import { useEffect } from 'react';
import axios from "axios";
import styles from '../../inputs/Input.module.css'
import Select from 'react-select'

function NatJur({ textLabel, listaNatureza, setListaNatureza, value, handleFunction }) {

    useEffect(() => {
            let page = 1;
            let array = []

            const fetchData = async (page) => {
                const request = {
                    body: "SELECT * FROM natureza_juridica",
                    count: page
                };

                const url = "https://ilxhdpai34.execute-api.us-east-1.amazonaws.com/v1/api-leads";

                const response = await axios.post(url, request);

                return JSON.parse(response.data.body).data;
            };

            const loadData = async (page) => {
                let result = await fetchData(page);
                array.push(result)
                return result.length === 1000;

            };


            const fetchDataRecursive = async () => {
                let retorno = await loadData(page);

                if (retorno) {
                    page++;
                    fetchDataRecursive()
                }

                if (!retorno) {
                    let accumulatedNatJu = [];
                    for (let i = 0; i < array.length; i++) {
                        accumulatedNatJu = [...accumulatedNatJu, ...array[i]];
                    }
                    setListaNatureza(accumulatedNatJu);
                }

            };

            fetchDataRecursive()


    }, [setListaNatureza]);


    const options = listaNatureza.map(natureza => {
        return { codigo: natureza.codigo, value: natureza.descricao, label: `${natureza.codigo} - ${natureza.descricao}` }
    });

    const loadOptions = (searchValue) => {
        const filteredOptions = options.filter(option =>
            option.label.toLowerCase().includes(searchValue.toLowerCase())
        );
        return Promise.resolve(filteredOptions);
    };

    return (
        <div className={styles.inputs}>
            <label>{textLabel}</label>
            <Select
                placeholder="Natureza jurídica"
                isMulti
                options={options}
                value={value}
                onChange={handleFunction}
                loadOptions={loadOptions}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: 0,
                        borderColor: "#aab7b8",
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: 400,
                        backgroundColor: "#F6F8FA",
                        fontFamily: "Inter",
                    }),
                    multiValueLabel: (styles) => ({
                        ...styles,
                        color: "#312D45",
                    }),
                }}
            />
        </div>
    )

}
export default NatJur
