import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';

import styles from '../layout/desktop/funil/HeaderFunil.module.css'

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CnpjFormat from '../numberFormat/CnpjFormat';

import dotenv from 'react-dotenv';
import axios from "axios";
import moment from 'moment'
import SnackbarAlert from '../layout/snackbar/Snackbar';
import { AiOutlineConsoleSql } from 'react-icons/ai';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const stylesTextField = {
    textFieldLabel: {
        fontWeight: '500', // define a espessura da fonte do label para negrito
        fontFamily: "Inter",
        fontSize: "14px",
        color: "#777",
        '&.Mui-focused': {
            color: '#777', // cor do label quando o textField estiver em foco
            fontWeight: "600",
            fontSize: "14px"
        },
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: 'Inter',
            color: '#282539',
            '&:hover fieldset': {
                borderColor: 'gray', // cor da borda ao passar o mouse sobre o textField
            },
            '&.Mui-focused fieldset': {
                borderColor: "#dfe1e6",
                color: "#777777" // cor da borda quando o textField estiver em foco
            },
        },
    },
};

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: "16px", color: "#282539", fontFamily: 'Inter', minWidth: "500px" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function FiltroCNPJ(props) {
    const [cnpjFormatado, setCnpjFormatado] = React.useState("")
    const [cnpj, setCnpj] = React.useState("")
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setResultCnpj("")
    };

    const handleSetCnpj = (value) => {
        setCnpjFormatado(value.toString());
        setCnpj(value.target.value)
    };

    const getCnpjBasico = async () => {
        const url = `${process.env.REACT_APP_API_URL}/cnpj`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    cnpj: cnpj
                }

            });
            return response.data;
        } catch (error) {
            alertError()
            return error.response.data;

        }
    }

    const [resultCnpj, setResultCnpj] = React.useState([])

    const handleGetCnpj = () => {
        getCnpjBasico().then(function (response) {
            setResultCnpj(response)
        })
    }

    //SnackAlert
    const [color, setColor] = React.useState("")
    const [state, setState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'right',
        text: "Ok"
    });

    const handleCloseAlert = () => {
        setState({ ...state, openAlert: false });
    };

    function alertError() {
        setColor('#ec3c62')
        setState({
            openAlert: true, vertical: 'top',
            horizontal: 'right',
            text: "CNPJ inválido!"
        });
    }

    //fim SnackAlert

    function sucessSaveFunil() {
        handleClose()
        setColor('#239f4f')
        setState({
            openAlert: true, vertical: 'top',
            horizontal: 'right',
            text: "Lead adicionado ao Funil!"
        });
    }

    function errorSaveFunil() {
        handleClose()
        setColor('#ec3c62')
        setState({
            openAlert: true, vertical: 'top',
            horizontal: 'right',
            text: "Ocorreu um erro. Contacte o suporte ao cliente!"
        });
    }


    const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");

    const saveFunil = async () => {
        const request = {
            cnpj: resultCnpj[0].cnpj,
            razao_social: resultCnpj[0].razao_social,
            nome_fantasia: resultCnpj[0].nome_fantasia,
            cep: resultCnpj[0].cep,
            logradouro: `${resultCnpj[0].logradouro} ${resultCnpj[0].numero} ${resultCnpj[0].complemento}`,
            bairro: resultCnpj[0].bairro,
            cidade: resultCnpj[0].cidade,
            uf: resultCnpj[0].uf,
            anotacoes: [], // adicionando como um array vazio
            tags: [], // adicionando como um array vazio
            telefone1: resultCnpj[0].telefone_1,
            telefone2: resultCnpj[0].telefone_2,
            email: resultCnpj[0].email,
            status: "Prospecção",
            create_date: currentDate,
            iduser: props.id
        }


        const url = `${process.env.REACT_APP_API_URL}/saveFunil`;

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            sucessSaveFunil()
            getFunil().then(function (res) {
                props.setItens(res.data)
                setOpen(false)
            })
            return response;
        } catch (error) {
            errorSaveFunil()
            return error;
        }
    }

    const getFunil = async () => {

        const url = `${process.env.REACT_APP_API_URL}/funil`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: props.id
                }

            });
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    const handleSaveFunil = () => {
        saveFunil()
    }


    return (
        <div>
            <SnackbarAlert handleClose={handleCloseAlert} open={state.openAlert} vertical={state.vertical} horizontal={state.horizontal} color={color} text={state.text} />
            <button className={styles.btnAddLeads} onClick={handleClickOpen}>Adicionar lead <AddCircleOutlineRoundedIcon fontSize='small' /></button>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Adicionar lead
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <TextField
                        label="CNPJ"
                        id="outlined-size-small"
                        value={cnpjFormatado}
                        size="small"
                        InputLabelProps={{
                            sx: stylesTextField.textFieldLabel, // aplica o estilo ao label do TextField
                        }}
                        sx={{
                            ...stylesTextField.textField,
                            width: '300px', // defina a largura desejada aqui
                        }}
                        InputProps={{
                            inputComponent: CnpjFormat,
                            value: cnpjFormatado, // Passa o valor atual do CNPJ para o componente CnpjFormat
                            onChange: handleSetCnpj, // Atualiza o estado do CNPJ conforme o usuário digita
                        }}
                    />

                    <Button onClick={handleGetCnpj} sx={{
                        marginLeft: "10px",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "500",
                        textTransform: "none",
                        borderRadius: "3px",
                        border: "none",
                        padding: "6px 12px",
                        color: "#282539",
                        background: "none",
                        cursor: "pointer",
                    }}>Pesquisar</Button>

                    {resultCnpj.length > 0 ? (
                        <Typography
                            variant="h3"
                            sx={{
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: '500',
                                color: '#282539',
                                borderRadius: "3px",
                                background: "rgb(4, 41, 64, 0.1)",
                                marginTop: "16px",
                                padding: "16px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            < BusinessRoundedIcon style={{ marginRight: "10px" }} />
                            {resultCnpj[0].razao_social}
                        </Typography>
                    ) : null}
                </DialogContent>


                <DialogActions>
                    {resultCnpj.length > 0 ? (
                        <Button autoFocus onClick={handleSaveFunil} sx={{
                            borderRadius: "3px",
                            border: "none",
                            padding: "6px 12px",
                            fontSize: "12px",
                            fontWeight: "500",
                            fontFamily: "Inter",
                            color: "white",
                            background: "#239f4f",
                            cursor: "pointer",
                            textTransform: "none",
                            '&:hover': {
                                background: "#239f4f"
                            }
                        }}>
                            Adicionar
                        </Button>
                    ) : null}
                </DialogActions>

            </BootstrapDialog>
        </div >
    );
}