import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Paper } from '@mui/material';
import './DrawerBottom.css';
import check from '../imagens/check.svg'
import unchecked from '../imagens/unchecked.svg'
import arrowLeft from '../imagens/arrows-left.svg'
import SwitchUI from '../switch/Switch';
import Stripe from 'stripe';

export default function DrawerBottom(props) {
    const [state, setState] = React.useState({
        bottom: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const stylePaper = {
        color: "rgb(33, 43, 54)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundImage: "none",
        overflow: "hidden",
        position: "relative",
        borderRadius: "16px",
        zIndex: 0,
        padding: "40px",
        boxShadow: "none",
        backgroundColor: "rgb(255, 255, 255)",
        boxShadow: "gb(145 158 171 / 16%) 0px 24px 48px 0px"
    }

    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked(!checked)
    }

    const stripe = Stripe(process.env.REACT_APP_STRIPE_API);

    const mensal = async () => {
        handleClickCheckout(49.90)

        let customer = null

        if (props.user.idstripe) {
            customer = props.user.idstripe
            createCustomerPortalSession(customer)
        } else {
            customer = await stripe.customers.create({
                email: props.user.email,
                name: props.user.first_name + ' ' + props.user.last_name,
                metadata: {
                    'idUser': `${props.user.iduser}`
                }
            }).then(function (result) {
                createCustomerPortalSession(result.id)
            }).catch(function (error) {
                window.alert("Ocorreu um erro inesperado!")
            });
        }

        async function createCustomerPortalSession(id) {
            const session = await stripe.checkout.sessions.create({
                payment_method_types: ['card'],
                line_items: [
                    {
                        price: 'price_1MsT9cCL10uTvR7KJdwbcW5e',
                        quantity: 1,
                    },
                ],
                customer: id,
                mode: 'subscription',
                success_url: `${process.env.REACT_APP_RETURN_URL}/sucess`,
                cancel_url: `${process.env.REACT_APP_RETURN_URL}/dashboard`,
            });

            window.location.href = session.url;
        }
    }

    const bimestral = async () => {
        handleClickCheckout(69.90)

        let customer

        if (props.user.idstripe) {
            customer = props.user.idstripe
            createCustomerPortalSession(customer)
        } else {
            customer = await stripe.customers.create({
                email: props.user.email,
                name: props.user.first_name + ' ' + props.user.last_name,
                metadata: {
                    'idUser': `${props.user.iduser}`
                }
            }).then(function (result) {
                createCustomerPortalSession(result.id)
            }).catch(function (error) {
                window.alert("Ocorreu um erro inesperado!")
            });
        }

        async function createCustomerPortalSession(id) {
            const session = await stripe.checkout.sessions.create({
                payment_method_types: ['card'],
                line_items: [
                    {
                        price: 'price_1MsTBLCL10uTvR7Ktkk23W7W', // substitua 'price_123' pelo ID do seu produto no Stripe
                        quantity: 1,
                    },
                ],
                customer: id,
                mode: 'subscription',
                success_url: `${process.env.REACT_APP_RETURN_URL}/sucess`,
                cancel_url: `${process.env.REACT_APP_RETURN_URL}/dashboard`,
            });

            window.location.href = session.url;
        }
    }

    const trimestral = async () => {
        handleClickCheckout(119.90)

        let customer = null

        if (props.user.idstripe) {
            customer = props.user.idstripe
            createCustomerPortalSession(customer)
        } else {
            customer = await stripe.customers.create({
                email: props.user.email,
                name: props.user.first_name + ' ' + props.user.last_name,
                metadata: {
                    'idUser': `${props.user.iduser}`
                }
            }).then(function (result) {
                createCustomerPortalSession(result.id)
            }).catch(function (error) {
                window.alert("Ocorreu um erro inesperado!")
            });
        }

        async function createCustomerPortalSession(id) {
            const session = await stripe.checkout.sessions.create({
                payment_method_types: ['card'],
                line_items: [
                    {
                        price: 'price_1NTWywCL10uTvR7Kb4emxEJ9', // substitua 'price_123' pelo ID do seu produto no Stripe
                        quantity: 1,
                    },
                ],
                customer: id,
                mode: 'subscription',
                success_url: `${process.env.REACT_APP_RETURN_URL}/sucess`,
                cancel_url: `${process.env.REACT_APP_RETURN_URL}/dashboard`,
            });

            window.location.href = session.url;
        }
    }

    const anual = async () => {
        handleClickCheckout(838.80)

        let customer = null

        if (props.user.idstripe) {
            customer = props.user.idstripe
            createCustomerPortalSession(customer)
        } else {
            customer = await stripe.customers.create({
                email: props.user.email,
                name: props.user.first_name + ' ' + props.user.last_name,
                metadata: {
                    'idUser': `${props.user.iduser}`
                }
            }).then(function (result) {
                createCustomerPortalSession(result.id)
            }).catch(function (error) {
                window.alert("Ocorreu um erro inesperado!")
            });
        }

        async function createCustomerPortalSession(id) {
            const session = await stripe.checkout.sessions.create({
                payment_method_types: ['card'],
                line_items: [
                    {
                        price: 'price_1MsTAWCL10uTvR7KCuIUtWnF', // substitua 'price_123' pelo ID do seu produto no Stripe
                        quantity: 1,
                    },
                ],
                customer: id,
                mode: 'subscription',
                success_url: `${process.env.REACT_APP_RETURN_URL}/sucess`,
                cancel_url: `${process.env.REACT_APP_RETURN_URL}/dashboard`,
            });

            window.location.href = session.url;
        }
    }


    //Snippet Google
    const handleClick = () => {

        function gtag_report_conversion(url) {
            var callback = function () {
                if (typeof (url) != 'undefined') {
                    window.location = url;
                }
            };
            window.gtag('event', 'conversion', {
                send_to: 'AW-11085727816/fo7cCKjolJYYEMiQi6Yp',
                event_callback: callback,
            });
            return false;
        }

        gtag_report_conversion()

    }

    //Snippet Google aquisição planos
    const handleClickCheckout = (valor) => {

        function gtag_report_conversion(url) {
            var callback = function () {
                if (typeof (url) != 'undefined') {
                    window.location = url;
                }
            };

            window.gtag('event', 'conversion', {
                send_to: 'AW-11085727816/Qm1ACI7DnJYYEMiQi6Yp',
                value: valor,
                currency: 'BRL',
                event_callback: callback,
            });
            return false;
        }

        gtag_report_conversion()
    };

    React.useEffect(() => {
        handleClick()
    }, [])


    return (
        <div>
            <React.Fragment key={'bottom'}>
                <div style={{ display: "flex" }}>
                    <button style={{ cursor: "pointer" }} onClick={toggleDrawer('bottom', true)}>Fazer upgrade</button>
                </div>
                <Drawer
                    anchor={'bottom'}
                    open={state['bottom']}
                    onClose={toggleDrawer('bottom', false)}
                >
                    <Box
                        sx={{
                            height: '100%',
                            background: "rgba(0, 171, 85, 0.08)",
                            padding: "40px 20px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "flex-start",
                        }}
                        role="presentation"
                    >
                        <div style={{
                            width: "80%",
                            marginBottom: "30px",
                            fontFamily: 'Inter',
                            fontSize: "16px",
                            color: "#777",
                            fontWeight: "500",
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}>
                            <div className='voltar'>
                                <img style={{ marginRight: "4px" }} src={arrowLeft} alt='voltar'
                                    onClick={toggleDrawer('bottom', false)}
                                    onKeyDown={toggleDrawer('bottom', false)} />
                                <p
                                    onClick={toggleDrawer('bottom', false)}
                                    onKeyDown={toggleDrawer('bottom', false)}>
                                    {'Voltar para painel'}
                                </p>
                            </div>

                            <div className='switch'>
                                <span>Mensal</span>
                                <SwitchUI checked={checked} handleChange={handleChange} />
                                <span>Anual</span>
                            </div>

                        </div>
                        {!checked ? (
                            <Box sx={{
                                gap: "24px",
                                display: "grid",
                                '@media (min-width: 900px)': {
                                    gridTemplateColumns: "repeat(3, 1fr)"
                                }
                            }}>

                                <Paper sx={stylePaper}>
                                    <div className='DrawerBottom'>
                                        <span className='titlePlan'>Plano Mensal</span>
                                        <div className='css-c7dxy7'>
                                            <h5>R$</h5>
                                            <h2>49,90</h2>
                                            <span>/mês</span>
                                        </div>
                                    </div>

                                    <div className='DrawerBottomBeneficios'>
                                        <ul className='css-14i14n2 '>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Acesso a até 1000 leads</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Funil de vendas</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Gestor de leads (Kanban)</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Visualização detalhada de leads</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Exportação CSV: limite de 200 empresas</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Histórico da base de pesquisa</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Painel de resultados</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Filtros especiais</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Atendimento via email</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Atendimento via Whatsapp</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Leads avulsos (R$ 0,40/lead)</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={unchecked} alt="check" />
                                                <p style={{ color: "#919eab" }} className='css-1o38g2n'>Suporte personalizado</p>
                                            </li>
                                        </ul>
                                        <button onClick={mensal}>Assinar</button>
                                    </div>
                                </Paper>

                                <Paper sx={stylePaper}>
                                    <div className='DrawerBottom'>
                                        <span className='titlePlan'>Plano Bimestral</span>
                                        <div className='css-c7dxy7'>
                                            <h5>R$</h5>
                                            <h2>69,90</h2>
                                            <span>/2 meses</span>
                                        </div>
                                    </div>

                                    <div className='DrawerBottomBeneficios'>
                                        <ul className='css-14i14n2 '>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Acesso a até 2000 leads</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Funil de vendas</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Gestor de leads (Kanban)</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Visualização detalhada de leads</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Exportação CSV: limite de 10k</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Histórico da base de pesquisa</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Painel de resultados</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Filtros especiais</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Atendimento via email</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Atendimento via Whatsapp</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Leads avulsos (R$ 0,40/lead)</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={unchecked} alt="check" />
                                                <p style={{ color: "#919eab" }} className='css-1o38g2n'>Suporte personalizado</p>
                                            </li>
                                        </ul>
                                        <button onClick={bimestral}>Assinar</button>
                                    </div>
                                </Paper>

                                <Paper sx={stylePaper}>
                                    <div className='DrawerBottom'>
                                        <div className='tagCustoBeneficio'>
                                            <span className='css-10wqog4'>Melhor custo benefício</span>
                                        </div>
                                        <span className='titlePlan'>Plano Trimestral</span>
                                        <div className='css-c7dxy7'>
                                            <h5>R$</h5>
                                            <h2>399,00</h2>
                                            <span>/3 meses</span>
                                        </div>
                                    </div>

                                    <div className='DrawerBottomBeneficios'>
                                        <ul className='css-14i14n2 '>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Leads ilimitados</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Funil de vendas</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Gestor de leads (Kanban)</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Visualização detalhada de leads</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Exportação CSV: limite de 80k</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Histórico da base de pesquisa</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Painel de resultados</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Filtros especiais</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Atendimento via email</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Atendimento via Whatsapp</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Leads avulsos (R$ 0,40/lead)</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={unchecked} alt="check" />
                                                <p style={{ color: "#919eab" }} className='css-1o38g2n'>Suporte personalizado</p>
                                            </li>
                                        </ul>
                                        <button onClick={trimestral}>Assinar</button>
                                    </div>
                                </Paper>
                            </Box>
                        )
                            :
                            <Box sx={{
                                gap: "24px",
                                display: "grid",
                                justifyContent: "center",
                                '@media (min-width: 900px)': {
                                    gridTemplateColumns: "repeat(3, 1fr)"
                                }
                            }}>
                                <Paper sx={stylePaper}>
                                    <div className='DrawerBottom'>
                                        <span className='titlePlan'>Plano Anual</span>
                                        <div className='css-c7dxy7'>
                                            <h5>R$</h5>
                                            <h2>838,80</h2>
                                            <span>/ano</span>
                                        </div>
                                    </div>

                                    <div className='DrawerBottomBeneficios'>
                                        <ul className='css-14i14n2 '>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Leads ilimitados</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Funil de vendas</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Gestor de leads (Kanban)</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Visualização detalhada de leads</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Exportação CSV: limite de 100k empresas</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Histórico da base de pesquisa</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Painel de resultados</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Filtros especiais</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Atendimento via email</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Atendimento via Whatsapp</p>
                                            </li>
                                            <li className=' css-1yo0sni'>
                                                <img className='css-k0e32s' src={check} alt="check" />
                                                <p className='css-1o38g2n'>Suporte personalizado</p>
                                            </li>
                                        </ul>
                                        <button onClick={anual}>Assinar</button>
                                    </div>
                                </Paper>
                            </Box>
                        }
                    </Box>

                </Drawer>
            </React.Fragment>

        </div >
    );
}