import { useEffect, useState } from 'react';
import axios from "axios";
import styles from '../../inputs/Input.module.css'

function EstadosLista({ textLabel, value, handleFunction}) {

    const [ufs, setUfs] = useState([])

    useEffect(() => {
        axios
            .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome")
            .then((response) => {
                setUfs(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);


    return (
        <div className={styles.inputs}>
            <label>{textLabel}</label>
            <select className={styles.select} id="uf" onChange={handleFunction} value={value}>
                <option value=""></option>
                {ufs.map(uf => (
                    <option key={uf.id} value={uf.sigla}>{uf.sigla}</option>
                ))}
            </select>
        </div>
    )
}
export default EstadosLista
