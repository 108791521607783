import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';

import leads from '../../../imagens/leads.png';

export default function Welcome(props) {
    const [open, setOpen] = React.useState(false);

    const getPlans = async () => {

        const url = `${process.env.REACT_APP_API_URL}/welcome`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: props.id
                }

            });

            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        getPlans()
        props.setWelcome(false);
        setOpen(false);
        window.location.reload();
    };

    React.useEffect(() => {
        if (props.welcome) {
            handleClickOpen();
        }
    }, [props.welcome]);
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{
                    fontFamily: "Inter",
                    color: "#212b36",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                }}>
                    {"Seja bem-vindo à LeadsMapping!"}
                </DialogTitle>
                <DialogContent sx={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}>
                        <img style={{ width: "200px", margin: "30px" }} src={leads} />
                    </div>
                    <DialogContentText id="alert-dialog-description" sx={{
                        fontFamily: "Inter",
                        color: "#454F5B",
                        fontSize: "16px",
                        textAlign: "justify",
                    }}>
                        Adicionamos 60 créditos à sua conta para que você possa mapear novos leads e conquistar clientes durante seu período de teste de 15 dias.
                    </DialogContentText>

                    <DialogContentText id="alert-dialog-description" sx={{
                        fontFamily: "Inter",
                        color: "#454F5B",
                        fontSize: "16px",
                        textAlign: "justify",
                        marginTop: "20px",
                    }}>
                        Com o nosso Funil de Vendas, você tem tudo o que precisa para impulsionar o seu negócio e gerenciar a etapas do seu processo de vendas. E o melhor de tudo: é gratuito!
                    </DialogContentText>

                    <DialogContentText id="alert-dialog-description" sx={{
                        fontFamily: "Inter",
                        color: "#454F5B",
                        fontSize: "16px",
                        textAlign: "justify",
                        marginTop: "20px",
                    }}>
                        Estamos ansiosos para ajudá-lo a vender mais!
                    </DialogContentText>

                </DialogContent>
                <DialogActions sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    <button style={{
                        color: "white",
                        background: "#00AB55",
                        width: "140px",
                        height: "30px",
                        padding: "5px",
                        borderRadius: "4px",
                        border: "none",
                        fontFamily: 'Inter',
                        fontSize: "12px",
                        cursor: "pointer",
                        marginBottom: "20px",
                        marginTop: "20px",
                    }}
                        onClick={handleClose}
                    >
                        Começar agora!
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}