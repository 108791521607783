import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const stylesTextField = {
    textFieldLabel: {
        fontWeight: '500', // define a espessura da fonte do label para negrito
        fontFamily: "Inter",
        fontSize: "14px",
        color: "#777",
        '&.Mui-focused': {
            color: '#777', // cor do label quando o textField estiver em foco
            fontWeight: "600",
            fontSize: "14px"
        },
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: 'Inter',
            color: '#282539',
            '&:hover fieldset': {
                borderColor: 'gray', // cor da borda ao passar o mouse sobre o textField
            },
            '&.Mui-focused fieldset': {
                borderColor: "#dfe1e6",
                color: "#777777" // cor da borda quando o textField estiver em foco
            },
        },
    },
};

export default function YearMonth(props) {
    const [value, setValue] = React.useState(dayjs().locale('pt-br').format('MMMM YYYY'));

    // Definir o idioma como português
    dayjs.locale('pt-br');

    React.useEffect(() => {
        setValue(props.filtro)
    }, [props.filtro])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <DatePicker
                    views={props.views}
                    label={props.label}
                    value={value}
                    onChange={(newValue) => {
                        setValue(newValue);
                        props.setFiltro(newValue)
                    }}
                    renderInput={(params) => <TextField
                        {...params}
                        helperText={null}
                        InputLabelProps={{
                            sx: stylesTextField.textFieldLabel
                        }}
                        sx={{
                            ...stylesTextField.textField,
                            maxWidth: "200px",
                            marginTop: "20px",
                            marginBottom: "15px",
                            "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                                padding: "8.5px", // define o padding do input
                            },
                            "& .MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-1uvydh2": {
                                padding: "8.5px", // define o padding do input
                            },
                            "& .css-1uvydh2": {
                                padding: "8.5px",
                            }
                        }}
                    />}
                />
            </Stack>
        </LocalizationProvider>
    );
}

