import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from 'react'

import './App.css';
import Dashboard from '../src/pages/dashboard';
import Erro404 from "./404";
import Login from "./pages/login";
import PrivacyPolicy from "./pages/privacyPolicy";
import Sucess from "./pages/planSucess";
import IndexPage from "./pages/index";
import Admin from "./pages/admin";
import Teste from "./pages/voip/teste";
import Sip from "./pages/voip/jssip";



function App() {
  const [isAuthenticated, setAuthenticated] = useState(false);

  //Sendinblue Conversations {literal} 

  useEffect(() => {
    window.SibConversationsID = '642b0f68a738fc694c4428b8';
    window.SibConversations = window.SibConversations || function () {
      (window.SibConversations.q = window.SibConversations.q || []).push(arguments);
    };
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://conversations-widget.sendinblue.com/sib-conversations.js';
    if (document.head) document.head.appendChild(script);
  }, []);

  return (
    <div className="AppContainer">
      <Router>
        <Routes>
          <Route exact path="/" element={<IndexPage />} />
          <Route exact path="/login" element={<Login autenthicated={isAuthenticated} setAutenthicated={setAuthenticated} />} />
          <Route exact path="/dashboard" element={<Dashboard autenthicated={isAuthenticated} />} />
          <Route exact path="/politica-de-privacidade" element={<PrivacyPolicy />} />
          <Route exact path="/sucess" element={<Sucess />} />
          <Route exact path="/sip" element={<Sip />} />
          <Route exact path="/:path*" element={<Erro404 />} />
        </Routes>
      </Router>
    </div>
  );
}


export default App;
