import AssinaturaDigital from './assinaturaDigital/assinaturaDigital'
import CardMapping from './cardMapping/CardMapping'
import ChatBot from './chatbot/ChatBot'
import styles from './Desktop.module.css'
import EmailMarketing from './emailMarketing/emailMarketing'
import Funil from './funil/Funil'
import Home from './home/Home'
import ListLeads from './listLeads/listLeads'
import Voip from './voip/Voip'



function Desktop({ visibleCardMapping, visibleFunil, visibleListLeads, visibleVoip, id, leadsClick, setLeadsClick,
    visibleHome, setUser, user, limit, setLimit, setNumberCall, visibleChatBot, visibleEmailMarketing, visibleAssinaturaDigital}) {

    return (
        <div className={styles.desktop}>
            {visibleCardMapping ? <CardMapping setLimit={setLimit} id={id} limit={limit} user={user} /> : null}
            {visibleListLeads ? <ListLeads id={id} user={user} setUser={setUser} leadsClick={leadsClick} setLeadsClick={setLeadsClick} /> : null}
            {visibleFunil ? <Funil setNumberCall={setNumberCall} user={user} id={id} /> : null}
            {visibleHome && user ? <Home id={id} user={user} limit={limit} /> : null}
            {visibleVoip && user ? <Voip user={user} /> : null}
            {visibleChatBot && user ? <ChatBot user={user} /> : null}
            {visibleEmailMarketing && user ? <EmailMarketing user={user} /> : null}
            {visibleAssinaturaDigital && user ? <AssinaturaDigital user={user} /> : null}
        </div>
    )
}

export default Desktop
