import './assinaturaDigital.css'
import { Box, Button, Container, Paper, Typography } from "@mui/material"
import SignerDigital from '../../../imagens/signerDigital.svg'
import axios from 'axios'


const stylePaper = {
    color: "rgb(33, 43, 54)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    position: "relative",
    borderRadius: "16px",
    zIndex: 0,
    padding: "40px",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "gb(145 158 171 / 16%) 0px 24px 48px 0px",
    maxWidth: '380px'
}


export default function SignerDescription({ user }) {

    const createLeads = async () => {
        const request = {
            iduser: user.iduser,
            email: user.email,
        };

        const url = `${process.env.REACT_APP_API_URL}/signerLeads`;

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            alert("Agradecemos o seu interesse, em breve entraremos em contato!")
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    return (
        <Container sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#f5f8fa",
            padding: "30px",
            borderRadius: "16px",
        }}>
            <Box sx={{
                gap: "24px",
                display: "grid",
                justifyContent: "center",
                alignContent: "center",
                '@media (min-width: 900px)': {
                    gridTemplateColumns: "repeat(2, 1fr)"
                }
            }}>
                <Box sx={{
                    marginLeft: "20px",
                    marginRight: "20px",

                    "@media (max-width: 600px)": {
                        width: "100%",
                    }
                }}
                >
                    <img src={SignerDigital} alt='Assinatura Digital' className='imgAssDigital' />
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: "20px",
                    marginRight: "20px",
                }}
                >
                    <Typography variant='h6'
                        sx={{
                            marginBottom: "10px",
                            maxWidth: "400px",
                            fontFamily: 'Inter',
                            margin: "0px 0px 20px",
                            fontWeight: "600",
                            color: "#212b36"
                        }}>Assinatura eletrônica para formalizar seus negócios com rapidez</Typography>

                    <Typography variant='span'
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#637381",
                            maxWidth: "400px",
                            textAlign: "justify",
                            "@media (max-width: 900px)": {
                                maxWidth: "100%",
                            }
                        }}
                    >Seu documento pode ser assinado de qualquer dispositivo com acesso à internet. Adicione o documento na plataforma e o envie para assinatura via e-mail ou WhatsApp. 
                    </Typography>

                    <Button sx={{
                        maxWidth: "400px",
                        marginTop: "30px",
                        backgroundColor: "#00ab55",
                        color: "#fff",
                        fontFamily: 'Inter',
                        textTransform: "none",
                        fontWeight: "600",
                        "&:hover": {
                            backgroundColor: "#00ab55",
                        }
                    }}
                        onClick={createLeads}
                    >
                        Avise-me quando estiver disponível
                    </Button>
                </Box>

            </Box>

        </Container >
    )
}