import './Index.css'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'

import { Box, Container, Divider, Paper, Typography } from "@mui/material"
import logoBlackGreen from '../../components/imagens/logoBlackGreen.svg'
import logoBlackWhite from '../../components/imagens/logoWhiteGreen.svg'
import overlay from '../../components/imagens/overlay_2.jpg'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import leads from '../../components/imagens/leads.png'
import funil from '../../components/imagens/funil_site.svg'

import { HiFilter } from "react-icons/hi";
import { IoIosRocket } from "react-icons/io";
import { FiTarget } from "react-icons/fi";
import Planos from './Planos'
import Menu from './Menu'
import { Helmet } from 'react-helmet';

function IndexPage() {
    const ref = useRef(null);
    const refSolution = useRef(null);

    const navigate = useNavigate()

    const handleLogin = () => {
        navigate('/login')
    }

    const handleClickPlanos = () => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const handleClickSolution = () => {
        refSolution.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const headerStyle = {
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        boxShadow: 'rgba(140, 152, 164, 0.25) 0px 3px 6px 0px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        boxSizing: 'border-box',
        flexShrink: 0,
        position: 'sticky',
        zIndex: 1200,
        left: 'auto',
        right: '0px',
        color: 'rgb(255, 255, 255)',
        top: '0px',
        backgroundColor: 'white',
    }

    const stylePaper = {
        background: "none",
        boxShadow: "none",
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '15px',
        marginBottom: '15px',
    }

    const stylePaperFooter = {
        root: {
            background: "none",
            boxShadow: "none",
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        typography: {
            fontSize: "14px",
            color: '#F9FAFB',
            fontFamily: "Inter",
            fontWeight: 400,
            lineHeight: "28px",
            display: 'flex',
            marginBottom: "40px"
        },
        listTitle: {
            fontSize: "18px",
            color: '#00DE4B',
            fontFamily: "Inter",
            fontWeight: 500,
            height: '64px',
            lineHeight: "28px",
            display: 'flex',
            alignItems: 'center',
        },
        list: {
            fontSize: "14px",
            color: '#F9FAFB',
            fontFamily: "Inter",
            fontWeight: 400,
            lineHeight: "28px",
            marginBottom: "20px",
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        copyRight: {
            fontSize: "12px",
            color: '#F9FAFB',
            fontFamily: "Inter",
            fontWeight: 400,
            lineHeight: "28px",
            marginTop: "20px",
            marginBottom: "20px",
            display: 'flex',
            alignItems: 'center',
        }
    }

    const styleH2 = {
        color: "#454F5B",
        fontSize: "48px",
        fontWeight: 800,
        marginTop: "0px"
    }

    const styles = {
        root: {
            color: "rgb(69, 79, 91)",
            fontWeight: 500,
            fontSize: "2em",
            lineHeight: "42px",
            textAlign: "center",
            '@media (max-width: 600px)': {
                fontSize: 24,
            },
        },
        spanColor: {
            color: '#00AB55',
            fontWeight: 600
        },
    };

    const styleH3 = {
        fontSize: "16px",
        fontWeight: 400,
        color: "#637381",
        marginLeft: "10px"
    }

    return (
        <Paper sx={{
            width: '100%',
            minHeight: '100vh',
            height: '100%',
            boxShadow: 'none',
            border: 'none',
            borderRadius: '0px',
        }}>
            <Paper sx={headerStyle}>
                <Helmet>
                    <title>LeadsMapping: Acelere suas vendas com leads segmentados</title>
                    <meta name="description" content="Descubra como a LeadsMapping pode impulsionar seu funil de vendas com acesso a listas segmentadas de empresas. Transforme leads em clientes agora!" />
                    <meta name="keywords" content="leadsmapping, leads mapping, lista de empresas, lista segmentada de empresas, funil de vendas" />
                </Helmet>
                <Container sx={{
                    height: '70px',
                }}>
                    <div className="divHeaderIndex">
                        <img className='logoBlackGreen' src={logoBlackGreen} alt='Logomarca'></img>
                        <nav className="divHeaderContent">
                            <ul>
                                <li>
                                    <p onClick={handleClickSolution}>
                                        Funil de vendas
                                        <KeyboardArrowDownRoundedIcon sx={{ marginLeft: '3px', display: "none" }} />
                                        <span className="underline"></span>
                                    </p>
                                </li>
                                <li>
                                    <p onClick={handleClickPlanos}>
                                        Planos
                                        <span className="underline"></span>
                                    </p>
                                </li>
                                <li>
                                    <a href="/login">
                                        Entrar
                                        <span className="underline"></span>
                                    </a>
                                </li>
                            </ul>

                            <button className="btnMenusEntrar" onClick={handleLogin}> Criar conta grátis</button>
                        </nav>
                        <div className='divMenuResp'>
                            <Menu />
                        </div>
                    </div>
                </Container>
            </Paper>
            <Box>
                <div style={{
                    background: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)) center center / cover no-repeat, url(${overlay})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }} className="bodyContent">
                    <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Box sx={{
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            '@media (max-width: 600px)': {
                                width: '100%'
                            }
                        }}>
                            <h1>Obtenha listas de empresas segmentadas de alta qualidade</h1>
                            <p>Acesse nossa base de dados com mais de 54 milhões de empresas e gere listas segmentadas de alta qualidade. Na LeadsMapping, você filtra e seleciona empresas ideais para suas estratégias de vendas e marketing, impulsionando o crescimento do seu negócio de forma eficiente</p>
                            <button className="btnCriarConta_gratis" onClick={handleLogin}>Crie uma conta grátis</button>
                        </Box>
                        <Box sx={{
                            width: '50%',
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-end',
                            '@media (max-width: 600px)': {
                                display: 'none'
                            }
                        }}>
                            <img className='imgPrincipal' src={leads} alt="Leads" />
                        </Box>
                    </Container>
                    <Container sx={{ marginTop: "80px", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Box sx={{
                            gap: "15px",
                            display: "grid",
                            width: "100%",
                            alignItems: "center",
                            '@media (min-width: 768px)': {
                                gridTemplateColumns: "repeat(4, 1fr)"
                            }
                        }}>
                            <Paper sx={stylePaper}>
                                <Box>
                                    <span style={styleH2}>+55</span>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                                    <span style={styleH3}>milhões</span>
                                    <span style={styleH3}>de empresas</span>
                                </Box>
                            </Paper>

                            <Paper sx={stylePaper}>
                                <Box>
                                    <HiFilter className='IconblocosIcon' />
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                                    <span style={styleH3}>Funil de</span>
                                    <span style={styleH3}>vendas grátis</span>
                                </Box>
                            </Paper>

                            <Paper sx={stylePaper}>
                                <Box>
                                    <FiTarget className='IconblocosIcon' />
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                                    <span style={styleH3}>Alcance o</span>
                                    <span style={styleH3}>público certo</span>
                                </Box>
                            </Paper>

                            <Paper sx={stylePaper}>
                                <Box>
                                    <IoIosRocket className='IconblocosIcon' />
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                                    <span style={styleH3}>Alavanque</span>
                                    <span style={styleH3}>suas vendas</span>
                                </Box>
                            </Paper>
                        </Box>
                    </Container>

                </div>
            </Box >
            <Box sx={{ background: '#D8FBDE', minHeight: '500px', paddingTop: "60px", paddingBottom: "60px" }} ref={refSolution}>
                <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant='h2' component="h2" sx={styles.root}>
                        Transforme seus contatos em <span style={styles.spanColor}>vendas reais</span> com a ajuda da nossa base de milhões de empresas e acompanhe todo o processo de vendas
                        com o nosso <span style={styles.spanColor}>Funil de Vendas gratuito</span>. Alavanque seus resultados agora mesmo!
                    </Typography >
                    <img style={{ width: '80%' }} src={funil} alt='Imagem funil de vendas' />
                </Container>
            </Box>
            <Box sx={{ background: '#F4F6F8', minHeight: '500px', paddingTop: "60px", paddingBottom: "60px" }} ref={ref}>
                <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant='h4' component="h4" sx={styles.root}>
                        Escolha o plano que mais se encaixa no seu negócio
                    </Typography >
                    <Planos />
                </Container>
            </Box>
            <Box sx={{ background: '#212B36', minHeight: '500px', paddingTop: "60px", paddingBottom: "40px" }}>
                <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Box sx={{
                        gap: "24px",
                        display: "grid",
                        justifyContent: "center",
                        '@media (min-width: 900px)': {
                            gridTemplateColumns: "repeat(4, 1fr)"
                        }
                    }}>
                        <Paper sx={stylePaperFooter.root}>
                            <img className='logoBlackGreen' src={logoBlackWhite} alt='Logomarca'></img>
                            <Typography variant='h6' component="h6" sx={stylePaperFooter.typography}>
                                A LeadsMapping é uma empresa de tecnologia dedicada a fornecer soluções abrangentes para aprimorar a gestão e o crescimento das
                                vendas de negócios. Nossas soluções comerciais ajudam empresas a impulsionar sua produtividade e eficiência, permitindo que elas
                                alcancem seus objetivos de vendas de forma mais rápida e efetiva.
                            </Typography>
                            <Typography variant='p' component="p" sx={stylePaperFooter.typography}>
                                CNPJ: 27.496.069/0001-04
                            </Typography>
                        </Paper>

                        <Paper sx={stylePaperFooter.root}>
                            <Typography variant='p' component="p" sx={stylePaperFooter.listTitle}>
                                Produto
                            </Typography>
                            <Typography variant='p' component="p" sx={stylePaperFooter.list} onClick={handleClickPlanos}>
                                Planos
                            </Typography>
                            <Typography variant='p' component="p" sx={stylePaperFooter.list} onClick={handleClickSolution}>
                                Funil de Vendas
                            </Typography>
                            <Typography variant='p' component="p" sx={stylePaperFooter.list}>
                                Mapeamento de mercado
                            </Typography>
                        </Paper>


                        <Paper sx={stylePaperFooter.root}>
                            <Typography variant='p' component="p" sx={stylePaperFooter.listTitle}>
                                Suporte
                            </Typography>
                            <Typography variant='p' component="p" sx={stylePaperFooter.list} onClick={() => navigate('/politica-de-privacidade')}>
                                Política de privacidade
                            </Typography>
                        </Paper>

                        <Paper sx={stylePaperFooter.root}>
                            <Typography variant='p' component="p" sx={stylePaperFooter.listTitle}>
                                Fale conosco
                            </Typography>
                            <Typography variant='p' component="p" sx={stylePaperFooter.list}>
                                atendimento@leadsmapping.com.br
                            </Typography>
                        </Paper>
                    </Box>
                    <Divider sx={{ width: '100%', background: 'white', color: 'white' }} />
                    <Typography variant='p' component="p" sx={stylePaperFooter.copyRight}>
                        &copy; 2023 - LeadsMapping - Todos os direitos reservados
                    </Typography>
                </Container>
            </Box>
        </Paper >
    )
}

export default IndexPage