import { useEffect, useState } from 'react';
import axios from "axios";
import styles from '../../inputs/Input.module.css'
import Select from 'react-select'

function Cnae({ textLabel, value, handleFunction }) {

    const [cnaes, setCnaes] = useState([]);

    useEffect(() => {
        let page = 1;
        let array = []

        const fetchData = async (page) => {
            const request = {
                body: "SELECT * FROM cnae",
                count: page
            };

            const url = "https://ilxhdpai34.execute-api.us-east-1.amazonaws.com/v1/api-leads";

            const response = await axios.post(url, request);

            return JSON.parse(response.data.body).data;
        };

        const loadData = async (page) => {
            let result = await fetchData(page);
            array.push(result)
            return result.length === 1000;

        };


        const fetchDataRecursive = async () => {
            let retorno = await loadData(page);

            if (retorno) {
                page++;
                fetchDataRecursive()
            }

            if (!retorno) {
                let accumulatedCnaes = [];
                for (let i = 0; i < array.length; i++) {
                    accumulatedCnaes = [...accumulatedCnaes, ...array[i]];
                }
                setCnaes(accumulatedCnaes);
            }

        };

        fetchDataRecursive()

        // eslint-disable-next-line
    }, []);


    const options = cnaes.map(cnae => {
        return { codigo: cnae.codigo, value: cnae.descricao, label: `${cnae.codigo} - ${cnae.descricao}` }
    });

    const loadOptions = (searchValue) => {
        const filteredOptions = options.filter(option =>
            option.label.toLowerCase().includes(searchValue.toLowerCase())
        );
        return Promise.resolve(filteredOptions);
    };

    return (
        <div className={styles.inputs}>
            <label>{textLabel}</label>
            <Select
                placeholder="Filtre as atividades desejadas"
                isMulti
                options={options}
                value={value}
                onChange={handleFunction}
                loadOptions={loadOptions}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: 0,
                        borderColor: "#aab7b8",
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: 400,
                        backgroundColor: "#F6F8FA",
                        fontFamily: "Inter",
                    }),
                    multiValueLabel: (styles) => ({
                        ...styles,
                        color: "#312D45",
                    }),
                }}
            />
        </div>
    )

}
export default Cnae
