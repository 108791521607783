import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { BsArchive } from "react-icons/bs";

const options = [
    'Arquivar'
];

export default function LongMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleArquivar = () => {
        updateStatus(props.id)
    };

    const updateStatus = (id) => {
        const novoArray = props.arrayItens.map((objeto) => {
            if (objeto.id === id) {
                return {
                    ...objeto,
                    status: "Arquivado",
                };
            } else {
                return objeto;
            }
        });

        //atualiza o estado com o novo array
        props.setItens(novoArray);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                sx={{
                    padding: "0px",
                    margin: "0px",
                }}
                onClick={handleClick}
            >
                <MoreVertIcon fontSize='small' />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '100px',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option}
                        selected={option === 'Pyxis'}
                        onClick={handleArquivar}
                        sx={{
                            fontSize: "12px",
                            fontWeight: "400",
                            fontFamily: 'Inter',
                            color: '#282539',
                            padding: "5px"
                        }}>
                        <BsArchive style={{ marginRight: "6px" }} fontSize='medium' />
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}