import './VoipList.css'
import { Box, Container, Paper, Typography } from "@mui/material"
import VoipDescription from './VoipDescription'
import BasicTabs from './Tabs'
import DataTable from './Table'


const stylePaper = {
    color: "rgb(33, 43, 54)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    position: "relative",
    borderRadius: "16px",
    zIndex: 0,
    padding: "40px",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "gb(145 158 171 / 16%) 0px 24px 48px 0px",
    maxWidth: '380px'
}

function Voip({ user }) {
    return (
        <Container sx={{
            minHeight: "calc(100vh - 64px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>

            <VoipDescription user={user} />
{/* 
            <Box sx={{
                marginTop: "40px",
                marginBottom: "40px",
            }}>
                <div className='css-u4p24i'>
                    <Typography variant='h4' className='css-1bhrkuh'>Histórico de chamadas</Typography>
                </div>
            </Box>
            <Paper sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                borderRadius: "16px",
                boxShadow: "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px"
            }}>
                <BasicTabs />
                <div className='.css-1c5rw4h'>
                    <DataTable />
                </div>
            </Paper> */}
        </Container>
    )
}

export default Voip