import styles from './Home.module.css'
import Progress from '../../../progressBar/Progress';
import { useEffect, useState } from 'react';
import axios from "axios";
import dayjs from 'dayjs';
import { startOfMonth, endOfMonth } from 'date-fns';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';

import dash from '../../../imagens/dash.svg'
import rocket from '../../../imagens/Dart.png'
import character_3 from '../../../imagens/character_3.png'
import ApexChart from '../../../charts/ApexFunil';
import Stripe from 'stripe';
import DrawerBottom from '../../../drawer/DrawerBottom';
import Welcome from './Welcome';


const Item = styled(Paper)(({ theme }) => ({

    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: '16px',
    border: "none",
    boxShadow: "none",
    color: theme.palette.text.secondary,
}));

function Home(props) {
    const [welcome, setWelcome] = useState(false)

    const [itens, setItens] = useState([])
    const [filtroAno, setFiltroAno] = useState([])
    const [totalItens, setTotalItens] = useState([])


    const getFunil = async () => {

        const url = `${process.env.REACT_APP_API_URL}/funil`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: props.id
                }

            });
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    const getPlans = async () => {

        const url = `${process.env.REACT_APP_API_URL}/plans`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: props.user.plan_id
                }

            });
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    useEffect(() => {
        getFunil().then(function (response) {
            setItens(response.data)
        })
        // eslint-disable-next-line 
    }, [])

    function filtrarItensAno() {
        const anoAtual = dayjs().year();
        const primeiroDiaDoAno = dayjs().year(anoAtual).startOf('year');
        const ultimoDiaDoAno = dayjs().year(anoAtual).endOf('year');

        const itensDoAnoAtual = itens.filter((item) =>
            item.status === 'Fechamento' &&
            dayjs(item.fechamento_date).isBetween(primeiroDiaDoAno, ultimoDiaDoAno, 'day', '[]')
        );
        setFiltroAno(itensDoAnoAtual);
    }


    const currentDate = new Date();
    const [yearValue, setYearValue] = useState(0)
    const [monthValue, setMonthValue] = useState(0)

    function valueYear() {
        const totalValue = filtroAno.reduce((accumulator, item) => {
            return accumulator + item.valor;
        }, 0);

        const formattedValueYear = totalValue ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(totalValue) : new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(0);

        setYearValue(formattedValueYear)
    }


    function valueMonth() {
        const firstDayOfMonth = startOfMonth(currentDate);
        const lastDayOfMonth = endOfMonth(currentDate);

        const totalValueMonth = filtroAno
            .filter(item => {
                const fechamentoDate = new Date(item.fechamento_date);
                return fechamentoDate >= firstDayOfMonth && fechamentoDate <= lastDayOfMonth;
            })
            .reduce((accumulator, item) => {
                return accumulator + item.valor;
            }, 0);

        const formattedValueMonth = totalValueMonth
            ? new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(totalValueMonth)
            : new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(0);

        setMonthValue(formattedValueMonth);
    }


    useEffect(() => {
        valueYear()
        valueMonth()
        // eslint-disable-next-line
    }, [filtroAno])


    useEffect(() => {
        const prospeccao = itens.filter((item) => item.status === 'Prospecção').length
        const removido = itens.filter((item) => item.status === 'Arquivado').length
        const contato = itens.filter((item) => item.status === 'Contato').length
        const negociacao = itens.filter((item) => item.status === 'Negociação').length
        const fechamento = itens.filter((item) => item.status === 'Fechamento').length

        const fase1 = prospeccao + removido + contato + negociacao + fechamento
        const fase2 = contato + negociacao + fechamento
        const fase3 = negociacao + fechamento
        const fase4 = fechamento
        const valores = [fase1, fase2, fase3, fase4];

        filtrarItensAno()

        setTotalItens(valores)
        // eslint-disable-next-line
    }, [itens])


    const [saldo, setSaldo] = useState(0)
    const [totalPlano, setTotalPlano] = useState(0)
    const [namePlan, setNamePlan] = useState("")
    const [valorPlan, setValorPlan] = useState(0)
    const [dataRenovacao, setDataRenovacao] = useState("")

    useEffect(() => {
        if (props.user.welcome) {
            setWelcome(true)
        }

        let valorSaldo = parseFloat(props.user.credit_used)
        setSaldo(valorSaldo)

        getPlans().then(function (response) {
            setTotalPlano(response[0].limit_plan)
            let valor = parseFloat(response[0].value)
            setValorPlan(valor)
            setNamePlan(response[0].name)
        })

        const data = new Date(props.user.plan_due_date);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const ano = data.getFullYear().toString();
        const dataFormatada = `${dia}/${mes}/${ano}`;
        setDataRenovacao(dataFormatada)
        // eslint-disable-next-line
    }, [props.user])

    //Snippet Google aquisição planos
    const handleClickCheckout = (valor) => {

        function gtag_report_conversion(url) {
            var callback = function () {
                if (typeof (url) != 'undefined') {
                    window.location = url;
                }
            };
            window.gtag('event', 'conversion', {
                send_to: 'AW-11085727816/Qm1ACI7DnJYYEMiQi6Yp',
                value: valor,
                currency: 'BRL',
                event_callback: callback,
            });
            return false;
        }

        gtag_report_conversion()

    };

    //teste stripe
    const configure = async () => {
        const stripe = Stripe(process.env.REACT_APP_STRIPE_API);

        async function createCustomerPortalSession() {
            const session = await stripe.billingPortal.sessions.create({
                customer: props.user.idstripe,
                return_url: `${process.env.REACT_APP_RETURN_URL}/dashboard`,
                locale: 'pt-BR',
            }).then(function (result) {
                window.location.href = result.url;
            }).catch(function (error) {
                console.error(error);
            });
        }

        createCustomerPortalSession()
    }

    const avulso = async () => {
        const stripe = Stripe(process.env.REACT_APP_STRIPE_API);

        handleClickCheckout(0.40)

        let customer = null

        if (props.user.idstripe) {
            customer = props.user.idstripe
            createCustomerPortalSession(customer)
        } else {
            customer = await stripe.customers.create({
                email: props.user.email,
                name: props.user.first_name + ' ' + props.user.last_name,
                metadata: {
                    'idUser': `${props.user.iduser}`
                }
            }).then(function (result) {
                createCustomerPortalSession(result.id)
            }).catch(function (error) {
                window.alert("Ocorreu um erro inesperado!")
            });
        }

        async function createCustomerPortalSession(id) {
            const session = await stripe.checkout.sessions.create({
                payment_method_types: ['card'],
                line_items: [
                    {
                        price: 'price_1MsTB6CL10uTvR7KlQlJDRap', // substitua 'price_123' pelo ID do seu produto no Stripe
                        quantity: 100,
                        adjustable_quantity: {
                            enabled: true,
                            minimum: 100,
                            maximum: 999,
                        },
                    },
                ],
                customer: id,
                mode: 'payment',
                success_url: `${process.env.REACT_APP_RETURN_URL}/sucess`,
                cancel_url: `${process.env.REACT_APP_RETURN_URL}/dashboard`,
            });

            window.location.href = session.url;
        }
    }

    let creditosUtilizados = (totalPlano + props.limit.creditos_avulsos) - props.limit.saldo

    return (
        <div className={styles.Home}>
            <Welcome welcome={welcome} id={props.user.iduser} setWelcome={setWelcome} />
            <Grid container spacing={2} breakpoints={{ values: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 } }}>
                <Grid item xs={12} sm={12} md={8}>
                    <Item style={{ backgroundColor: "#00ab5514", boxSizing: "border-box", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
                        <div className={styles.divWelcome}>
                            <p>Bem-vindo de volta!</p>
                            {props.user ? (<p>{props.user.first_name}</p>) : null}
                            <label>Temos 55 milhões de leads a sua espera!</label>
                        </div>
                        <div className={styles.divWelcomeImg}>
                            <img src={dash} alt="dash" />
                            <img className={styles.character} src={character_3} alt="woman-dash" />
                        </div>
                    </Item>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                    <Item style={{ minHeight: "100%", boxSizing: "border-box", backgroundColor: "#00ab5514", display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap", alignItems: "center" }}>
                        <div className={styles.divUpgrade}>
                            <p>Potencialize sua base de leads</p>
                            {props.user.plan_id !== "1" ? (
                                <button style={{ cursor: "pointer" }} onClick={configure}>Fazer upgrade</button>
                            ) : <DrawerBottom user={props.user} />}
                        </div>
                        <img className={styles.imgUpgrade} src={rocket} alt="faça upgrade" />
                    </Item>
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: "20px", minHeight: "350px", boxSizing: "border-box" }} breakpoints={{ values: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 } }}>
                <Grid item xs={12} sm={12} md={4}>

                    <Item style={{ paddingTop: "24px", height: "100%", boxSizing: "border-box", display: "flex", flexDirection: "column" }}>
                        <div className={styles.divChart}>
                            <Item style={{ width: "100%", height: "50%" }}>
                                <div className={styles.homeCard}>
                                    <label>Minhas vendas no ano</label>
                                    <h4>{yearValue}</h4>
                                </div>
                            </Item>
                            <Item style={{ width: "100%", height: "50%", boxSizing: "border-box" }}>
                                <div className={styles.homeCard}>
                                    <label>Minhas vendas no mês</label>
                                    <h4>{monthValue}</h4>
                                </div>
                            </Item>
                        </div>
                    </Item>

                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <Item style={{ paddingTop: "24px", boxSizing: "border-box" }}>
                        <div className={styles.divApex}>
                            {totalItens.length > 0 ? (
                                <ApexChart totalItens={totalItens} />
                            ) : null}
                        </div>
                    </Item>
                </Grid>
            </Grid>

            <Grid container spacing={2} breakpoints={{ values: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 } }}>
                <Grid item xs={12} sm={12} md={4}>
                    <Item style={{
                        padding: "24px", display: "flex", flexDirection: "column", justifyContent: "space-around",
                        minHeight: "170px",
                        boxShadow: " rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px"
                    }}>
                        <div className={styles.suaAssinatura}>
                            <h4>Sua assinatura</h4>
                            {props.user.plan_id > 2 ? (
                                <button onClick={configure}>Alterar Plano</button>
                            ) : <DrawerBottom user={props.user} />}
                        </div>
                        <div className={styles.seuPlano}>
                            <p>Plano</p>
                            <label><b>{namePlan}</b></label>
                        </div>
                        <div className={styles.seuPlano}>
                            <p>Valor</p>
                            <label><b>{valorPlan.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></label>
                        </div>
                        <div className={styles.seuPlano}>
                            <p>Expira em:</p>
                            <label><b>{dataRenovacao}</b></label>
                        </div>
                    </Item>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                    <Item style={{
                        padding: "24px", display: "flex", flexDirection: "column", justifyContent: "space-around",
                        height: "100%",
                        boxShadow: " rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px"
                    }}>

                        <div className={styles.seusCreditos}>
                            <h4>Seus créditos</h4>
                            <button onClick={avulso}>Comprar créditos</button>
                        </div>
                        <div className={styles.creditosContratados}>
                            <div className={styles.creditosContratados_p}>
                                <p>Contratados:</p>
                                <label>{totalPlano}/Mês</label>
                            </div>
                            <p>Avulsos: <label>{props.limit.creditos_avulsos}</label></p>
                        </div>
                        <div className={styles.progress}>
                            <Progress value={parseFloat(saldo)} total={totalPlano} />
                        </div>
                        <div className={styles.creditosUtilizados}>
                            <p>Utilizados: {creditosUtilizados}</p>
                            <label>Disponíveis: {props.limit.saldo}</label>
                        </div>

                    </Item>

                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Item style={{ paddingTop: "24px" }}>

                    </Item>
                </Grid>
            </Grid>


        </div >
    )
}

export default Home
