import * as React from 'react';
import dayjs from 'dayjs';
import ptBr from 'dayjs/locale/pt-br';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, DateAdapter } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const stylesTextField = {
    textFieldLabel: {
        fontWeight: '500', // define a espessura da fonte do label para negrito
        fontFamily: "Inter",
        fontSize: "14px",
        color: "#777",
        '&.Mui-focused': {
            color: '#777', // cor do label quando o textField estiver em foco
            fontWeight: "600",
            fontSize: "14px"
        },
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: 'Inter',
            color: '#282539',
            '&:hover fieldset': {
                borderColor: 'gray', // cor da borda ao passar o mouse sobre o textField
            },
            '&.Mui-focused fieldset': {
                borderColor: "#dfe1e6",
                color: "#777777" // cor da borda quando o textField estiver em foco
            },
        },
    },
};


export default function MaterialUIPickers(props) {
    const [value, setValue] = React.useState("");

    React.useEffect(() => {
        setValue(dayjs(props.dataFechamento))
    }, [])

    React.useEffect(() => {
        setValue(dayjs(props.dataFechamento))
    }, [props.arrayItens])

    const handleChange = (newValue) => {
        updateFechamento(props.id, newValue.toISOString())
    };

    const updateFechamento = (id, newValue) => {
        const novoArray = props.arrayItens.map((objeto) => {
            if (objeto.id === id) {
                return {
                    ...objeto,
                    fechamento_date: newValue
                };
            } else {
                return objeto;
            }
        });

        //atualiza o estado com o novo array
        props.setItens(novoArray);
    };

    return (
        <>
            {props.dataFechamento && props.status === "Fechamento" ? (

                <LocalizationProvider dateAdapter={AdapterDayjs} locale={ptBr} >
                    <Stack spacing={3}>
                        <DesktopDatePicker
                            label="Data da venda"
                            inputFormat="DD/MM/YYYY"
                            value={value}
                            onChange={handleChange}
                            renderInput={(params) => <TextField
                                {...params}
                                InputLabelProps={{
                                    sx: stylesTextField.textFieldLabel
                                }}
                                sx={{
                                    ...stylesTextField.textField,
                                    width: '180px', // defina a largura desejada aqu
                                    marginLeft: "10px",
                                    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                                        padding: "8.5px", // define o padding do input
                                    },
                                    "& .MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-1uvydh2": {
                                        padding: "8.5px", // define o padding do input
                                    },
                                    "& .css-1uvydh2": {
                                        padding: "8.5px",
                                    }
                                }}
                            />}
                        />
                    </Stack>
                </LocalizationProvider>
            ) : null}
        </>
    );
}