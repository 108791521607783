import Switch from '@mui/material/Switch';
import styles from './Switch.module.css'

export default function SwitchUI({checked, textLabel, setChecked, handleChange }) {

  return (
    <div className={styles.switch}>
      <Switch
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        size='small'
      />
      <label className={styles.label}>{textLabel}</label>
    </div>
  );
}
