import styles from './DadosLista.module.css'

import axios from "axios";
import { useEffect, useState } from 'react'
import moment from 'moment'

import { IoIosArrowBack, IoMdBusiness } from "react-icons/io";
import { IoBusiness } from "react-icons/io5";
import { FiMapPin } from "react-icons/fi";
import { SiInstagram, SiFacebook, SiLinkedin, SiGooglemaps, SiGooglemybusiness } from "react-icons/si";
import { BsTelephone, BsWhatsapp } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import ClipLoader from "react-spinners/ClipLoader";

function DadosLista({ id, listaQuery, setCardListVisible, setListaVisible, setListaQuery, setSpinnerVisible, leadsClick }) {
    const [listaDeLeads, setListaDeLeads] = useState([])
    const [cnpjSelect, setCnpjSelect] = useState(null);

    const [cardListResulVisible, setCardResultVisible] = useState("none")

    const recuperaLista = async () => {
        let page = 1;
        let array = []
        let query = listaQuery[0]
        let limit = listaQuery[1]

        const fetchData = async (page) => {
            setSpinnerVisible("")
            setCardResultVisible("none")

            const request = {
                body: query,
                page: page,
                limit: limit
            }

            const url = `${process.env.REACT_APP_API_URL}/downloadList`;

            try {
                const response = await axios.post(url, request, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                return response.data;
            } catch (error) {
                setSpinnerVisible("none")
                setCardResultVisible("")
                console.error(error);
                return error;
            }

        };

        const loadData = async (page) => {
            let result = await fetchData(page);
            array.push(result)
            return result.length === 20000;

        };

        const fetchDataRecursive = async () => {
            let retorno = await loadData(page);

            if (retorno) {
                page++;
                fetchDataRecursive()
            }

            if (!retorno) {
                let accumulatedQuery = [];
                let dadosCsv = []

                for (let i = 0; i < array.length; i++) {
                    accumulatedQuery = [...accumulatedQuery, ...array[i]];
                }
                setSpinnerVisible("none")
                setCardResultVisible("")
                setListaDeLeads(accumulatedQuery)
                setCnpjSelect(accumulatedQuery[0])
            }
        };

        fetchDataRecursive()
    }

    useEffect(() => {
        if (listaQuery.length > 0) {
            recuperaLista(listaQuery)
        }
    }, [listaQuery]);

    function handleInstagramSearch(razaoSocial, cidade, uf) {
        const searchUrl = `https://www.google.com/search?q=${razaoSocial}+${cidade}+${uf}+instagram`;
        window.open(searchUrl, "_blank");
    }

    function handleFacebookSearch(razaoSocial, cidade, uf) {
        const searchUrl = `https://www.google.com/search?q=${razaoSocial}+${cidade}+${uf}+facebook`;
        window.open(searchUrl, "_blank");
    }

    function handleLinkedinSearch(razaoSocial, cidade, uf) {
        const searchUrl = `https://www.google.com/search?q=${razaoSocial}+${cidade}+${uf}+linkedin`;
        window.open(searchUrl, "_blank");
    }

    //socios
    function handleInstagramSearchSocios(razaoSocial) {
        const searchUrl = `https://www.google.com/search?q=${razaoSocial}+instagram`;
        window.open(searchUrl, "_blank");
    }

    function handleFacebookSearchSocios(razaoSocial) {
        const searchUrl = `https://www.google.com/search?q=${razaoSocial}+facebook`;
        window.open(searchUrl, "_blank");
    }

    function handleLinkedinSearchSocios(razaoSocial) {
        const searchUrl = `https://www.google.com/search?q=${razaoSocial}+linkedin`;
        window.open(searchUrl, "_blank");
    }


    function handleMyBusinessSearch(razaoSocial, cidade, uf) {
        const searchUrl = `https://www.google.com/search?q=${razaoSocial}+${cidade}+${uf}`;
        window.open(searchUrl, "_blank");
    }

    function formatarCEP(cep) {
        if (cep && cep.length === 8) {
            const parte1 = cep.substring(0, 5);
            const parte2 = cep.substring(5);
            return `${parte1}-${parte2}`;
        }
        return cep;
    }

    function formatarMoeda(valor) {
        if (valor) {
            return Number(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        }
        return valor;
    }

    function handleClick(numeroTelefone) {
        const link = `https://api.whatsapp.com/send?phone=55${numeroTelefone}`;
        window.open(link, "_blank");
    }


    function abrirGoogleMaps() {
        const endereco = cnpjSelect.logradouro + ' ' + cnpjSelect.numero + ' ' + cnpjSelect.bairro + ' ' + cnpjSelect.municipio + ' ' + cnpjSelect.uf + ' ' + cnpjSelect.cep;
        const url = `https://www.google.com/maps/search/?api=1&query=${endereco.replace(/ /g, '%20')}`;
        window.open(url, '_blank');
    }

    const handleListaVisible = (e) => {
        setCardListVisible("")
        setListaVisible("none")
        setListaQuery("")
        setCnpjSelect("")
        setListaDeLeads([])
    };

    useEffect(() => {
        setCardListVisible("")
        setListaVisible("none")
        setListaQuery("")
        setCnpjSelect("")
        setListaDeLeads([])
    }, [leadsClick])


    //envia para funil
    const [clipLoader, setClipLoaderVisible] = useState("none")
    const [buttonColor, setButtonColor] = useState("#EC7211");
    const [textButton, setTextButton] = useState("Adicionar ao funil")

    const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
    const saveFunil = async () => {
        setClipLoaderVisible("")
        const request = {
            cnpj: cnpjSelect.cnpj,
            razao_social: cnpjSelect.razao_social,
            nome_fantasia: cnpjSelect.nome_fantasia,
            cep: cnpjSelect.cep,
            logradouro: `${cnpjSelect.logradouro} ${cnpjSelect.numero} ${cnpjSelect.complemento}`,
            bairro: cnpjSelect.bairro,
            cidade: cnpjSelect.municipio,
            uf: cnpjSelect.uf,
            anotacoes: [],
            tags: [],
            telefone1: cnpjSelect.telefone_1,
            telefone2: cnpjSelect.telefone_2,
            email: cnpjSelect.email,
            status: "Prospecção",
            create_date: currentDate,
            iduser: id
        }

        const url = `${process.env.REACT_APP_API_URL}/saveFunil`;

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setClipLoaderVisible("none")
            setButtonColor("#239f4f");
            setTextButton("Adicionado!")
            setTimeout(() => {
                setButtonColor("#EC7211");
                setTextButton("Adicionar ao funil")
            }, 5000);
            return response.data;
        } catch (error) {
            console.error(error);
            setClipLoaderVisible("none")
            return error;
        }

    }


    const [places, setPlaces] = useState([]);

    const place = async () => {
        const url = `${process.env.REACT_APP_API_URL}/places`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    query: cnpjSelect.razao_social + " " + cnpjSelect.municipio + " " + cnpjSelect.uf
                }

            });

            setPlaces(response.data || "");

        } catch (error) {
            setPlaces("")
            console.error(error);
            return error;
        }
    }

    useEffect(() => {
        if (cnpjSelect && cnpjSelect.razao_social) {
            place();
        }
    }, [cnpjSelect]);



    return (
        <div className={styles.cardListResult}>
            <div className={styles.cardListContainerDiv} style={{ display: cardListResulVisible }}>
                <div className={styles.mapListas}>
                    <div className={styles.mapListasHeader} onClick={() => handleListaVisible()}>
                        <IoIosArrowBack className={styles.iconsDadosLista} />
                        <span>Voltar para listas adquiridas</span>
                    </div>

                    <div className={styles.divCnpjMap}>
                        {listaDeLeads.map((item, index) => (
                            <div className={styles.cnpjCards} key={index} onClick={() => setCnpjSelect(item)}>
                                <div className={styles.div}>
                                    <IoMdBusiness className={styles.iconsBusiness} />
                                    <h6>{item.razao_social}</h6>
                                </div>
                                <div className={styles.div}>
                                    <FiMapPin className={styles.icons} />
                                    <h6>{item.municipio}-{item.uf}</h6>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.infoListas}>
                    {cnpjSelect ? (
                        <div className={styles.infoHeader}>
                            <IoBusiness className={styles.icons} />
                            <div className={styles.cabecalhoPrincipal}>
                                <div className={styles.cabecalho}>
                                    <div className={styles.ativoMatriz}>
                                        {cnpjSelect.situacao_cadastral === "Ativa" ? (
                                            <div className={styles.tag} style={{ background: "#239f4f" }}>
                                                {cnpjSelect.situacao_cadastral}
                                            </div>
                                        ) : cnpjSelect.situacao_cadastral === "Nula" || cnpjSelect.situacao_cadastral === "Inapta" || cnpjSelect.situacao_cadastral === "Baixada" || cnpjSelect.situacao_cadastral === "Suspensa" ? (
                                            <div className={styles.tag} style={{ background: "#ec3c62" }}>
                                                {cnpjSelect.situacao_cadastral}
                                            </div>
                                        ) :

                                            null}
                                        <p className={styles.matriz}>{cnpjSelect.descricao_identificador_matriz_filial} </p>
                                    </div>
                                    <p className={styles.cnpj}>CNPJ: {cnpjSelect.cnpj}</p>
                                </div>
                                <p className={styles.razao}>{cnpjSelect.razao_social}</p>
                                <div className={styles.socialIcons}>
                                    <SiInstagram className={styles.mediaSocial} onClick={() => handleInstagramSearch(cnpjSelect.razao_social, cnpjSelect.municipio, cnpjSelect.uf)} />
                                    <SiFacebook className={styles.mediaSocial} onClick={() => handleFacebookSearch(cnpjSelect.razao_social, cnpjSelect.municipio, cnpjSelect.uf)} />
                                    <SiLinkedin className={styles.mediaSocial} onClick={() => handleLinkedinSearch(cnpjSelect.razao_social, cnpjSelect.municipio, cnpjSelect.uf)} />
                                    <SiGooglemybusiness className={styles.mediaSocial} onClick={() => handleMyBusinessSearch(cnpjSelect.razao_social, cnpjSelect.municipio, cnpjSelect.uf)} />
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {cnpjSelect ? (
                        <div className={styles.infoCartaoCNPJ}>
                            <div className={styles.inforCartaoArea}>
                                <h6>Cartão CNPJ</h6>

                                <div className={styles.rowGray}>
                                    <label>Número da inscrição / Identificação / Porte:</label>
                                    <p>{cnpjSelect.cnpj} - {cnpjSelect.descricao_identificador_matriz_filial} - {cnpjSelect.descricao_porte}</p>
                                </div>

                                <div className={styles.rowWhite}>
                                    <label>Data de abertura:</label>
                                    <p>{cnpjSelect.data_inicio_atividade}</p>
                                </div>

                                <div className={styles.rowGray}>
                                    <label>Razão social:</label>
                                    <p>{cnpjSelect.razao_social}</p>
                                </div>

                                <div className={styles.rowWhite}>
                                    <label>Nome fantasia:</label>
                                    <p>{cnpjSelect.nome_fantasia}</p>
                                </div>

                                <div className={styles.rowGray}>
                                    <label>Código e descrição da atividade econômica principal:</label>
                                    <p>{cnpjSelect.cnae_fiscal} - {cnpjSelect.cnae_fiscal_descricao}</p>
                                </div>

                                <div className={styles.rowWhiteCnae}>
                                    <label>Código e descrição das atividades econômicas secundárias:</label>
                                    {cnpjSelect.cnaes_secundarios.map((item, index) => (
                                        <p key={index}>{item.codigo} - {item.descricao}</p>
                                    ))}
                                </div>

                                <div className={styles.rowGray}>
                                    <label>Código e descrição da natureza jurídica:</label>
                                    <p>{cnpjSelect.codigo_natureza_juridica} - {cnpjSelect.natureza_juridica}</p>
                                </div>

                                <div className={styles.rowWhite}>
                                    <label>Logradouro:</label>
                                    <p>{cnpjSelect.logradouro}</p>
                                </div>

                                <div className={styles.rowGray}>
                                    <label>Número / Complemento:</label>
                                    <p>{cnpjSelect.numero} {cnpjSelect.complemento}</p>
                                </div>

                                <div className={styles.rowWhite}>
                                    <label>Bairro:</label>
                                    <p>{cnpjSelect.bairro}</p>
                                </div>

                                <div className={styles.rowGray}>
                                    <label>Municipio/UF:</label>
                                    <p>{cnpjSelect.municipio} - {cnpjSelect.uf}</p>
                                </div>

                                <div className={styles.rowWhite}>
                                    <label>CEP:</label>
                                    <p>{formatarCEP(cnpjSelect.cep)}</p>
                                </div>

                                <div className={styles.rowGray}>
                                    <label>Capital social:</label>
                                    <p>{formatarMoeda(cnpjSelect.capital_social)}</p>
                                </div>

                                <h6>Quadro societário</h6>

                                <div className={styles.rowGraySocios}>
                                    <label>Nome / Qualificação:</label>
                                    {cnpjSelect.qsa.map((item, index) => (
                                        <div className={styles.qsaSocios} key={index}>
                                            <div className={styles.nameSocios}>
                                                <p key={index}>{item.nome_socio}</p>
                                                <div className={styles.iconSocios}>
                                                    <SiInstagram className={styles.mediaSocial} onClick={() => handleInstagramSearchSocios(item.nome_socio)} />
                                                    <SiFacebook className={styles.mediaSocial} onClick={() => handleFacebookSearchSocios(item.nome_socio)} />
                                                    <SiLinkedin className={styles.mediaSocial} onClick={() => handleLinkedinSearchSocios(item.nome_socio)} />
                                                </div>
                                            </div>
                                            <label>{item.qualificacao_socio} - {item.descricao_qualificacao}</label>
                                        </div>
                                    ))}
                                </div>

                                <div className={styles.rowWhite}>
                                    <label>Situação cadastral / Data situação cadastral:</label>
                                    <p>{cnpjSelect.situacao_cadastral} - {cnpjSelect.data_situacao_cadastral}</p>
                                </div>

                                <p className={styles.labelDisclaimer}>Todas os dados exibidos são públicos e adquiridos legalmente através da internet. A divulgação de dados públicos de empresa não constitui nenhum tipo de irregularidade conforme já explicito pelos normativos da Receita Federal.</p>

                            </div>
                        </div>
                    ) : null}
                </div>

                <div className={styles.contactListas}>
                    <button style={{ backgroundColor: buttonColor }} className={styles.btnLeadsFunil} onClick={saveFunil}>
                        <div style={{ display: clipLoader }}>
                            <ClipLoader color="white" size={20} />
                        </div>
                        <p>{textButton}</p>
                    </button>

                    <div className={styles.telefone}>
                        <h6>Telefones</h6>
                        {cnpjSelect && cnpjSelect.telefone_1 && cnpjSelect.telefone_1.trim() !== "" && (
                            <div className={styles.divTelefone}>
                                <h5>{cnpjSelect.telefone_1}</h5>
                                <div>
                                    <BsWhatsapp className={styles.mediaSocial} style={{ cursor: 'pointer' }} onClick={() => handleClick(cnpjSelect.telefone_1.replace(/\s+/g, ''))} />
                                    <BsTelephone className={styles.mediaSocial} />
                                </div>
                            </div>
                        )}

                        {cnpjSelect && cnpjSelect.telefone_2 && cnpjSelect.telefone_2.trim() !== "" && (
                            <div className={styles.divTelefone}>
                                <h5>{cnpjSelect.telefone_2}</h5>
                                <div>
                                    <BsWhatsapp className={styles.mediaSocial} style={{ cursor: 'pointer' }} onClick={() => handleClick(cnpjSelect.telefone_2.replace(/\s+/g, ''))} />
                                    <BsTelephone className={styles.mediaSocial} />
                                </div>
                            </div>
                        )}

                        {cnpjSelect && places ? (
                            <div className={styles.divTelefone}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <h5>{places.toString().replace(/\D+/g, "").replace(/(\d{2})(\d{8})/, "$1 $2")}</h5>
                                </div>

                                <div>
                                    <BsWhatsapp className={styles.mediaSocial} style={{ cursor: 'pointer' }} onClick={() => handleClick(cnpjSelect.telefone_1.replace(/\s+/g, ''))} />
                                    <BsTelephone className={styles.mediaSocial} />
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <div className={styles.email}>
                        <h6>Emails</h6>
                        {cnpjSelect && cnpjSelect.email && cnpjSelect.email.trim() !== "" && (
                            <div className={styles.divEmail}>
                                <h5>{cnpjSelect.email.toLowerCase()}</h5>
                                <div>
                                    <HiOutlineMail className={styles.mediaSocial} />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={styles.email}>
                        <div className={styles.divEmail}>
                            <h6>Google Maps</h6>
                            <SiGooglemaps className={styles.mediaSocial} />
                        </div>
                        <button className={styles.btnGoogleMaps} onClick={abrirGoogleMaps}>Abrir rota</button>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default DadosLista