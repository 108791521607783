import styles from '../inputs/Input.module.css'

function Dropdown({ textLabel, handleFunction, value, lista }) {

    return (
        <div className={styles.inputs}>
            <label>{textLabel}</label>
            <select className={styles.select} onChange={handleFunction} value={value}>
                <option value=""></option>
                {lista.map((lista, index) => (
                    <option key={index} value={lista}>{lista}</option>
                ))}
            </select>
        </div>
    )

}

export default Dropdown