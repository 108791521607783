import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';

import { BsFilter } from "react-icons/bs";

import styles from '../layout/desktop/funil/HeaderFunil.module.css'
import YearMonth from '../dateTime Picker/YearMonth';

export default function MenuFilter(props) {
    const [anchorElFil, setAnchorElFil] = React.useState(null);
    const openFil = Boolean(anchorElFil);
    const handleClick = (event) => {
        setAnchorElFil(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorElFil(null);
    };
    
    const [filtro, setFiltro] = React.useState(dayjs().locale('pt-br').format('MM/YYYY'));

    const handleFiltrar = (event) => {
        props.setFiltroData(filtro)
        props.setCount(props.count + 1)
        setAnchorElFil(null);
    };


    return (
        <React.Fragment>
            <div className={styles.filter} onClick={handleClick}>
                <BsFilter className={styles.iconFilter} />
                <p>Filtro</p>
            </div>
            <Menu
                anchorEl={anchorElFil}
                id="account-menu"
                open={openFil}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    <YearMonth setFiltro={setFiltro} filtro={filtro} views={['year', 'month']} label="Filtrar vendas por mês e ano" />
                </MenuItem>
                <div className={styles.divFiltroFilter}>
                    <button className={styles.btnCancelarFiltro} onClick={handleClose}>Cancelar</button>
                    <button className={styles.btnAplicarFiltro} onClick={handleFiltrar}>Filtrar</button>
                </div>
            </Menu>
        </React.Fragment>
    );
}