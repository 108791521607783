import './assinaturaDigital.css'
import {Container } from "@mui/material"
import SignerDescription from './signerDescription'


const stylePaper = {
    color: "rgb(33, 43, 54)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    position: "relative",
    borderRadius: "16px",
    zIndex: 0,
    padding: "40px",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "gb(145 158 171 / 16%) 0px 24px 48px 0px",
    maxWidth: '380px'
}

function AssinaturaDigital({ user }) {
    return (
        <Container sx={{
            minHeight: "calc(100vh - 64px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <SignerDescription user={user} />
        </Container>
    )
}

export default AssinaturaDigital