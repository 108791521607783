import styles from './CardMapping.module.css'

import EstadosLista from '../../../api/Ibge/IbgeEstados'
import Cidades from '../../../api/Ibge/IbgeCidades';
import Input from '../../../inputs/Input'
import { HiInformationCircle } from "react-icons/hi";


import BarLoader from "react-spinners/BarLoader";
import ClipLoader from "react-spinners/ClipLoader";
import StickyBox from "react-sticky-box";

import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';

import { useEffect, useState } from 'react'
import Cnae from '../../../api/bd/Cnae';
import Dropdown from '../../../dropdown/Dropdown';
import NatJur from '../../../api/bd/Natureza';
import Switch from '../../../switch/Switch';
import axios from "axios";

import moment from 'moment'
import SnackbarAlert from '../../snackbar/Snackbar';

import mapPoint from '../../../imagens/map-location-point.svg'
import placeWork from '../../../imagens/places-work.svg'
import filter2 from '../../../imagens/filter2.svg'
import pinUser from '../../../imagens/pin-user.svg'
import business from '../../../imagens/business-building.svg'
import moneyWallet from '../../../imagens/money-wallet.svg'

import Stripe from 'stripe';

function CardMapping({ id, limit, setLimit, user }) {
    //range slider state
    const minValue = 0;
    const maxValue = 1000000;

    const [value, setValue] = useState([50000, 300000]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    function valuetext(value) {
        return formatter.format(value);
    }

    //fim range slider

    const [estado, setEstado] = useState("")
    const [cidade, setCidade] = useState("")
    const [cnae, setCnae] = useState("")
    const [matriz, setMatriz] = useState("")
    const [simples, setSimples] = useState("")
    const [simei, setSimei] = useState("")
    const [natureza, setNatureza] = useState("")
    const [porte, setPorte] = useState("")
    const [situacao, setSituacao] = useState("Ativa")

    const listaMatriz = ["Matriz", "Filial"]
    const listaSimNao = ["Sim", "Não"]
    const listaPorte = ["Não informado", "Micro empresa", "Empresa de pequeno porte", "Demais"]
    const listaSituacao = ["Nula", "Ativa", "Suspensa", "Inapta", "Baixada"]
    const [listaNatureza, setListaNatureza] = useState([])

    const [checkedCnaeSec, setCheckedCnaeSec] = useState(false);
    const [checkedPhone, setCheckedPhone] = useState(false);
    const [checkedEmail, setCheckedEmail] = useState(false);
    const [checkedEmailCorporate, setCheckedEmailCorporate] = useState(false);
    const [checkedContabil, setCheckedContabil] = useState(false);
    const [checkedCapitalSocial, setCheckedCapitalSocial] = useState(false);

    const [countEmpresas, setCountEmpresas] = useState(0)

    const [visibleSlider, setVisibleSlider] = useState("none")

    const [nameSearch, setNameSearch] = useState("")

    const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
    const [visibleSave, setVisibleSave] = useState("none")


    useEffect(() => {
        if (checkedCapitalSocial) {
            setVisibleSlider("")
        } else {
            setVisibleSlider("none")
        }
    }, [checkedCapitalSocial])


    function handleEstado(e) {
        setEstado(e.target.value)
    }

    function handleCidade(e) {
        setCidade(e.target.value)
    }

    function handleCnae(e) {
        setCnae(e)
    }

    function handleMatriz(e) {
        setMatriz(e.target.value)
    }

    function handleSimples(e) {
        setSimples(e.target.value)
    }

    function handleSimei(e) {
        setSimei(e.target.value)
    }

    function handleNatureza(e) {
        setNatureza(e)
    }

    const handleChangeCnaeSec = (e) => {
        setCheckedCnaeSec(e.target.checked)
    }

    const handleChangeCapitalSocial = (event) => {
        setCheckedCapitalSocial(event.target.checked);
    };

    const handleChangePhone = (event) => {
        setCheckedPhone(event.target.checked);
    };

    const handleChangeEmail = (event) => {
        setCheckedEmail(event.target.checked);
    };

    const handleChangeEmailCorporate = (event) => {
        setCheckedEmailCorporate(event.target.checked);
    };


    const handlePorte = (event) => {
        setPorte(event.target.value);
    };

    const handleSituacao = (event) => {
        setSituacao(event.target.value);
    };

    const handleChangeContabil = (event) => {
        setCheckedContabil(event.target.checked);
    };

    const handleNameList = (event) => {
        setNameSearch(event.target.value);
    };

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("")

    const [visibleBuscar, setVisibleBuscar] = useState("")
    const [visibleClipLoader, setVisibleClipLoader] = useState(false)

    const [visibleSaveSearch, setVisibleSaveSearch] = useState("")
    const [visibleClipLoaderSave, setVisibleClipLoaderSave] = useState(false)

    const query = async () => {
        setVisibleClipLoader(true)
        setLoading(true)
        setVisibleBuscar("none")

        let page = 1;
        let array = 0

        let query = `SELECT estabelecimento.*, empresas.* FROM estabelecimento JOIN municipio ON estabelecimento.municipio = municipio.codigo JOIN empresas ON estabelecimento.cnpj_basico = empresas.cnpj_basico`;
        let queryCount = `SELECT LEAST(COUNT(*), 20000) AS count FROM estabelecimento JOIN municipio ON estabelecimento.municipio = municipio.codigo JOIN empresas ON estabelecimento.cnpj_basico = empresas.cnpj_basico`;

        if (simples === "Sim" || simples === "Não" || simei === "Sim" || simei === "Não") {
            query = `SELECT estabelecimento.*, empresas.* FROM estabelecimento JOIN municipio ON estabelecimento.municipio = municipio.codigo JOIN empresas ON estabelecimento.cnpj_basico = empresas.cnpj_basico JOIN simples ON empresas.cnpj_basico = simples.cnpj_basico`;
            queryCount = `SELECT LEAST(COUNT(*), 20000) AS count FROM estabelecimento JOIN municipio ON estabelecimento.municipio = municipio.codigo JOIN empresas ON estabelecimento.cnpj_basico = empresas.cnpj_basico JOIN simples ON empresas.cnpj_basico = simples.cnpj_basico`;
        }


        if (estado) {
            query += ` WHERE estabelecimento.uf = '${estado}'`;
            queryCount += ` WHERE estabelecimento.uf = '${estado}'`;
        }

        if (cidade) {
            query += ` AND municipio.descricao = '${cidade}'`;
            queryCount += ` AND municipio.descricao = '${cidade}'`;
        }

        if (cnae && !checkedCnaeSec) {
            if (cnae.length === 1) {
                query += ` AND estabelecimento.cnae_fiscal = '${cnae[0].codigo}'::character varying`;
                queryCount += ` AND estabelecimento.cnae_fiscal = '${cnae[0].codigo}'::character varying`;
            }

            if (cnae.length > 1) {
                let codigosCnae = []

                for (let i = 0; i < cnae.length; i++) {
                    codigosCnae.push(`'${cnae[i].codigo}'`)
                }

                query += ` AND estabelecimento.cnae_fiscal IN (${codigosCnae})`;
                queryCount += ` AND estabelecimento.cnae_fiscal IN (${codigosCnae})`;

            }
        }

        if (cnae && checkedCnaeSec) {
            if (cnae.length === 1) {
                query += ` AND (estabelecimento.cnae_fiscal = '${cnae[0].codigo}'::character varying OR estabelecimento.cnae_fiscal_secundaria = '${cnae[0].codigo}'::character varying)`;
                queryCount += ` AND (estabelecimento.cnae_fiscal = '${cnae[0].codigo}'::character varying OR estabelecimento.cnae_fiscal_secundaria = '${cnae[0].codigo}'::character varying)`;
            }

            if (cnae.length > 1) {
                let codigosCnaeSec = []

                for (let i = 0; i < cnae.length; i++) {
                    codigosCnaeSec.push(`'${cnae[i].codigo}'`)
                }

                query += ` AND (estabelecimento.cnae_fiscal IN (${codigosCnaeSec}) OR estabelecimento.cnae_fiscal_secundaria IN (${codigosCnaeSec}))`;
                queryCount += ` AND (estabelecimento.cnae_fiscal IN (${codigosCnaeSec}) OR estabelecimento.cnae_fiscal_secundaria IN (${codigosCnaeSec}))`;

            }
        }

        if (matriz === "Matriz") {
            query += ` AND estabelecimento.matriz_filial = 1::character varying`;
            queryCount += ` AND estabelecimento.matriz_filial = 1::character varying`;
        }

        if (matriz === "Filial") {
            query += ` AND estabelecimento.matriz_filial = 2::character varying`;
            queryCount += ` AND estabelecimento.matriz_filial = 2::character varying`;
        }

        if (simples === "Sim") {
            query += ` AND simples.opcao_simples = 'S'::character varying`;
            queryCount += ` AND simples.opcao_simples = 'S'::character varying`;

        }

        if (simples === "Não") {
            query += ` AND simples.opcao_simples = 'N'::character varying`;
            queryCount += ` AND simples.opcao_simples = 'N'::character varying`;
        }

        if (simei === "Sim") {
            query += ` AND simples.opcao_mei = 'S'::character varying`;
            queryCount += ` AND simples.opcao_mei = 'S'::character varying`;
        }

        if (simei === "Não") {
            query += ` AND simples.opcao_mei = 'N'::character varying`;
            queryCount += ` AND simples.opcao_mei = 'N'::character varying`;
        }

        if (natureza) {
            if (natureza.length === 1) {
                query += ` AND empresas.natureza_juridica = '${natureza[0].codigo}'::character varying`;
                queryCount += ` AND empresas.natureza_juridica = '${natureza[0].codigo}'::character varying`;
            }

            if (cnae.length > 1) {
                let codigosNatJu = []

                for (let i = 0; i < cnae.length; i++) {
                    codigosNatJu.push(`'${natureza[i].codigo}'`)
                }

                query += ` AND empresas.natureza_juridica IN (${codigosNatJu})`;
                queryCount += ` AND empresas.natureza_juridica IN (${codigosNatJu})`;
            }

        }

        if (checkedPhone) {
            query += ` AND estabelecimento.telefone1 IS NOT NULL AND COALESCE(estabelecimento.telefone1, '') <> '' `;
            queryCount += ` AND estabelecimento.telefone1 IS NOT NULL AND COALESCE(estabelecimento.telefone1, '') <> '' `;
        }

        if (checkedEmail) {
            query += ` AND estabelecimento.correio_eletronico IS NOT NULL AND COALESCE(estabelecimento.correio_eletronico, '') <> '' `;
            queryCount += ` AND estabelecimento.correio_eletronico IS NOT NULL AND COALESCE(estabelecimento.correio_eletronico, '') <> '' `;
        }

        if (checkedEmailCorporate) {
            query += ` AND estabelecimento.correio_eletronico LIKE '%.com.br%'`;
            queryCount += ` AND estabelecimento.correio_eletronico LIKE '%.com.br%'`;
        }

        if (porte === "Não informado") {
            query += ` AND empresas.porte_empresa = '00'::character varying`;
            queryCount += ` AND empresas.porte_empresa = '00'::character varying`;
        }

        if (porte === "Micro empresa") {
            query += ` AND empresas.porte_empresa = '01'::character varying`;
            queryCount += ` AND empresas.porte_empresa = '01'::character varying`;
        }

        if (porte === "Empresa de pequeno porte") {
            query += ` AND empresas.porte_empresa = '03'::character varying`;
            queryCount += ` AND empresas.porte_empresa = '03'::character varying`;
        }

        if (porte === "Demais") {
            query += ` AND empresas.porte_empresa = '05'::character varying`;
            queryCount += ` AND empresas.porte_empresa = '05'::character varying`;
        }

        if (situacao === "Nula") {
            query += ` AND estabelecimento.situacao_cadastral = '01'::character varying`;
            queryCount += ` AND estabelecimento.situacao_cadastral = '01'::character varying`;
        }

        if (situacao === "Ativa") {
            query += ` AND estabelecimento.situacao_cadastral = '02'::character varying`;
            queryCount += ` AND estabelecimento.situacao_cadastral = '02'::character varying`;
        }

        if (situacao === "Suspensa") {
            query += ` AND estabelecimento.situacao_cadastral = '03'::character varying`;
            queryCount += ` AND estabelecimento.situacao_cadastral = '03'::character varying`;
        }

        if (situacao === "Inapta") {
            query += ` AND estabelecimento.situacao_cadastral = '04'::character varying`;
            queryCount += ` AND estabelecimento.situacao_cadastral = '04'::character varying`;
        }

        if (situacao === "Baixada") {
            query += ` AND estabelecimento.situacao_cadastral = '08'::character varying`;
            queryCount += ` AND estabelecimento.situacao_cadastral = '08'::character varying`;
        }

        if (checkedContabil) {
            query += ` AND estabelecimento.correio_eletronico NOT LIKE '%contabilidade%' AND estabelecimento.correio_eletronico NOT LIKE '%contabil%'`;
            queryCount += ` AND estabelecimento.correio_eletronico NOT LIKE '%contabilidade%' AND estabelecimento.correio_eletronico NOT LIKE '%contabil%'`;
        }

        if (checkedCapitalSocial) {
            if ((value[0] < 1000000 && value[1] < 1000000) || (value[0] < 1000000 && value[1] === 1000000)) {
                query += ` AND empresas.capital_social BETWEEN ${value[0]} AND ${value[1]}`
                queryCount += ` AND empresas.capital_social BETWEEN ${value[0]} AND ${value[1]}`
            }

            if (value[0] === 1000000 && value[1] === 1000000) {
                query += ` AND empresas.capital_social > 1000000`
                queryCount += ` AND empresas.capital_social > 1000000`
            }
        }

        const fetchData = async (page) => {
            setCountEmpresas(0)
            setVisibleSave("none")


            const request = {
                body: queryCount,
                count: page
            }

            setSearch(query)

            const url = `${process.env.REACT_APP_API_URL}/leadsCount`;

            try {
                const response = await axios.post(url, request, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                array = response.data.count
                return response.data.count;

            } catch (error) {
                console.error(error);
                setLoading(false)
                setVisibleBuscar("")
                setVisibleClipLoader(false)
                return error;
            }

        };

        const loadData = async (page) => {
            let result = await fetchData(page);
            return result === 20000;
        };

        const fetchDataRecursive = async () => {
            let retorno = await loadData(page);

            if (retorno) {
                page++;
                fetchDataRecursive()
            }

            if (!retorno) {

                setCountEmpresas(array);
                setLoading(false)
                setVisibleBuscar("")
                setVisibleClipLoader(false)
            }

        };

        fetchDataRecursive()
    }

    const queryByscar = async () => {
        if (estado) {
            query()
        } else {
            setState({ ...state, openAlert: true, color: "#ec3c62", text: "Campo estado é obrigatório" });
        }
    }

    useEffect(() => {
        if (countEmpresas > 0) {
            setVisibleSave("")
        }
    }, [countEmpresas])

    const insertQuery = async () => {
        if (nameSearch) {

            setVisibleSaveSearch("none")
            setVisibleClipLoaderSave(true)

            const request = {
                count: parseInt(limit.saldo) < parseInt(countEmpresas) ? parseInt(limit.saldo) : parseInt(countEmpresas),
                name_list: nameSearch,
                create_date: currentDate,
                search: search,
                iduser: id
            }

            const url = `${process.env.REACT_APP_API_URL}/listSave`;

            try {
                const response = await axios.post(url, request, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                return response.data;
            } catch (error) {
                console.error(error);
                setNameSearch("")
                setVisibleSaveSearch("")
                setVisibleClipLoaderSave(false)
                return error;
            }
        } else {
            setState({ ...state, openAlert: true, color: "#ec3c62", text: "Campo nome da lista é obrigatório" });
        }

    }

    const insertSaldo = async () => {

        const totalLista = parseInt(limit.saldo) < parseInt(countEmpresas) ? parseInt(limit.saldo) : parseInt(countEmpresas)

        const request = {
            count: (-totalLista),
            description: `Consumo de ${totalLista} créditos ref ${nameSearch}`,
            iduser: id,
            type: limit.creditos_assinatura == 0 && limit.creditos_avulsos > 0 ? "Detached" : "Débit",
            create_date: currentDate
        }

        const url = `${process.env.REACT_APP_API_URL}/credit`;

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            setVisibleSave("none") //aqui

            setState({ ...state, openAlert: true, color: '#03a678', text: "Pesquisa salva! Acesse o menu Leads para vizualizar os dados" });

            setVisibleSaveSearch("")
            setVisibleClipLoaderSave(false)
            setNameSearch("")

            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }

    }

    const handleSaveList = async () => {
        insertQuery().then((response) => {
            if (response) {
                insertSaldo().then((response) => {
                    setLimit(response)
                })
            }
        })
    }


    //SnackAlert
    const [state, setState] = useState({
        openAlert: false,
        color: '#03a678',
        text: "Pesquisa salva! Acesse o menu Leads para vizualizar os dados"
    });


    const handleCloseAlert = () => {
        setState({ ...state, openAlert: false });
    };
    //fim SnackAlert


    //Snippet Google aquisição planos
    const handleClickCheckout = (valor) => {

        function gtag_report_conversion(url) {
            var callback = function () {
                if (typeof (url) != 'undefined') {
                    window.location = url;
                }
            };
            window.gtag('event', 'conversion', {
                send_to: 'AW-11085727816/Qm1ACI7DnJYYEMiQi6Yp',
                value: valor,
                currency: 'BRL',
                event_callback: callback,
            });
            return false;
        }
        gtag_report_conversion()
    };



    const avulso = async () => {
        const stripe = Stripe(process.env.REACT_APP_STRIPE_API);

        handleClickCheckout(0.40)

        let customer = null

        if (user.idstripe) {
            customer = user.idstripe
            createCustomerPortalSession(customer)
        } else {
            customer = await stripe.customers.create({
                email: user.email,
                name: user.first_name + ' ' + user.last_name,
                metadata: {
                    'idUser': `${user.iduser}`
                }
            }).then(function (result) {
                createCustomerPortalSession(result.id)
            }).catch(function (error) {
                window.alert("Ocorreu um erro inesperado!")
            });
        }

        async function createCustomerPortalSession(id) {
            const session = await stripe.checkout.sessions.create({
                payment_method_types: ['card'],
                line_items: [
                    {
                        price: 'price_1MsTB6CL10uTvR7KlQlJDRap', // substitua 'price_123' pelo ID do seu produto no Stripe
                        quantity: 100,
                        adjustable_quantity: {
                            enabled: true,
                            minimum: 100,
                            maximum: 999,
                        },
                    },
                ],
                customer: id,
                mode: 'payment',
                success_url: `${process.env.REACT_APP_RETURN_URL}/sucess`,
                cancel_url: `${process.env.REACT_APP_RETURN_URL}/dashboard`,
            });

            window.location.href = session.url;
        }
    }


    return (
        <div className={styles.cardMapping}>
            <SnackbarAlert handleClose={handleCloseAlert} open={state.openAlert} vertical="top" horizontal="right" color={state.color} text={state.text} />
            <h3>Mapeamento de mercado</h3>
            <h6>Encontre mais de 54 milhões de empresas</h6>
            <div className={styles.line}></div>

            <div className={styles.containerSearch}>
                <div className={styles.mappingSearch}>
                    <div className={styles.searchLocalization}>
                        <div className={styles.cardTitle}>
                            <img src={mapPoint} alt={"Map pont"} />
                            <h5>Localização</h5>
                        </div>
                        <div className={styles.card}>
                            <EstadosLista textLabel="Estado" value={estado} handleFunction={handleEstado} />
                            <Cidades textLabel="Cidade" uf={estado} value={cidade} handleFunction={handleCidade} />
                        </div>
                    </div>

                    <div className={styles.searchFeatures}>
                        <div className={styles.cardTitle}>
                            <img src={placeWork} alt={"Características"} />
                            <h5>Características</h5>
                        </div>
                        <div className={styles.card}>
                            <Cnae textLabel="Ramo de atividade (CNAE)" value={cnae} setCnaes={setCnae} handleFunction={handleCnae} />
                            <Switch checked={checkedCnaeSec} textLabel="Mapear Cnaes secundários" setChecked={setCheckedCnaeSec} handleChange={handleChangeCnaeSec} />
                            <Dropdown textLabel="Matriz ou Filial" handleFunction={handleMatriz} value={matriz} lista={listaMatriz} />
                            <Dropdown textLabel="Optante pelo Simples" handleFunction={handleSimples} value={simples} lista={listaSimNao} />
                            <Dropdown textLabel="Optante do MEI" handleFunction={handleSimei} value={simei} lista={listaSimNao} />
                            <NatJur textLabel="Natureza Jurídica" value={natureza} listaNatureza={listaNatureza} setListaNatureza={setListaNatureza} handleFunction={handleNatureza} />
                        </div>
                    </div>

                    <div className={styles.specialFilters}>
                        <div className={styles.cardTitle}>
                            <img src={filter2} alt={"Filtros"} />
                            <h5>Filtros especiais</h5>
                        </div>
                        <div className={styles.card}>
                            <Dropdown textLabel="Porte da empresa" handleFunction={handlePorte} value={porte} lista={listaPorte} />
                            <Dropdown textLabel="Situação cadastral" handleFunction={handleSituacao} value={situacao} lista={listaSituacao} />
                            <Switch checked={checkedCapitalSocial} textLabel="Capital social" setChecked={setCheckedCapitalSocial} handleChange={handleChangeCapitalSocial} />

                            <div className={styles.slider} style={{ display: visibleSlider }}>
                                <div className={styles.label}>
                                    <label>Capital social</label>
                                    <div className={styles.info}>
                                        <HiInformationCircle className={styles.labelInfo} />
                                        <label><em>Para valores acima de R$ 1 milhão mova os dois ponteiro até o final</em></label>
                                    </div>
                                </div>

                                <Box sx={{ width: `100%` }}>
                                    <Slider
                                        getAriaLabel={() => 'Single Slider'}
                                        value={value}
                                        onChange={handleChange}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuetext}
                                        style={{ color: '#03a678' }}
                                        min={minValue}
                                        max={maxValue}
                                    />
                                </Box>
                                {(value[0] < 1000000 && value[1] < 1000000) || (value[0] < 1000000 && value[1] === 1000000)
                                    ? <label>Entre <b>{formatter.format(value[0])}</b> e <b>{formatter.format(value[1])}</b></label>
                                    : null
                                }

                                {value[0] === 1000000 && value[1] === 1000000
                                    ? <label>Acima de <b>R$ 1 milhão</b></label>
                                    : null
                                }
                            </div>


                            <Switch checked={checkedPhone} textLabel="Contenha telefone" setChecked={setCheckedPhone} handleChange={handleChangePhone} />
                            <Switch checked={checkedEmail} textLabel="Contenha e-mail" setChecked={setCheckedEmail} handleChange={handleChangeEmail} />
                            <Switch checked={checkedEmailCorporate} textLabel="E-mail corporativo (.com.br)" setChecked={setCheckedEmailCorporate} handleChange={handleChangeEmailCorporate} />
                            <Switch checked={checkedContabil} textLabel="Ignorar empresas com e-mail do contador" setChecked={setCheckedContabil} handleChange={handleChangeContabil} />
                        </div>
                    </div>
                    <div className={styles.barLoader}>
                        {loading ? <BarLoader color="#005c53" height={5} width={320} /> : <div></div>}
                        {loading ? <p>Mapeando leads... Aguarde o término do mapeamento!</p> : <div></div>}
                    </div>
                    <button className={styles.buttonSearch} onClick={queryByscar}>
                        <p style={{ display: visibleBuscar }}>Buscar</p>
                        {visibleClipLoader ? (
                            <ClipLoader
                                color="white"
                                size={25}
                                speedMultiplier={1}
                            />
                        ) : null}
                    </button>
                </div>

                <div className={styles.mappingResult}>
                    <StickyBox offsetTop={20} offsetBottom={20}>
                        <div className={styles.specialFilters}>
                            <div className={styles.cardTitle}>
                                <img src={pinUser} alt={"Resultado"} />
                                <h5>Resultado</h5>
                            </div>
                            <div className={styles.card}>
                                <div className={styles.cardResultEmpresas}>
                                    <img src={business} alt={"Empresas encontradas"} />
                                    <h3>{countEmpresas}</h3>
                                    <h6>Empresas encontradas</h6>
                                </div>
                                <div className={styles.cardResultSaldo}>
                                    <img src={moneyWallet} alt={"Saldo"} />
                                    {limit.saldo > 1000000 ? <h3>Ilimitado</h3> : <h3>{limit.saldo}</h3>}
                                    <h6>Saldo de empresas</h6>
                                </div>
                            </div>

                            {parseFloat(limit.saldo) > countEmpresas ? (
                                <div className={styles.divSaveSearch} style={{ display: visibleSave }}>
                                    <h5>Ao salvar, sua pesquisa consumirá {countEmpresas} créditos do seu saldo</h5>
                                    <Input textLabel="Dê um nome para sua pesquisa" type="text" handleFunction={handleNameList} />
                                    <button className={styles.buttonSearch} onClick={() => handleSaveList()}>
                                        <p style={{ display: visibleSaveSearch }}>Salvar pesquisa</p>
                                        {visibleClipLoaderSave ? (
                                            <ClipLoader
                                                color="white"
                                                size={25}
                                                speedMultiplier={1}
                                            />
                                        ) : null}
                                    </button>
                                </div>
                            ) : null}
                            {parseFloat(limit.saldo) < countEmpresas ? (
                                <div className={styles.divSaveSearch} style={{ display: visibleSave }}>
                                    <div className={styles.divNotCredit}>
                                        <h5>Você não possui créditos suficientes para salvar esta pesquisa. Altere os filtros ou adquira créditos</h5>
                                    </div>
                                    {limit.saldo > 0 ? <Input textLabel="Dê um nome para sua pesquisa" type="text" handleFunction={handleNameList} /> : null}
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {limit.saldo === 0 ? <button className={styles.buttonSearch} style={{ marginRight: "0px" }} onClick={avulso}>Adquirir créditos</button> :
                                            <button className={styles.buttonSearch} style={{ marginRight: "20px" }} onClick={avulso}>Adquirir créditos</button>
                                        }

                                        {limit.saldo > 0 ?
                                            <button className={styles.buttonSearch} style={{ background: "#212B36" }} onClick={() => handleSaveList()}>Salvar {limit.saldo} leads</button>
                                            : null
                                        }
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </StickyBox>
                </div>

            </div>
        </div >
    )
}

export default CardMapping
