import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { PublicClientApplication } from '@azure/msal-browser';
import { config } from '../../oauthConfig'
import axios from 'axios'

export default function BasicButtonsMicrosoft(props) {
    const msalInstance = new PublicClientApplication({
        auth: {
            clientId: 'cc1dcd25-8939-4b66-888a-87a3003c35a7',
        }
    });

    const [userPhoto, setUserPhoto] = useState('');

    const handleLogin = async () => {
        props.acaoLogin(props.valueLogin)
        props.acaoCad(!props.valueLogin)
        try {
            const response = await msalInstance.loginPopup();
            props.setUser(response.account)
        } catch (error) {
            console.error('Erro ao autenticar usuário:', error);
        }
    };


    return (
        <Stack
            spacing={2}
            direction="row"
            sx={{
                width: '100%',
                marginBottom: "15px"
            }}>
            <Button
                variant="outlined"
                size='large'
                startIcon={<img style={{ width: "20px", marginRight: "30px" }} src={props.logo} />}
                sx={{
                    width: '100%',
                    height: "52px",
                    borderColor: "#c2c8d0",
                    fontFamily: 'Inter',
                    fontSize: "14px",
                    justifyContent: "flex-start",
                    color: "#637381",
                    ":hover": {
                        color: "#03a678",
                        borderColor: "#03a678",
                        bgcolor: "white"
                    }
                }}
                onClick={handleLogin}
            >
                {props.text}
            </Button>
        </Stack>
    );
}
