import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AvisoLimit(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.setAviso(false);
    };

    React.useEffect(() => {
        if (props.aviso) {
            handleClickOpen();
        }
    }, [props.aviso]);
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{
                    fontFamily: "Inter",
                    color: "#212b36"
                }}>
                    {"Limite de exportação atingido!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{
                        fontFamily: "Inter",
                        color: "#637381"
                    }}>
                        Você atingiu o limite de exportação de empresas para este plano. Para continuar exportando empresas, é necessário atualizar seu plano ou aguardar a renovação da sua assinatura.
                        Entre em contato com nossa equipe de suporte se precisar de ajuda.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button style={{
                        color: "white",
                        background: "#00AB55",
                        width: "60px",
                        padding: "5px",
                        borderRadius: "4px",
                        border: "none",
                        fontFamily: 'Inter',
                        fontSize: "12px",
                        cursor: "pointer",
                    }}
                        onClick={handleClose}
                    >
                        Ok
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}