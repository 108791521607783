
import { Draggable } from 'react-beautiful-dnd';
import './Funil.css'
import { BsTelephone, BsWhatsapp } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { MdContentCopy } from "react-icons/md";

import { Divider, Popover } from '@mui/material';
import Rating from '@mui/material/Rating';

import { useEffect, useState } from "react";
import axios from "axios";
import SnackbarAlert from '../../snackbar/Snackbar';
import DialogFunil from '../../../dialog/DialogFunil';
import Tags from '../../../etiquetas/Tag';
import LongMenu from '../../../menu/MaxHeightMenu';

const Item = (props) => {

    //SnackAlert
    const [state, setState] = useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'right',
    });

    const { vertical, horizontal, openAlert } = state;

    const handleClickAlert = (newState) => () => {
        setState({ openAlert: true, ...newState });
    };

    const handleCloseAlert = () => {
        setState({ ...state, openAlert: false });
    };
    //fim SnackAlert

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorTel, setAnchorTel] = useState(null);
    const [anchorEmail, setAnchorEmail] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickAnchorTel = (event, tel) => {
        place(tel)
        setAnchorTel(event.currentTarget);
    };

    const handleClickAnchorEmail = (event) => {
        setAnchorEmail(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseTel = () => {
        setAnchorTel(null);
    };

    const handleCloseEmail = () => {
        setAnchorEmail(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const openTel = Boolean(anchorTel);
    const tel = openTel ? 'simple-popover' : undefined;

    const openEmail = Boolean(anchorEmail);
    const email = openEmail ? 'simple-popover' : undefined;

    function handleClickTel(numeroTelefone) {
        const link = `https://api.whatsapp.com/send?phone=55${numeroTelefone}`;
        window.open(link, "_blank");
    }


    const updateRatingFunil = async (nota) => {

        const request = {
            nota: nota,
            id: props.item.id
        }

        const url = "https://jmqww2wzofg2kdvnwspxliuys40lfcle.lambda-url.us-east-1.on.aws/updateRatingFunil";

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    const [rating, setRating] = useState(props.item.rating);
    const [avaliadores, setAvaliadores] = useState(props.item.quantidade_avaliadores)
    const [newRating, setNewRating] = useState(0)

    const handleRatingChange = async (event, newValue) => {
        await new Promise((resolve) => {
            let novoRating = (rating * avaliadores + newValue) / (avaliadores + 1)
            setNewRating(novoRating);
            setAvaliadores(avaliadores + 1)
            resolve();
        });

        updateRatingFunil(newValue);
    }

    function copyToClipboard(text) {
        navigator.clipboard.writeText(text.toLowerCase())
            .then(() => {
                setState({
                    openAlert: true, vertical: 'top',
                    horizontal: 'right',
                });
            })
            .catch((error) => console.error('Error copying text: ', error));
    }

    const [showControl, setShowControl] = useState(false)

    const Call = async (number) => {
        props.setNumberCall(number)
        
        const request = {
            ramal: props.user.ramal,
            destination: number,
            destination: number,
            iduser: props.user.iduser
        }

        const url = `${process.env.REACT_APP_API_URL}/calls`;

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response.data)
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    const [places, setPlaces] = useState("");

    const place = async (tel) => {
        const url = `${process.env.REACT_APP_API_URL}/places`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    query: tel.razao_social + " " + tel.cidade + " " + tel.uf
                }

            });

            setPlaces(response.data || "");

        } catch (error) {
            setPlaces("")
            console.error(error);
            return error;
        }
    }


    return (
        <Draggable draggableId={props.item.id.toString()} index={props.index}>
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className="item"
                >
                    <SnackbarAlert handleClose={handleCloseAlert} open={openAlert} vertical={vertical} horizontal={horizontal} color='#239F4F' text="Copiado!" />
                    <Tags arrayItens={props.arrayItens} id={props.item.id} setItens={props.setItens} tags={props.item.tags} clique={true} showControl={showControl} show={showControl} setShowControl={setShowControl} />
                    <div className='h3Razao'>
                        <h3>{props.item.razao_social}</h3>
                        <LongMenu arrayItens={props.arrayItens} id={props.item.id} setItens={props.setItens} />
                    </div>

                    <div className='divRating'>
                        <p>{props.item.cidade}-{props.item.uf}</p>
                        <Rating name="half-rating" defaultValue={props.item.rating} precision={0.5} onChange={handleRatingChange} />
                        {avaliadores ? (<span className='span'>({avaliadores})</span>) : null}
                    </div>

                    <Divider sx={{
                        marginTop: '20px',
                    }} />

                    <div className='itemIcons'>

                        <p>{props.item.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>

                        <div className='divIcones'>
                            <DialogFunil dataFechamento={props.item.fechamento_date} setItens={props.setItens} arrayItens={props.arrayItens} id={props.item.id} valor={props.item.valor} tags={props.item.tags} razao_social={props.item.razao_social} status={props.item.status} anotacao={props.item.anotacoes} />
                            <BsWhatsapp className='icons' aria-describedby={id} variant="contained" onClick={handleClick} />
                            <BsTelephone className='icons' aria-describedby={tel} variant="contained" onClick={(event) => handleClickAnchorTel(event, props.item)} />
                            <HiOutlineMail className='icons' aria-describedby={email} variant="contained" onClick={handleClickAnchorEmail} />
                        </div>
                    </div>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div className='popover'>
                            {props.item.telefone1 && props.item.telefone1.trim().length > 0 && (
                                <div className='divPopover'>
                                    <span className='receitaChecked'></span>
                                    <p>{props.item.telefone1}</p>

                                    <MdContentCopy className='icons' onClick={() => copyToClipboard(props.item.telefone1)} />
                                    <button onClick={() => handleClickTel(props.item.telefone1.replace(/\s+/g, ''))}>Abrir</button>
                                </div>
                            )}
                            {props.item.telefone2 && props.item.telefone2.trim().length > 0 && (
                                <div className='divPopover'>
                                    <span className='receitaChecked'></span>
                                    <p>{props.item.telefone2}</p>
                                    <MdContentCopy className='icons' onClick={() => copyToClipboard(props.item.telefone2)} />
                                    <button onClick={() => handleClickTel(props.item.telefone2.replace(/\s+/g, ''))}>Abrir</button>
                                </div>
                            )}
                            {places ? (
                                <div className='divPopover'>
                                    <span className='googleChecked'></span>
                                    <p>{places.replace(/\D+/g, "").replace(/(\d{2})(\d{8})/, "$1 $2")}</p>
                                    <MdContentCopy className='icons' onClick={() => copyToClipboard(props.item.telefone2)} />
                                    <button onClick={() => handleClickTel(places.replace(/\s+/g, ''))}>Abrir</button>
                                </div>
                            ) : null}


                        </div>
                    </Popover>

                    <Popover
                        id={tel}
                        open={openTel}
                        anchorEl={anchorTel}
                        onClose={handleCloseTel}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div className='popoverTel'>
                            {props.item.telefone1 && props.item.telefone1.trim().length > 0 && (
                                <div className='divPopover'>
                                    <span className='receitaChecked'></span>
                                    <p>{props.item.telefone1}</p>
                                    {props.user.voip ? <BsTelephone className='icons' onClick={() => Call(props.item.telefone1.replace(/\s+/g, ''))} /> : null}
                                    <MdContentCopy className='icons' onClick={() => copyToClipboard(props.item.telefone1)} />
                                </div>
                            )}
                            {props.item.telefone2 && props.item.telefone2.trim().length > 0 && (
                                <div className='divPopover'>
                                    <span className='receitaChecked'></span>
                                    <p>{props.item.telefone2}</p>
                                    {props.user.voip ? <BsTelephone className='icons' onClick={() => Call(props.item.telefone1.replace(/\s+/g, ''))} /> : null}
                                    <MdContentCopy className='icons' onClick={() => copyToClipboard(props.item.telefone2)} />
                                </div>
                            )}

                            {places ? (
                                <div className='divPopover'>
                                    <span className='googleChecked'></span>
                                    <p>{places.replace(/\D+/g, "").replace(/(\d{2})(\d{8})/, "$1 $2")}</p>
                                    {props.user.voip ? <BsTelephone className='icons' onClick={() => Call(places.replace(/\D+/g, "").replace(/(\d{2})(\d{8})/, "$1 $2"))} /> : null}
                                    <MdContentCopy className='icons' onClick={() => copyToClipboard(places)} />
                                </div>
                            ) : null}
                        </div>
                    </Popover>

                    <Popover
                        id={email}
                        open={openEmail}
                        anchorEl={anchorEmail}
                        onClose={handleCloseEmail}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div className='popoverEmail'>
                            {props.item.email && props.item.email.trim().length > 0 && (
                                <div className='divPopover'>
                                    <p>{props.item.email.toLowerCase()}</p>
                                    <MdContentCopy className='icons' onClick={() => copyToClipboard(props.item.email)} />
                                </div>
                            )}
                        </div>
                    </Popover>
                </div>
            )}
        </Draggable>
    );
};

export default Item;
