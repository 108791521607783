import React from 'react'
import { useEffect, useState } from 'react';
import JsSIP from 'jssip';

var socket = new JsSIP.WebSocketInterface('wss://leadsmapping.api4com.com:6443');

const configuration = {
    uri: 'sip:1001@leadsmapping.api4com.com', // FILL SIP URI HERE like sip:sip-user@your-domain.bwapp.bwsip.io
    password: 'ejrxgdjj', // FILL PASSWORD HERE,
    sockets  : [ socket ],
    ws_servers: 'wss://leadsmapping.api4com.com:6443'
};

const incomingCallAudio = new window.Audio('./beep-01.wav');
incomingCallAudio.loop = true;
const remoteAudio = new window.Audio();
remoteAudio.autoplay = true;

var callOptions = {
    mediaConstraints: { audio: true, video: false }
};

function Teste() {
    const [numero, setNumero] = useState('');
    const [phone, setPhone] = useState(null);
    const [session, setSession] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isMuted, setIsMuted] = useState(false);


    const handleNumero = (event) => {
        setNumero(event.target.value);
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "getSelo";
        script.src = 'https://jssip.net/download/releases/jssip-0.7.23.min.js';
        script.async = true;

        script.onload = () => {

            JsSIP.debug.enable('JsSIP:*'); // more detailed debug output
            const phone = new JsSIP.UA(configuration);
            phone.on('registrationFailed', function (ev) {
                alert('Registering on SIP server failed with error: ' + ev.cause);
                configuration.uri = null;
                configuration.password = null;
                updateUI();
            });
            phone.on('newRTCSession', function (ev) {
                var newSession = ev.session;
                if (session) { // hangup any existing call
                    session.terminate();
                }
                session = newSession;
                var completeSession = function () {
                    session = null;
                    updateUI();
                };
                session.on('ended', completeSession);
                session.on('failed', completeSession);
                session.on('accepted', updateUI);
                session.on('confirmed', updateUI);
                session.on('addstream', function (e) {
                    incomingCallAudio.pause();
                    remoteAudio.src = window.URL.createObjectURL(e.stream);
                });
                if (session.direction === 'incoming') {
                    incomingCallAudio.play();
                }
                updateUI();
            });
            phone.start();
        };

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };

    }, []);

    var secao = new JsSIP.UA(configuration);

    function connectCall() {
        var dest = numero;
        secao.call(dest, callOptions);
        updateUI();
    }

    function answerCall() {
        secao.answer(callOptions);
    }


    function hangUp() {
        secao.terminate();
        //hangup
    }

    function reject() {
        secao.terminate();
        //hangup
    }

    function mute() {
        console.log('MUTE CLICKED');
        if (secao.isMuted().audio) {
            secao.unmute({ audio: true });
        } else {
            secao.mute({ audio: true });
        }
        updateUI();
    }


    // $('#inCallButtons').on('click', '.dialpad-char', function (e) {
    //     var $target = $(e.target);
    //     var value = $target.data('value');
    //     session.sendDTMF(value.toString());
    // });

    function updateUI() {
        if (configuration.uri && configuration.password) {
            // $('#errorMessage').hide();
            // $('#wrapper').show();
            if (secao) {
                if (secao.isInProgress()) {
                    if (secao.direction === 'incoming') {
                        // $('#incomingCallNumber').html(session.remote_identity.uri);
                        // $('#incomingCall').show();
                        // $('#callControl').hide()
                        // $('#incomingCall').show();
                    } else {
                        // $('#callInfoText').html('Ringing...');
                        // $('#callInfoNumber').html(session.remote_identity.uri.user);
                        // $('#callStatus').show();
                    }

                } else if (secao.isEstablished()) {
                    // $('#callStatus').show();
                    // $('#incomingCall').hide();
                    // $('#callInfoText').html('In Call');
                    // $('#callInfoNumber').html(session.remote_identity.uri.user);
                    // $('#inCallButtons').show();
                    incomingCallAudio.pause();
                }
                // $('#callControl').hide();
            } else {
                // $('#incomingCall').hide();
                // $('#callControl').show();
                // $('#callStatus').hide();
                // $('#inCallButtons').hide();
                incomingCallAudio.pause();
            }
            //microphone mute icon
            if (secao && secao.isMuted().audio) {
                // $('#muteIcon').addClass('fa-microphone-slash');
                // $('#muteIcon').removeClass('fa-microphone');
            } else {
                // $('#muteIcon').removeClass('fa-microphone-slash');
                // $('#muteIcon').addClass('fa-microphone');
            }
        } else {
            // $('#wrapper').hide();
            // $('#errorMessage').show();
        }
    }

    return (
        <div>
            <input id="tofield" placeholder='Numero' value={numero} onChange={handleNumero}></input>

            <button onClick={connectCall}>ConectCall</button>

            <div id="answer" onClick={answerCall}> <i class="fa fa-phone"></i></div>
            <div id="hangUp" onClick={hangUp}> <i class="fa fa-phone"></i>
                <div id="reject" onClick={hangUp}> <i class="fa fa-phone"></i></div>
            </div>

            <div id="mute" onClick={mute}>
                <i id="muteIcon" class="fa fa-microphone"></i>
            </div>
        </div>
    );
}


export default Teste;
