import styles from './HeaderFunil.module.css'
import { useEffect, useState } from 'react';
import MenuFilter from '../../../menu/MenuFilter';
import TemporaryDrawer from '../../../drawer/DrawerRight';

import FiltroCNPJ from '../../../dialog/FiltroCNPJ';
import ImportExcel from '../../../dialog/Import';

function HeaderFunil(props) {
    const [yearValue, setYearValue] = useState(0)
    const [monthValue, setMonthValue] = useState(0)

    function valueYear() {
        const totalValue = props.filtroAno.reduce((accumulator, item) => {
            return accumulator + item.valor;
        }, 0);

        const formattedValueYear = totalValue ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(totalValue) : new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(0);

        setYearValue(formattedValueYear)

    }

    //retorna vendas do mês
    const valueMonth = () => {
        const totalValueMonth = props.itensFiltrados.reduce((accumulator, item) => {
            return accumulator + item.valor;
        }, 0);

        const formattedValueMonth = totalValueMonth ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(totalValueMonth) : new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(0);

        setMonthValue(formattedValueMonth)
    }

    useEffect(() => {
        valueYear()
        valueMonth()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        valueYear()
        valueMonth()
        // eslint-disable-next-line
    }, [props.itensFiltrados])

    return (
        <div className={styles.headerFunil}>
            <div className={styles.divHead}>
                <h4>Funil de Vendas</h4>
                <FiltroCNPJ id={props.id} setItens={props.setItens} />
                <ImportExcel id={props.id} setItens={props.setItens} />
            </div>
            <div className={styles.div}>
                <div className={styles.value}>
                    <label>Vendas no ano:</label>
                    <p>{yearValue}</p>
                </div>
                <div className={styles.value1}>
                    <label>Vendas no mês:</label>
                    <p>{monthValue}</p>
                </div>
                <MenuFilter count={props.count} setCount={props.setCount} setFiltroData={props.setFiltroData} />
                <TemporaryDrawer arrayItens={props.arrayItens} setItens={props.setItens} item={props.item.filter((item) => item.status === 'Arquivado')} />
            </div>
        </div>
    )
}

export default HeaderFunil