import ReactApexChart from 'react-apexcharts';
import 'apexcharts/dist/apexcharts.css';
import { useState, useEffect } from 'react';
import "./ApexFunil.css"


function ApexChart({ totalItens }) {
    const [valores, setValores] = useState([])

    useEffect(() => {
        setValores(totalItens)
    }, [totalItens])

    const state = {

        series: [{
            data: valores,
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350,
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                }
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
            },
            tooltip: {
                shared: true,
                intersect: false
            },
            title: {
                text: 'Funil de vendas',
                style: {
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#212b36"

                }
            },
            xaxis: {
                categories: ['Prospeção', 'Contato', 'Negociação', 'Fechamento'],
            },


        },

    }

    return (
        <div id="chart">
            <ReactApexChart options={state.options} series={state.series} type="bar" height={300} />
        </div>
    );
}

export default ApexChart