import styles from './Input.module.css'

function Input({textLabel, type, maxLength, value, handleFunction }) {
    return (
        <div className={styles.inputs}>
            <label>{textLabel}</label>
            <input className={styles.inputLayout} type={type} autoComplete="nope" maxLength={maxLength} value={value}
                               onChange={handleFunction}/>
        </div>
    )
}

export default Input