import React from "react";
import BarLoader from "react-spinners/BarLoader";
import styles from './Spinner.module.css'

const Spinner = ({display, label}) => {
    return (
        <div className={styles.containerSpinner} style={{display: display}}>
            <BarLoader color="#005c53" height={5} width={320}  cssOverride={{}} />
            <label>{label}</label>
        </div>
    );
};

export default Spinner;
