
import './index.css';

import logoFundo from './components/imagens/svgviewer-output.svg'
import character from './components/imagens/character_6.png'
import Box from '@mui/material/Box';

function Erro404() {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      padding: "20px",
      alignItems: "flex-start",
      margin: "auto",
      maxWidth: "400px",
      textAlign: "center",
      justifyContent: "center"
    }}>
      <div className='container404'>
        <h3>Página não encontrada</h3>
        <p>Desculpe, não encontramos a página que você está procurando. Talvez você tenha digitado errado o URL? Certifique-se de verificar a ortografia.</p>
        <div className='container404Img'>
          <img className='imgFundo' src={logoFundo} alt="Página não encontrada" />
          <img className='imgSobreposta' src={character} alt="Página não encontrada" />
        </div>
      </div>
    </Box>
  )
}

export default Erro404