import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import styled from './SpeedDial.module.css'

import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import { Button, IconButton, Paper, Snackbar } from '@mui/material';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import CallEndRoundedIcon from '@mui/icons-material/CallEndRounded';
import CloseIcon from '@mui/icons-material/Close';
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded';
import axios from 'axios';

const styles = {
    with: "50px",
    height: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "24px",
    fontWeight: "600",
    fontFamily: "Inter",
    boxShadow: "none",
    color: '#637381',
    cursor: "pointer",
}



export default function BasicSpeedDial({ calling, user, hangup, numberDial }) {

    const [numberCall, setNumberCall] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const handleClickNumber = (num) => {
        setNumberCall(numberCall + num);
    };

    const deleteClickNumber = (num) => {
        setNumberCall(prev => prev.slice(0, -1));
    };

    const handleClick = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setNumberCall("");
        setOpen(false);
        setAnchorEl(null);
    };

    const Call = async (number) => {
        const request = {
            ramal: user.ramal,
            destination: numberCall,
            destination: numberCall,
            iduser: user.iduser
        }

        const url = `${process.env.REACT_APP_API_URL}/calls`;

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response.data)
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }



    const action = [
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "250px",
        }}>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }} >
                <CloseIcon />
            </IconButton>

            <input className={styled.diskCall} type="text" value={numberCall} placeholder="Disque ou digite um número" onChange={(event) => setNumberCall(event.target.value)} />

            {calling ? (<span className={styled.statusCall}>Em andamento...</span>) : null}
            
            <Box sx={{
                width: "100%",
                gap: "10px",
                display: "grid",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                gridTemplateColumns: "repeat(3, 1fr)",
                boxSizing: "border-box",
                marginTop: "30px",
            }}>
                <Paper key="1" sx={styles} onClick={() => handleClickNumber("1")}>1</Paper>
                <Paper key="2" sx={styles} onClick={() => handleClickNumber("2")}>2</Paper>
                <Paper key="3" sx={styles} onClick={() => handleClickNumber("3")}>3</Paper>
                <Paper key="4" sx={styles} onClick={() => handleClickNumber("4")}>4</Paper>
                <Paper key="5" sx={styles} onClick={() => handleClickNumber("5")}>5</Paper>
                <Paper key="6" sx={styles} onClick={() => handleClickNumber("6")}>6</Paper>
                <Paper key="7" sx={styles} onClick={() => handleClickNumber("7")}>7</Paper>
                <Paper key="8" sx={styles} onClick={() => handleClickNumber("8")}>8</Paper>
                <Paper key="9" sx={styles} onClick={() => handleClickNumber("9")}>9</Paper>
                <Paper key="10" sx={styles} onClick={() => handleClickNumber("*")}>*</Paper>
                <Paper key="11" sx={styles} onClick={() => handleClickNumber("0")}>0</Paper>
                <Paper key="12" sx={styles} onClick={deleteClickNumber}><BackspaceRoundedIcon fontSize='small' /></Paper>
            </Box>

            {!calling ? (
                <Button 
                onClick={Call}
                sx={{
                    width: "100%",
                    height: "50px",
                    backgroundColor: '#27D75D',
                    '&:hover': {
                        backgroundColor: '#27D75D',
                    },
                    margin: "20px"
                }}>
                    <CallRoundedIcon fontSize="medium" sx={{ color: "#FFF" }} />
                </Button>
            ) : (<Button 
                onClick={hangup}
                sx={{
                width: "100%",
                height: "50px",
                backgroundColor: '#FF3A32',
                '&:hover': {
                    backgroundColor: '#FF3A32',
                },
                margin: "10px",
            }}>
                <CallEndRoundedIcon fontSize="medium" sx={{ color: "#FFF" }}/>
            </Button>
            )}
        </div>
    ];

    React.useEffect(() => {
        if (calling) {
            setOpen(true)
        }
    }, [calling])

    React.useEffect(() => {
        setNumberCall(numberDial)
    }, [numberDial])



    return (
        <>
            {open ? null : (
                <SpeedDial
                    key="speedDial"
                    ariaLabel="SpeedDial basic example"
                    onClick={handleClick}
                    sx={{
                        position: 'fixed',
                        bottom: 21,
                        right: 21,
                        alignItems: "flex-end",
                        "& .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab": {
                            backgroundColor: "#3366FF",
                            width: "58px",
                            height: "58px",
                        }
                    }}
                    icon={<PhoneInTalkRoundedIcon fontSize='large' />}
                />
            )}

            <Snackbar
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                action={action}
                sx={{
                    padding: "10px",
                    "& .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root": {
                        minWidth: "200px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                    "& .css-1kr9x0n-MuiSnackbarContent-action": {
                        marginRight: "0px",
                        marginLeft: "0px",
                        paddingLeft: "0px",
                    },
                    "& .css-zykra6": {
                        marginRight: "0px",
                        paddingLeft: "0px",
                        margin: "auto"
                    }
                }}
            />
        </>
    );
}