import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.8, 0.8, 0.9, 0),
}));

const StylePaper = styled(Paper)({
    border: 'none',
    boxShadow: 'none',
});

export default function Tags(props) {
    function showControlTags() {
        props.setShowControl(!props.show)
    }

    const [chipData, setChipData] = React.useState([
        { key: 0, label: 'Não interessado', style: { backgroundColor: "#FF8F84", color: 'white' } },
        { key: 1, label: 'Reunião agendada', style: { backgroundColor: "#66BDC4", color: 'white' } },
        { key: 2, label: 'Proposta enviada', style: { backgroundColor: "#CC94FF", color: 'white' } },
        { key: 3, label: 'Contrato enviado', style: { backgroundColor: "#54D579", color: 'white' } },
    ]);

    const handleDelete = (chipToDelete) => () => {
        removeTag(props.id, chipToDelete)
    };

    const removeTag = (id, value) => {
        const novoArray = props.arrayItens.map((objeto) => {
            if (objeto.id === id) {
                const novoArrayTags = objeto.tags.filter(tag => tag !== value);

                return {
                    ...objeto,
                    tags: novoArrayTags,
                };
            }
            return objeto;
        });

        props.setItens(novoArray);
    };

    return (
        <StylePaper
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0,
                m: 0
            }}
            component="ul"
        >
            {props.tags ? (
                props.tags.map((data, index) => {
                    let icon;
                    const chip = chipData.find(chip => chip.label === data);
                    const chipStyle = chip ? chip.style : {};

                    return (
                        <ListItem key={index}>
                            <Chip
                                icon={icon}
                                label={props.showControl ? data : null}
                                onDelete={props.showControl ? data === 'React' ? undefined : handleDelete(data) : null}
                                sx={{
                                    fontFamily: 'Inter',
                                    fontSize: "10px",
                                    fontWeight: 500,
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    height: !props.showControl ? "5px" : "20px",
                                    minWidth: !props.showControl ? "30px" : null,
                                }}
                                style={chipStyle}
                                size="small"
                                {...props.clique ? { onClick: showControlTags } : null}
                            />
                        </ListItem>
                    );
                })
            ) : null}

        </StylePaper>
    );
}