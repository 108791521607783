import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CpfFormat from '../numberFormat/CpfFormat';


export default function SelectTextFieldsCPF(props) {
    const stylesTextField = {
        textFieldLabel: {
            fontWeight: '500', // define a espessura da fonte do label para negrito
            fontFamily: "Inter",
            fontSize: "14px",
            color: "#777",
            '&.Mui-focused': {
                color: '#777', // cor do label quando o textField estiver em foco
                fontWeight: "600",
                fontSize: "14px"
            },
        },
        textField: {
            '& .MuiOutlinedInput-root': {
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: 'Inter',
                color: '#312d45',
                '&:hover fieldset': {
                    borderColor: 'gray', // cor da borda ao passar o mouse sobre o textField
                },
                '&.Mui-focused fieldset': {
                    borderColor: "#dfe1e6",
                    color: "#777777" // cor da borda quando o textField estiver em foco
                },
            },
        },
    };

    const [cpfFormatado, setCpfFormatado] = React.useState("")

    const handleSetCpf = (value) => {
        setCpfFormatado(value.toString());
        props.setCpf(value.target.value)
    };


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 0 },
                width: "100%",
                marginTop: "15px"
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    label="CPF"
                    id={props.id}
                    value={cpfFormatado}
                    InputLabelProps={{
                        sx: stylesTextField.textFieldLabel, // aplica o estilo ao label do TextField
                    }}
                    sx={{
                        ...stylesTextField.textField,
                        width: "100%",
                        margin: "10px"
                    }}
                    InputProps={{
                        inputComponent: CpfFormat,
                        value: cpfFormatado, // Passa o valor atual do CNPJ para o componente CnpjFormat
                        onChange: handleSetCpf, // Atualiza o estado do CNPJ conforme o usuário digita
                    }}
                />
            </div>
        </Box >
    );
}