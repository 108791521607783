import { Box } from '@mui/system';
import * as React from 'react';
import "@lottiefiles/lottie-player";
import './Plan.css';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


function Sucess() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/dashboard');
    }


    const dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }


    useEffect(() => {
        //Google tag
        gtag('js', new Date());
        gtag('config', 'G-Y4H4L7L54D')
    }, []);


    return (
        <Box sx={{
            height: '100vh',
            padding: "40px 20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}>

            <lottie-player
                autoplay
                loop
                mode="normal"
                src="https://assets7.lottiefiles.com/packages/lf20_niyfyoqs.json"
                style={{ width: "160px", height: "160px" }}
            >
            </lottie-player>
            <div className='divSucessConfirmation'>
                <p>Parabéns! Seu pagamento foi concluído com sucesso. Nossos leads estão à sua espera!</p>
                <button onClick={handleClick}>Explorar leads</button>
            </div>

        </Box>
    );
}

export default Sucess;