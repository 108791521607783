import './emailMarketing.css'
import { Box, Button, Container, Paper, Typography } from "@mui/material"
import EmailMarketing from '../../../imagens/emailMarketing.svg'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import axios from 'axios'


const stylePaper = {
    color: "rgb(33, 43, 54)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    position: "relative",
    borderRadius: "16px",
    zIndex: 0,
    padding: "40px",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "gb(145 158 171 / 16%) 0px 24px 48px 0px",
    maxWidth: '380px'
}


export default function EmailMarketingtDescription({ user }) {

    const createLeads = async () => {
        const request = {
            iduser: user.iduser,
            email: user.email,
        };

        const url = `${process.env.REACT_APP_API_URL}/emailLeads`;

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            alert("Agradecemos o seu interesse, em breve entraremos em contato!")
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    const stylePaper = {
        width: "200px",
        height: "200px",
        boxShadow: "none",
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '15px',
        marginBottom: '15px',
        padding: "10px",
        boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
    }

    const styleH2 = {
        color: "#454F5B",
        fontWeight: 800,
        textAlign: "center",
    }

    const styleH3 = {
        fontSize: "14px",
        fontWeight: 400,
        color: "#637381",
        textAlign: "center",
        marginTop: "10px",
        lineHeight: "1.5",
    }


    return (
        <Container sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#f5f8fa",
            padding: "30px",
            borderRadius: "16px",
        }}>
            <Box sx={{
                gap: "24px",
                display: "grid",
                justifyContent: "center",
                alignContent: "center",
                '@media (min-width: 900px)': {
                    gridTemplateColumns: "repeat(2, 1fr)"
                }
            }}>
                <Box sx={{
                    marginLeft: "20px",
                    marginRight: "20px",

                    "@media (max-width: 600px)": {
                        width: "100%",
                    }
                }}
                >
                    <img src={EmailMarketing} alt='Email Marketing' className='imgChatBot' />
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: "20px",
                    marginRight: "20px",
                }}
                >
                    <Typography variant='h6'
                        sx={{
                            marginBottom: "10px",
                            maxWidth: "400px",
                            fontFamily: 'Inter',
                            margin: "0px 0px 20px",
                            fontWeight: "600",
                            color: "#212b36"
                        }}>Promova a sua marca e faça o seu negócio crescer</Typography>

                    <Typography variant='span'
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#637381",
                            maxWidth: "400px",
                            textAlign: "justify",
                            "@media (max-width: 900px)": {
                                maxWidth: "100%",
                            }
                        }}
                    >Email marketing é bom. Mas já imaginou gerar até 5x mais conversões e guiar seus Leads até a compra em menos tempo?
                    </Typography>

                    <Typography variant='span'
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#637381",
                            maxWidth: "400px",
                            marginTop: "20px",
                            textAlign: "justify",
                            "@media (max-width: 900px)": {
                                maxWidth: "100%",
                            }
                        }}
                    >Com Automação de Marketing, você envia a mensagem certa na hora certa e constrói jornadas personalizadas.
                        Você pode criar fluxos ou escolher um modelo para ajudar os Leads a darem o próximo passo.
                    </Typography>


                    <Button sx={{
                        maxWidth: "400px",
                        marginTop: "30px",
                        backgroundColor: "#00ab55",
                        color: "#fff",
                        fontFamily: 'Inter',
                        textTransform: "none",
                        fontWeight: "600",
                        "&:hover": {
                            backgroundColor: "#00ab55",
                        }
                    }}
                        onClick={createLeads}
                    >
                        Avise-me quando estiver disponível
                    </Button>
                </Box>

            </Box>

        </Container >
    )
}