import React, { useRef } from "react";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import styles from '../layout/desktop/funil/HeaderFunil.module.css'

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import axios from "axios";
import moment from 'moment'
import SnackbarAlert from '../layout/snackbar/Snackbar';

import * as XLSX from 'xlsx';
import './Import.css'
import upFile from '../imagens/upFile.svg'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: "16px", color: "#282539", fontFamily: 'Inter', minWidth: "500px" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ImportExcel(props) {

    const [data, setData] = React.useState([]);
    const [progress, setProgress] = React.useState(0);
    const [upVisible, setUpVisible] = React.useState("");
    const [progressVisible, setProgressVisible] = React.useState("none");

    function handleFile(e) {
        setUpVisible("none")
        setProgressVisible("")
        const file = e.target.files[0];
        const reader = new FileReader();

        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    clearInterval(timer);
                    return 100;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 2);

        reader.onload = function (e) {
            const workbook = XLSX.read(e.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                defval: null,
            });
            const headers = sheetData[0];
            const newData = sheetData.filter((row) => {
                // Remove as linhas totalmente vazias
                return row.some((cell) => cell !== null && cell !== "");
            }).slice(1).map((row) => {
                const newRow = {};
                headers.forEach((header, index) => {
                    newRow[header] = row[index];
                });
                return newRow;
            });
            setData(newData);
        };
        reader.readAsBinaryString(file);
    }



    const fileInputRef = useRef(null);

    function handleDivClick() {
        // Ao clicar na div, definimos o estilo da entrada de arquivo para "block"
        fileInputRef.current.click();
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setData([])
        setProgress(0)
        setUpVisible("")
        setProgressVisible("none")
    };


    //SnackAlert
    const [color, setColor] = React.useState("")
    const [state, setState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'right',
        text: "Ok"
    });

    const handleCloseAlert = () => {
        setState({ ...state, openAlert: false });
    };

    //fim SnackAlert

    const handleDownload = () => {
        window.open('https://docs.google.com/spreadsheets/d/19oRqtFhiiDpUdbHZmFuRuNrxs0NzkW7x/export?format=xlsx');
    };

    function sucessSaveFunil() {
        handleClose()
        setData([])
        setProgress(0)
        setUpVisible("")
        setProgressVisible("none")
        setColor('#239f4f')
        setState({
            openAlert: true, vertical: 'top',
            horizontal: 'right',
            text: "Leads adicionados ao Funil!"
        });
    }

    function errorSaveFunil() {
        handleClose()
        setColor('#ec3c62')
        setState({
            openAlert: true, vertical: 'top',
            horizontal: 'right',
            text: "Ocorreu um erro. Nem todos os leads foram importados. Contacte o suporte ao cliente!"
        });
    }

    
    const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");

    const saveFunil = async (cpfCnpj, nome, cidade, uf, telefone1, email, valor) => {
        const request = {
            cnpj: cpfCnpj ? cpfCnpj : "",
            razao_social: nome ? nome : "",
            nome_fantasia: "",
            cep: "",
            logradouro: "",
            bairro: "",
            cidade: cidade ? cidade : "",
            uf: uf ? uf : "",
            anotacoes: [], // adicionando como um array vazio
            tags: [], // adicionando como um array vazio
            telefone1: telefone1 ? telefone1 : "",
            telefone2: "",
            email: email ? email : "",
            status: "Prospecção",
            create_date: currentDate,
            iduser: props.id,
            valor: valor ? valor : "",
        }

        
        const url = `${process.env.REACT_APP_API_URL}/saveFunil`;

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            sucessSaveFunil()
            getFunil().then(function (res) {
                props.setItens(res.data)
                setOpen(false)
            })
            return response;
        } catch (error) {
            errorSaveFunil()
            console.log(error)
            return error;
        }
    }

    const getFunil = async () => {

        const url = `${process.env.REACT_APP_API_URL}/funil`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: props.id
                }

            });
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    const handleSaveFunil = () => {
        for (let i = 0; i < data.length; i++) {
            saveFunil(data[i].cpf_cnpj, data[i].nome, data[i].cidade, data[i].uf, data[i].telefone, data[i].email, data[i].valor_lead)
        }
    }

    return (
        <div>
            <SnackbarAlert handleClose={handleCloseAlert} open={state.openAlert} vertical={state.vertical} horizontal={state.horizontal} color={color} text={state.text} />
            <button className={styles.btnAddLeads} onClick={handleClickOpen}>Importar planilha <AddCircleOutlineRoundedIcon fontSize='small' /></button>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Importação de planilha (*.xlsx)
                </BootstrapDialogTitle>
                <Button sx={{
                    color: "#00AB55",
                    textTransform: "none",
                    fontFamily: "Inter",
                    '&:hover': {
                        backgroundColor: "rgb(200, 250, 205, 0.3)",
                    }
                }} onClick={handleDownload}>
                    Baixar modelo
                </Button>

                <DialogContent dividers>
                    <div className="divImport__upload" onClick={handleDivClick} style={{ display: upVisible }}>
                        <input
                            ref={fileInputRef}
                            type="file"
                            style={{ display: "none" }}
                            accept=".xlsx"
                            onChange={handleFile}
                        />
                        <label htmlFor="upload" className="divIconUpload">
                            <img src={upFile} alt="upload" />
                            <p>Upload planilha de leads</p>
                        </label>
                    </div>

                    <Box sx={{ width: '100%', marginBottom: "20px" }} style={{ display: progressVisible }}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                    <Box sx={{ width: '100%', marginBottom: "20px" }} style={{ display: progressVisible }}>
                        <p className="leadsP">{data.length} leads prontos para serem importados!</p>
                    </Box>
                </DialogContent>


                <DialogActions>
                    {data.length > 0 ? (
                        <Button autoFocus onClick={handleSaveFunil} sx={{
                            borderRadius: "3px",
                            border: "none",
                            padding: "6px 12px",
                            fontSize: "12px",
                            fontWeight: "500",
                            fontFamily: "Inter",
                            color: "white",
                            background: "#239f4f",
                            cursor: "pointer",
                            textTransform: "none",
                            '&:hover': {
                                background: "#239f4f"
                            }
                        }}>
                            Importar {data.length} leads
                        </Button>
                    ) : null}
                </DialogActions>

            </BootstrapDialog>
        </div >
    );
}