import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useGoogleLogin } from '@react-oauth/google';


export default function BasicButtonsGoogle(props) {
    //const [token, setToken] = useState("")

    const onSuccessLogin = (token) => {
        props.acaoLogin(props.valueLogin)
        props.acaoCad(!props.valueLogin)
        // Faz uma solicitação para a API do Google para obter informações do usuário
        fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`)
            .then((response) => response.json())
            .then((data) => {
                props.setUser(data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const loginGoogle = useGoogleLogin({
        onSuccess: tokenResponse => onSuccessLogin(tokenResponse.access_token),
    });

    return (
        <Stack
            spacing={2}
            direction="row"
            sx={{
                width: '100%',
                marginBottom: "15px"
            }}>
            <Button
                variant="outlined"
                size='large'
                startIcon={<img style={{ width: "20px", marginRight: "30px" }} src={props.logo} />}
                sx={{
                    width: '100%',
                    height: "52px",
                    borderColor: "#c2c8d0",
                    fontFamily: 'Inter',
                    color: "#637381",
                    fontSize: "14px",
                    justifyContent: "flex-start",
                    ":hover": {
                        color: "#03a678",
                        borderColor: "#03a678",
                        bgcolor: "white"
                    }
                }}
                onClick={() => loginGoogle()}

            >
                {props.text}
            </Button>
        </Stack>
    );
}