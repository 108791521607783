import styles from './ListLeads.module.css'

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';

import { BsFillCloudArrowDownFill } from "react-icons/bs";
import CircularProgress from '@mui/material/CircularProgress';

import axios from "axios";
import { useEffect, useState } from 'react'
import Papa from "papaparse"
import moment from 'moment'
import DadosLista from './dadosLeads';
import Spinner from '../../../spinner/Spinner';

import ClipLoader from "react-spinners/ClipLoader";

import { Backdrop } from '@mui/material';
import AvisoLimit from './avisoLimit';

function ListLeads({ user, setUser, id, leadsClick }) {

    //backdrop
    const [openBack, setOpenBack] = useState(false);
    const handleCloseBack = () => {
        setOpenBack(false);
    };

    const handleToggleBack = () => {
        setOpenBack(!openBack);
    };
    //fim backdrop


    const [lists, setLists] = useState([])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [download, setDownload] = useState("none")

    const handleChangePage = (event, newPage) => {
        setPage(newPage - 1);
    };

    const getList = async () => {
        handleToggleBack()
        const request = {
            id: id
        }

        const url = `${process.env.REACT_APP_API_URL}/listLeads`;

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
            setOpenBack(false)
            return error;
        }

    }

    const StyledTableCell = styled(TableCell)(({ theme, firstcell, lastcell }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F4F6F8',
            color: "#637381",
            fontFamily: 'Inter',
            paddingBottom: "8px",
            fontWeight: "600",
            paddingTop: "8px"
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            color: "#637381",
            fontFamily: 'Inter',
            width: lastcell === "true" || firstcell === "true" ? '150px' : 'auto',
            paddingBottom: "8px",
            paddingTop: "8px"
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "white",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
            color: "#637381",
        },
    }));

    const donwloadList = async (list, count) => {
        let page = 1;
        let array = []
        let query = list
        let limit = count

        const fetchData = async (page, limit) => {
            setDownload("")

            const request = {
                body: query,
                page: page,
                limit: limit,
                iduser: user.iduser,
                creditos: parseInt(credit_used) + parseInt(limit)
            }

            const url = `${process.env.REACT_APP_API_URL}/downloadList`;

            try {
                const response = await axios.post(url, request, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                getUser(user.iduser).then(response => {
                    setUser(response[0])
                })

                return response.data;
            } catch (error) {
                console.error(error);
                setDownload("none")
                return error;
            }

        };

        const loadData = async (page, limit) => {
            let result = await fetchData(page, limit);
            array.push(result)
            return result.length === 20000;

        };

        const fetchDataRecursive = async () => {
            let retorno = await loadData(page, limit);

            if (retorno) {
                page++;
                fetchDataRecursive()
            }

            if (!retorno) {
                let accumulatedQuery = [];

                for (let i = 0; i < array.length; i++) {
                    accumulatedQuery = [...accumulatedQuery, ...array[i]];
                }

                const newArray = accumulatedQuery.map(obj => {
                    const { qsa, cnaes_secundarios, qualificacao_do_responsavel, ...newObj } = obj;
                    return newObj;
                });

                const csv = Papa.unparse(newArray);

                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

                const url = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = 'leads.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setDownload("none")

            }
        };

        fetchDataRecursive()

    }

    const formatDate = (date) => {
        return moment(date).format('DD/MM/YYYY');
    }

    useEffect(() => {
        setCardListVisible("")
        setListaVisible("none")
        getList().then(function (response) {
            setOpenBack(false)
            setLists(response.data)
        })
        // eslint-disable-next-line
    }, []);

    const [listaVisible, setListaVisible] = useState("none")
    const [cardListVisible, setCardListVisible] = useState("")
    const [listaQuery, setListaQuery] = useState([])

    const handleListaVisible = (query, limit) => {
        setCardListVisible("none")
        setListaVisible("")

        if (listaQuery === "") {
            setListaQuery([query, limit]);
        }
    };

    const [spinnerVisible, setSpinnerVisible] = useState("none")

    const getPlans = async () => {

        const url = `${process.env.REACT_APP_API_URL}/plans`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: user.plan_id
                }

            });
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    const getUser = async (id) => {

        const url = `${process.env.REACT_APP_API_URL}/getUserID`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id
                }

            });

            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }


    const [limit_exportation, setLimit_exportation] = useState(0)
    const [credit_used, setCredit_used] = useState(0)
    const [exportantionSaldo, setExportantionSaldo] = useState(0)


    useEffect(() => {
        getPlans().then(function (response) {
            setLimit_exportation(parseInt(response[0].limit_exportation))
            setCredit_used(parseInt(user.credit_used))
            setExportantionSaldo(parseInt(response[0].limit_exportation) - parseInt(user.credit_used))
        })

        // eslint-disable-next-line
    }, [user])

    const [aviso, setAviso] = useState(false)

    const checkLimit = (list, count) => {

        if (exportantionSaldo === 0 || exportantionSaldo < count) {
            setAviso(true)
        } else {
            donwloadList(list, count)
        }
    }


    return (
        <div className={styles.cardList}>
            <AvisoLimit aviso={aviso} setAviso={setAviso} />
            <Spinner display={spinnerVisible} label={"Aguarde um momento, estamos atualizando seus leads"} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBack}
                onClick={handleCloseBack}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={styles.cardList} style={{ display: cardListVisible }}>
                <h3>Meus leads</h3>
                <h6>Selecione uma lista salva para acessar seus leads</h6>
                <div className={styles.line}></div>

                <div className={styles.avisoDownload} style={{ display: download }}>
                    <p>Gerando arquivo csv</p>
                    <ClipLoader color="#042940" size={20} />
                </div>

                <div style={{ marginBottom: "15px" }}>
                    <span className={styles.Expcs1wsvdx1}>Exportações restante:</span>
                    <span className={styles.cs1wsvdx1}>{exportantionSaldo}</span>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Criada em</StyledTableCell>
                                <StyledTableCell>Nome da lista</StyledTableCell>
                                <StyledTableCell>Quantidade</StyledTableCell>
                                <StyledTableCell>Ações</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lists.sort((a, b) => new Date(b.create_date) - new Date(a.create_date)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <StyledTableRow key={row.id_list}>
                                    <StyledTableCell align="left" firstcell="true">{formatDate(row.create_date)}</StyledTableCell>
                                    <StyledTableCell align="left">
                                        <p style={{ cursor: "pointer", color: "#637381", fontFamily: "Inter" }} onClick={() => handleListaVisible(row.query, row.count)}>{row.name_list}</p>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div className={styles.tag}>
                                            {row.count} leads
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" lastcell="true">
                                        <div className={styles.actions}>
                                            <button className={styles.btnActions} onClick={() => handleListaVisible(row.query, row.count)}>Abrir</button>
                                            <BsFillCloudArrowDownFill className={styles.listIcons} onClick={() => checkLimit(row.query, row.count)} />
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Pagination
                        style={{ paddingBottom: "8px", paddingTop: "8px" }}
                        count={Math.ceil(lists.length / rowsPerPage)}
                        page={page + 1}
                        onChange={handleChangePage}
                        color="primary"
                    />
                </TableContainer>
            </div>

            <div className={styles.cardList} style={{ display: listaVisible }}>
                <DadosLista id={id} setSpinnerVisible={setSpinnerVisible} listaQuery={listaQuery} setListaQuery={setListaQuery} setCardListVisible={setCardListVisible} setListaVisible={setListaVisible} leadsClick={leadsClick} />
            </div>

        </div>
    )
}

export default ListLeads