import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';


export default function SelectTextFields(props) {
    const stylesTextField = {
        textFieldLabel: {
            fontWeight: '500', // define a espessura da fonte do label para negrito
            fontFamily: "Inter",
            fontSize: "14px",
            color: "#777",
            '&.Mui-focused': {
                color: '#777', // cor do label quando o textField estiver em foco
                fontWeight: "600",
                fontSize: "14px"
            },
        },
        textField: {
            '& .MuiOutlinedInput-root': {
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: 'Inter',
                color: '#312d45',
                '&:hover fieldset': {
                    borderColor: 'gray', // cor da borda ao passar o mouse sobre o textField
                },
                '&.Mui-focused fieldset': {
                    borderColor: "#dfe1e6",
                    color: "#777777" // cor da borda quando o textField estiver em foco
                },
            },
        },
    };

    const [error, setError] = React.useState(false);

    const handleValueChange = (event) => {
        const inputValue = event.target.value;
        props.setValue(inputValue);
        setError(inputValue.trim() === '');
    };

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 0 },
                width: "100%",
                marginTop: "15px"
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    id={props.id}
                    select
                    label={props.label}
                    value={props.value}
                    helperText={error ? "Campo obrigatório." : null}
                    InputLabelProps={{
                        sx: stylesTextField.textFieldLabel, // aplica o estilo ao label do TextField
                    }}
                    sx={{
                        ...stylesTextField.textField,
                        width: "100%",
                        margin: "10px"
                    }}
                    onChange={handleValueChange}
                >
                    {props.currencies.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.value}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        </Box >
    );
}