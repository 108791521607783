import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';



export default function BasicButtons(props) {

    return (
        <Stack
            spacing={2}
            direction="row"
            sx={{
                width: '100%',
                marginBottom: "15px"
            }}>
            <Button
                variant="outlined"
                size='large'
                sx={{
                    width: '100%',
                    borderColor: "#c2c8d0",
                    height: "52px",
                    fontFamily: 'Inter',
                    color: "#637381",
                    fontSize: "14px",
                    ":hover": {
                        color: "#03a678",
                        borderColor: "#03a678",
                        bgcolor: "white"
                    }
                }}
                onClick={props.onclick}
            >
                {props.text}
            </Button>
        </Stack>
    );
}