import './VoipList.css'
import { Box, Button, Container, Paper, Typography } from "@mui/material"
import CallCenter from '../../../imagens/call-center.svg'
import axios from 'axios'


const stylePaper = {
    color: "rgb(33, 43, 54)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    position: "relative",
    borderRadius: "16px",
    zIndex: 0,
    padding: "40px",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "gb(145 158 171 / 16%) 0px 24px 48px 0px",
    maxWidth: '380px'
}


export default function VoipDescription({ user }) {

    const createLeads = async () => {
        const request = {
            iduser: user.iduser,
            email: user.email,
        };

        const url = `${process.env.REACT_APP_API_URL}/voipLeads`;

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            alert("Agradecemos o seu interesse, em breve entraremos em contato!")
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    return (
        <Container sx={{
            minHeight: "calc(100vh - 64px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#f5f8fa",
            padding: "30px",
            borderRadius: "16px",
        }}>
            <Box sx={{
                gap: "24px",
                display: "grid",
                justifyContent: "center",
                alignContent: "center",
                '@media (min-width: 900px)': {
                    gridTemplateColumns: "repeat(2, 1fr)"
                }
            }}>
                <Box sx={{
                    marginLeft: "20px",
                    marginRight: "20px",

                    "@media (max-width: 600px)": {
                        width: "100%",
                    }
                }}
                >
                    <img src={CallCenter} alt='undraw calling' className='imgCalling' />
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: "20px",
                    marginRight: "20px",
                }}
                >
                    <Typography variant='h6'
                        sx={{
                            marginBottom: "10px",
                            fontFamily: 'Inter',
                            margin: "0px 0px 8px",
                            fontWeight: "600",
                            color: "#212b36"
                        }}>Ligações VoIp com gravação</Typography>

                    <Typography variant='span'
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#637381",
                            maxWidth: "400px",
                            textAlign: "justify",
                            "@media (max-width: 900px)": {
                                maxWidth: "100%",
                            }
                        }}
                    >Tenha um sistema completo de comunicação por áudio integrado a LeadsMapping.
                        Faça e grave ligações direto na plataforma para manter histórico e ter maior controle de suas negociações.
                    </Typography>

                    <Typography variant='h6' className='ligacoesVoip' sx={{
                        marginBottom: "10px",
                        fontFamily: 'Inter',
                        marginTop: "30px",
                        fontWeight: "600",
                        color: "#212b36"
                    }}>
                        Números dinâmicos
                    </Typography>

                    <Typography variant='span'
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#637381",
                            maxWidth: "400px",
                            textAlign: "justify",

                            "@media (max-width: 900px)": {
                                maxWidth: "100%",
                            }
                        }}
                    >
                        Com os números dinâmicos, cada chamada pode ser originada com um número de bina diferente, adaptado ao DDD do cliente.
                        Essa tecnologia permite que sua empresa esteja presente em várias regiões do país, sem precisar de várias linhas telefônicas.
                    </Typography>

                    <Button sx={{
                        maxWidth: "400px",
                        marginTop: "30px",
                        backgroundColor: "#00ab55",
                        color: "#fff",
                        fontFamily: 'Inter',
                        textTransform: "none",
                        fontWeight: "600",
                        "&:hover": {
                            backgroundColor: "#00ab55",
                        }
                    }}
                        onClick={createLeads}
                    >
                        Avise-me quando estiver disponível
                    </Button>
                </Box>

            </Box>

            <div className='divTitleServices'>
                <p>Vantagens e Benefícios</p>
            </div>

            <Box sx={{
                gap: "24px",
                display: "grid",
                justifyContent: "center",
                alignContent: "center",
                '@media (min-width: 900px)': {
                    gridTemplateColumns: "repeat(2, 1fr)"
                }
            }}>
                <Paper sx={stylePaper}>
                    <div className='voipDescription'>
                        <div className='titleDescription'>
                            <h2>Celular</h2>
                        </div>
                    </div>

                    <div className='voipDescription'>
                        <ul className='css-14i14n2 '>
                            <li className=' css-1yo0sni'>
                                <p className='css-1o38g2n'>Chamadas para qualquer número celular</p>
                            </li>
                            <li className=' css-1yo0sni'>
                                <p className='css-1o38g2n'>Bina dinâmica</p>
                            </li>
                            <li className=' css-1yo0sni'>
                                <p className='css-1o38g2n'>Classificação de chamadas</p>
                            </li>
                            <li className=' css-1yo0sni'>
                                <p className='css-1o38g2n'>Gravação de chamadas</p>
                            </li>
                        </ul>
                    </div>
                </Paper>

                <Paper sx={stylePaper}>
                    <div className='voipDescription'>
                        <div className='titleDescription'>
                            <h2>Fixo</h2>
                        </div>
                    </div>

                    <div className='voipDescription'>
                        <ul className='css-14i14n2 '>
                            <li className=' css-1yo0sni'>
                                <p className='css-1o38g2n'>Chamadas para qualquer número fixo</p>
                            </li>
                            <li className=' css-1yo0sni'>
                                <p className='css-1o38g2n'>Bina dinâmica</p>
                            </li>
                            <li className=' css-1yo0sni'>
                                <p className='css-1o38g2n'>Classificação de chamadas</p>
                            </li>
                            <li className=' css-1yo0sni'>
                                <p className='css-1o38g2n'>Gravação de chamadas</p>
                            </li>
                        </ul>
                    </div>
                </Paper>
            </Box>
        </Container>
    )
}