import * as React from 'react';
import styles from './DialogFunil.module.css'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { AiFillEdit } from "react-icons/ai";
import { IoBusiness } from "react-icons/io5";
import { HiMenuAlt2 } from "react-icons/hi";
import { IoIosClose } from "react-icons/io";
import { TfiMenuAlt } from "react-icons/tfi";

import moment from 'moment'

import Tags from '../etiquetas/Tag';
import CurrencyFormat from '../numberFormat/NumberFormat';
import SnackbarAlert from '../layout/snackbar/Snackbar';
import MenuEtiquetas from '../etiquetas/MenuEtiquetas';
import MaterialUIPickers from '../dateTime Picker/DateTime';


export default function DialogFunil(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const stylesTextField = {
        textFieldLabel: {
            fontWeight: '500', // define a espessura da fonte do label para negrito
            fontFamily: "Inter",
            fontSize: "14px",
            color: "#777",
            '&.Mui-focused': {
                color: '#777', // cor do label quando o textField estiver em foco
                fontWeight: "600",
                fontSize: "14px"
            },
        },
        textField: {
            '& .MuiOutlinedInput-root': {
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: 'Inter',
                color: '#282539',
                '&:hover fieldset': {
                    borderColor: 'gray', // cor da borda ao passar o mouse sobre o textField
                },
                '&.Mui-focused fieldset': {
                    borderColor: "#dfe1e6",
                    color: "#777777" // cor da borda quando o textField estiver em foco
                },
            },
        },
    };

    const currencies = [
        {
            value: 'Prospecção',
            label: 'Prospecção',
        },
        {
            value: 'Contato',
            label: 'Contato',
        },
        {
            value: 'Negociação',
            label: 'Negociação',
        },
        {
            value: 'Fechamento',
            label: 'Fechamento',
        },
    ];

    const atualizarValor = (id, novoValor) => {
        const novoArray = props.arrayItens.map((objeto) => {
            if (objeto.id === id) {
                return {
                    ...objeto,
                    valor: novoValor,
                };
            } else {
                return objeto;
            }
        });

        //atualiza o estado com o novo array
        props.setItens(novoArray)

    };

    function handleInputChange(id, value) {
        atualizarValor(id, parseFloat(value))
    }

    const saveAnotacao = (id, novoObjeto) => {
        const data = new Date().toISOString()
        const novoArray = props.arrayItens.map((objeto) => {
            if (objeto.id === id) {
                let novoArrayAnotacoes = [];
                if (Array.isArray(objeto.anotacoes)) {
                    novoArrayAnotacoes = [...objeto.anotacoes];
                }
                novoArrayAnotacoes.push({ data, anotacao: novoObjeto });
                return {
                    ...objeto,
                    anotacoes: novoArrayAnotacoes,
                };
            } else {
                return objeto;
            }
        });

        // atualiza o estado com o novo array
        props.setItens(novoArray);
    };


    const [novoObjeto, setNovoObjeto] = React.useState("");
    const [visible, setVisible] = React.useState("none")

    const handleChangeObjeto = (event) => {
        setNovoObjeto(event.target.value);
    };

    React.useEffect(() => {
        if (novoObjeto.length > 0) {
            setVisible("")
        } else {
            setVisible("none")
        }
    }, [novoObjeto])

    const handleClickSalvar = () => {
        saveAnotacao(props.id, novoObjeto);
        setState({
            openAlert: true, vertical: 'top',
            horizontal: 'right',
            text: "Anotação registrada!"
        });
        setNovoObjeto("");
        setVisible("none")
    };

    const handleCancelArea = () => {
        setNovoObjeto("");
        setVisible("none")
    }

    //SnackAlert
    const [state, setState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'right',
        text: "Ok"
    });


    const handleCloseAlert = () => {
        setState({ ...state, openAlert: false });
    };
    //fim SnackAlert


    const updateStatus = (id, status) => {
        setState({
            ...state,
            openAlert: true
        });

        const novoArray = props.arrayItens.map((objeto) => {
            if (objeto.id === id) {
                return {
                    ...objeto,
                    status: status,
                    fechamento_date: status === 'Fechamento' ? new Date().toISOString() : objeto.fechamento // adiciona a data de fechamento se o status for Fechamento
                };
            } else {
                return objeto;
            }
        });

        //atualiza o estado com o novo array
        props.setItens(novoArray);
    };

    return (
        <div>
            <AiFillEdit className='icons' variant="outlined" onClick={handleClickOpen} />
            <SnackbarAlert handleClose={handleCloseAlert} open={state.openAlert} vertical={state.vertical} horizontal={state.horizontal} color='#239F4F' text={state.text} />
            <Dialog open={open} onClose={handleClose}>
                <div className={styles.dialogClose}>
                    <IoIosClose className={styles.icon} onClick={handleClose} />
                </div>
                <div className={styles.dialogHeader}>
                    <IoBusiness className={styles.dialogIcon} />
                    <h3>{props.razao_social}</h3>
                </div>
                <div className={styles.etiquetas}>
                    <div className={styles.divEtiqueta}>
                        <label>Etiquetas</label>
                        <MenuEtiquetas setItens={props.setItens} id={props.id} arrayItens={props.arrayItens} />
                    </div>
                    <Tags arrayItens={props.arrayItens} id={props.id} showControl={true} setItens={props.setItens} tags={props.tags} />
                </div>

                <div className={styles.divBox}>

                    <TextField
                        label="Atribuir valor"
                        id="outlined-size-small"
                        value={props.valor}
                        size="small"
                        InputLabelProps={{
                            sx: stylesTextField.textFieldLabel, // aplica o estilo ao label do TextField
                        }}
                        sx={{
                            ...stylesTextField.textField,
                            width: '200px', // defina a largura desejada aqui
                        }}
                        InputProps={{
                            inputComponent: CurrencyFormat,
                        }}
                        onChange={(event) => handleInputChange(props.id, event.target.value)}
                    />

                    <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        label="Estágio do Funil"
                        value={props.status}
                        onChange={(event) => updateStatus(props.id, event.target.value)}
                        InputLabelProps={{
                            sx: stylesTextField.textFieldLabel, // aplica o estilo ao label do TextField
                        }}
                        sx={{
                            ...stylesTextField.textField,
                            width: '200px', // defina a largura desejada aqui
                            marginLeft: "10px"
                        }}
                    >
                        {currencies.map((option) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    fontFamily: 'Inter',
                                    color: '#282539'
                                }}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <MaterialUIPickers arrayItens={props.arrayItens} setItens={props.setItens} id={props.id} status={props.status} dataFechamento={props.dataFechamento} />

                </div>

                <DialogContent>
                    <div className={styles.dialogDescricao}>
                        <HiMenuAlt2 className={styles.dialogIcon} />
                        <h3>Anotações</h3>
                    </div>
                    <div>
                        <textarea className={styles.textArea} id="message" name="message" rows="4" cols="40" value={novoObjeto} onChange={handleChangeObjeto}></textarea>
                        <div className={styles.btnTextArea}>
                            <button className={styles.btnSalvar} style={{ display: visible }} onClick={handleClickSalvar}>Salvar</button>
                            <button className={styles.btnCancelar} style={{ display: visible }} onClick={handleCancelArea}>Cancelar</button>
                        </div>
                    </div>
                    <div className={styles.historico}>
                        <div className={styles.historicoHeader}>
                            <TfiMenuAlt className={styles.dialogIcon} />
                            <h3>Histórico</h3>
                        </div>

                        {props.anotacao ? (
                            props.anotacao
                                .sort((a, b) => new Date(b.data) - new Date(a.data))
                                .map((item, index) => (
                                    <div key={index} className={styles.p}>
                                        <p>{item.anotacao}</p>
                                        <label>Em {moment(item.data).format('DD/MM/YYYY')}</label>
                                    </div>
                                ))
                        ) : null}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}