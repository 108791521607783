import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


import { IoIosAddCircle } from "react-icons/io";

export default function MenuEtiquetas(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, value) => {
        setAnchorEl(null);

        if (value !== "backdropClick") {
            updateTag(props.id, value); 
        }
    };

    const estilo = { fontSize: "12px", fontFamily: "Inter", color: "#282539" }

    const updateTag = (id, tag) => {
        const novoArray = props.arrayItens.map((objeto) => {
            if (objeto.id === id) {
                // Verifica se o array de tags está vazio
                if (objeto.tags?.length === 0) {
                    // Adiciona a nova tag ao array de tags
                    return {
                        ...objeto,
                        tags: [tag],
                    };
                }
                // Verifica se a tag já existe no array de tags
                else if (!objeto.tags.includes(tag)) {
                    // Adiciona a nova tag ao array de tags
                    return {
                        ...objeto,
                        tags: [...objeto.tags, tag],
                    };
                } else {
                    // Retorna o objeto original sem nenhuma modificação
                    return objeto;
                }
            }
            return objeto;
        });

        //atualiza o estado com o novo array
        props.setItens(novoArray);
    };




    return (
        <div>
            <IoIosAddCircle onClick={handleClick} style={{ cursor: "pointer", fontSize: "16px", marginLeft: "10px", display: "flex", flexDirection: "row", alignItems: "center", color: "#042940" }} />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem sx={estilo} onClick={(event) => handleClose(event, 'Não interessado')}>Não interessado</MenuItem>
                <MenuItem sx={estilo} onClick={(event) => handleClose(event, 'Reunião agendada')}>Reunião agendada</MenuItem>
                <MenuItem sx={estilo} onClick={(event) => handleClose(event, 'Proposta enviada')}>Proposta enviada</MenuItem>
                <MenuItem sx={estilo} onClick={(event) => handleClose(event, 'Contrato enviado')}>Contrato enviado</MenuItem>
            </Menu>
        </div>
    );
}