import React from 'react';
import { PatternFormat } from 'react-number-format';

const CnpjFormat = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props;

    return (
        <PatternFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="##.###.###/####-##" // Define a máscara de formatação
            mask="_" 
            placeholder="__.___.___/____-__" 
            
        />
    );
});

export default CnpjFormat;
