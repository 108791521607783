import styles from './Privacy.module.css'
import Box from '@mui/material/Box';
import logoBlack from '../../components/imagens/logoblack.svg'
import { useEffect } from 'react';

export function PrivacyPolicy() {

    useEffect(() => {
        function scrollToTop() {
            window.scrollTo(0, 0);
        }

        scrollToTop();
    }, []);

    return (
        <div className={styles.privacyContainer}>
            <div className={styles.headerLanding}>
                <img className={styles.logoBlack} src={logoBlack} alt="Leads logomarca" />
            </div>
            <Box sx={{ padding: "2rem" }}>
                <div className={styles.titulo}>
                    <h1>Política de Privacidade</h1>
                    <h5>Atualizada em: 13/03/2023</h5>
                </div>

                <div className={styles.paragrafos}>
                    <p>
                        JW SMART TECH TECNOLOGIA LDTA ME, sociedade empresária inscrita no CNPJ sob o nº 27.496.069/0001-04, com sede na Av. Ipiranga, 1504, Loja 2, Bairro Porto, na cidade de Cuiabá-MT,
                        CEP.: 78.025-350, ora denominada como LEADSMAPPING e responsável pela plataforma do site/app leadsmapping.com.br (“Plataforma”), estabelece a presente Política de Privacidade
                        (“Política”) a fim de esclarecer e informar aos clientes e profissionais (“Usuários”), de forma simples, transparente e objetiva, quais dados e informações serão obtidos e como
                        serão utilizados.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        O presente documento se aplica a todas as operações que envolvam dados pessoais tratados pela LEADSMAPPING, bem como cobre as atividades de coleta de dados online e offline e por
                        meio de vários canais, incluindo site, aplicativo, mídia social, atendimento ao cliente, dentre outros.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        A Política tem como escopo explicar e enumerar quais dados serão coletados, as razões e com quem será compartilhado. Caberá aos Usuários cumprirem as regras descritas no presente
                        documento. O compartilhamento dos dados ocorre em razão da natureza da atividade da LEADSMAPPING, não existindo qualquer outra razão que justifique o compartilhamento.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Incluem-se, também, os dados coletados no sistema de pagamento online utilizado pela LEADSMAPPING, quando o Usuário adquire um dos nossos planos ou paga pelos serviços feitos pelo
                        profissional.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Desse modo, pede-se aos Usuários que leiam atentamente as cláusulas abaixo antes de efetivar a contratação de serviços e assinatura dos planos.
                    </p>
                </div>
                <div className={styles.titulo}>
                    <h3>1. Definições</h3>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Caso algum termo deste documento não possuir definição, ele poderá ser encontrado no Termo de Adesão e Condições de Uso. A seguir, as definições para melhor
                        compreensão da presente Política de Privacidade:
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        - Usuários: clientes e/ou profissionais, todas as pessoas físicas que utilizarão ou visitarão o site/app da LEADSMAPPING, maiores de 18 (dezoito) anos e totalmente
                        capazes de praticar os atos da vida civil, bem como pessoas jurídicas.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        - Dados Pessoais: significa quaisquer informações fornecidas e/ou coletadas pela LEADSMAPPING, por qualquer meio, ainda que públicos, que: (i) identifiquem, ou que, quando
                        usadas em combinação com outras informações tratadas, reconheçam um indivíduo, em razão da pessoalidade; ou (ii) por meio das quais a identificação ou informações de contato de uma pessoa física possam ser derivadas.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Os dados pessoais podem estar em qualquer mídia ou formato, inclusive registros eletrônicos ou computadorizados, bem como em arquivos baseados em papel. Não são considerados dados
                        pessoais os dados de pessoa jurídica, nos termos da Lei nº 13.709/2018.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        - Titular: é a pessoa natural às quais se referem os dados pessoais, objeto de tratamento.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        - Tratamento: são as operações realizadas com os dados pessoais, se referindo a coleta, produção, recepção, utilização, acesso, reprodução, transmissão, processamento, arquivamento, armazenamento,
                        eliminação, modificação, transferência ou extração.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        - Controlador de dados: é a empresa que controla os dados do Usuário do site/app, no caso a LEADSMAPPING, sendo ela a responsável pelo tratamento das informações declaradas pelos
                        Usuários a partir desta Política, com abrangência somente em território nacional, ainda que a empresa realize o tratamento de dados das pessoas estrangeiras.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        - Finalidade: o objetivo, o propósito que a LEADSMAPPING deseja alcançar a partir de cada ato de tratamento das informações pessoais.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        - Necessidade: justificativa pela qual é estritamente necessário coletar dados pessoais para atingir a finalidade, evitando-se a coleta excessiva.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        - Consentimento: autorização expressa e inequívoca dada pelo Usuário titular do dado pessoal para que a LEADSMAPPING trate seus dados pessoais para uma finalidade previamente
                        descrita, na qual a base legal necessária para o ato demande a autorização expressa do titular.
                    </p>
                </div>
                <div className={styles.titulo}>
                    <h3>2. DADOS COLETADOS </h3>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        A LEADSMAPPING coleta e utiliza alguns dados pessoais dos seus Usuários, de forma a viabilizar a prestação de serviços e aprimorar a experiência de uso.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Assim, busca-se coletar apenas as informações essenciais para fornecer os serviços esperados, de modo a cumprir a finalidade pela qual foram coletadas. A LEADSMAPPING requisita e
                        coleta informações pessoais sobre os Usuários quando eles utilizam o site/app pela primeira vez. Esse ato é essencial para que a Plataforma consiga realizar as primeiras etapas do
                        objetivo do Usuário e para o cumprimento das obrigações legais.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        2.1. Informações para verificação de identidade: Para ajudar a criar e manter um ambiente confiável, a LEADSMAPPING poderá coletar informações para verificação de identidade, documentos
                        pessoais como RG, ou CNH, ou também por outras informações de autenticação. Desde já, a LEADSMAPPING expressa que poderá coletar os seguintes dados para o cadastro dos Usuários:
                        apelido ou pseudônimo, nome, identificação, informações de contato, como número de telefone, endereço, e-mail etc., dados dos meios de pagamento, informações do dispositivo através
                        do qual acessa o endereço IP, lista de contatos, links entre contas e Usuários, redes sociais, informações sobre localização e certas informações sobre a atividade, as preferências
                        dos Usuários e visitantes no site dos nossos serviços e direitos de propriedade intelectual.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        2.2. Informações de pagamento: para operações que envolvam pagamento de valores, será exigido do Usuário o fornecimento de determinadas informações financeiras, como cartão de
                        crédito, para facilitar o processamento dos pagamentos, além do CPF e endereço do pagador.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Ao utilizar os serviços de pagamento, o Usuário fornecerá informações pessoais para a operação do pagamento, que serão controladas e tratadas pelo controlador de dados, pessoa
                        jurídica contratada pela LEADSMAPPING para este fim.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Ressalta-se que a LEADSMAPPING só será responsável pelo tratamento de dados no sistema de recebimento de pagamentos quando o Usuário insere seus dados na página de pagamento. O
                        processamento dos dados após a inserção será feito pela pessoa jurídica contratada pela LEADSMAPPING para este fim.
                    </p>
                </div>
                <div className={styles.titulo}>
                    <h3>3. FORMAS DE COLETA DOS DADOS</h3>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        A LEADSMAPPING requisita e coleta informações pessoais sobre os Usuários quando eles utilizam o site/app pela primeira vez. Esse ato é essencial para que a plataforma consiga
                        realizar as primeiras etapas do objetivo do Usuário, seja a locação ou disponibilização de espaços, e para o cumprimento das obrigações legais.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        A LEADSMAPPING coleta apenas os dados essenciais para o funcionamento do serviço ofertado, de modo que, todo e qualquer dado extra, incluindo os dados sensíveis de acordo com a Lei
                        Geral de Proteção de Dados, são ofertados de modo facultativo pelo Usuário. Recomenda-se que sejam inseridos no site/app da LEADSMAPPING somente os dados essenciais para a utilização
                        dos serviços.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Nota-se que em todos os formulários de cadastro haverá um check-box obrigatório para o aceite destes termos da Política. Em caso de recusa de qualquer item do presente documento, o
                        Usuário ficará impedido de concluir o cadastro e, consequentemente, de utilizar a plataforma. Porém o simples início ou continuidade do uso dos serviços fornecidos também configura
                        aceite a esta política de privacidade.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Fica expresso que o site/app da LEADSMAPPING não é adequado para menores de 18 anos, nem se destina a coletar informações pessoais diretamente de pessoas menores de 18 anos. Se a LEADSMAPPING
                        suspeitar que as informações pessoais da criança foram tratadas e fornecidas inapropriadamente, elas serão levadas ao conhecimento das autoridades policiais ou judiciais competentes.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Assim, a partir dos seguintes atos a LEADSMAPPING realizará a coleta dos dados pessoais dos Usuários: preenchimento de cadastros, solicitações de consentimento, instalação de cookies,
                        cobrança dos valores concernentes aos planos e contratação de serviços, dentre outros.
                    </p>
                </div>
                <div className={styles.titulo}>
                    <h3>4. INFORMAÇÕES PARA O USO DOS SERVIÇOS DE PAGAMENTO</h3>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Para a cobrança dos valores atinentes à prestação dos serviços, a LEADSMAPPING conta com a empresa GALAX PAY PAGAMENTOS ELETRÔNICOS LTDA., sociedade empresária inscrita no CNPJ sob o nº
                        30.765.018/0001-45 responsável pelo processamento e administração dos pagamentos. Para tanto, a referida coletará dados do Usuário, tais como nome completo, RG, CPF, endereço, dados do cartão e outros dados que forem pertinentes.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Nota-se que a coleta destes dados é feita com o único propósito de pagar pelo plano e/ou serviço contratado. Além disso, será papel do Usuário conhecer a política de privacidade utilizada pela GALAX PAY. Caso o Usuário não concorde com o
                        fornecimento das informações, ele ficará impedido de utilizar os serviços da LEADSMAPPING.
                    </p>
                </div>
                <div className={styles.titulo}>
                    <h3>5. MODO DE UTILIZAÇÃO DAS INFORMAÇÕES COLETADAS</h3>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        A LEADSMAPPING coleta e utiliza as informações para os seguintes fins:
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <ul>
                        <li data-letter="a">Cumprir com a legislação</li>
                        <li data-letter="b">Permitir o acesso ao site/app LEADSMAPPING</li>
                        <li data-letter="c">Atender os requerimentos das autoridades legais</li>
                        <li data-letter="d">Enviar mensagens, seja através de e-mail, seja através do aplicativo de mensagens WhatsApp, a respeito de atualizações, alertas de segurança, notificações da conta, anúncios, questionamentos</li>
                        <li data-letter="e">Realizar verificações em bancos de dados e outras fontes de informação</li>
                        <li data-letter="f">Manter a segurança do site/app</li>
                        <li data-letter="g">Para o exercício regular de direitos em processo judicial, administrativo ou arbitral</li>
                        <li data-letter="h">Personalizar e melhorar as atividades de publicidade e marketing da LEADSMAPPING</li>
                    </ul>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Já o controlador dos dados de pagamento coleta os dados para processar e realizar o pagamento do Usuário à LEADSMAPPING; para o cumprimento das leis vigentes e, quando necessário,
                        para cumprir o contrato e melhorar seus serviços.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        5.1 Transferência de dados internacionais: Algumas das pessoas jurídicas com quem se compartilha seus dados podem ser localizados ou possuir instalações localizadas em países estrangeiros.
                        Nessas condições, de toda forma, seus dados pessoais estarão sujeitos à Lei Geral de Proteção de Dados e às demais leis pertinentes. Nesse sentido, a LEADSMAPPING se compromete a sempre
                        adotar eficientes padrões de segurança cibernética e de proteção de dados, nos melhores esforços de garantir e cumprir as exigências legislativas. Ao concordar com essa Política de Privacidade,
                        o Usuário concorda com esse compartilhamento, que se dará conforme as finalidades descritas no presente instrumento.
                    </p>
                </div>
                <div className={styles.titulo}>
                    <h3>6. POR QUANTO TEMPO OS DADOS SERÃO ARMAZENADOS</h3>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        A LEADSMAPPING possui uma política de retenção de dados pessoais alinhada com a Lei Geral de Proteção de Dados.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Os dados pessoais são armazenados somente pelo tempo que forem necessários para cumprir com as finalidades para as quais foram coletados, salvo se houver qualquer outra razão para sua
                        manutenção como, por exemplo, o cumprimento de quaisquer obrigações legais, regulatórias, contratuais, entre outras permitidas sob a lei. A LEADSMAPPING realiza uma análise técnica para
                        determinar o período de retenção adequado para cada tipo de dado pessoal coletado, considerando a sua natureza, necessidade de coleta e finalidade para a qual ele será tratado, bem como
                        eventuais necessidades de retenção para o cumprimento de obrigações ou o resguardo de direitos.
                    </p>
                </div>
                <div className={styles.titulo}>
                    <h3>7. COMPARTILHAMENTO E DIVULGAÇÃO</h3>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        As informações pessoais do Usuário compartilhadas com a LEADSMAPPING em suas redes sociais podem ser compartilhadas com os detentores dos site/apps, não existindo qualquer tipo de
                        controle ou supervisão pela LEADSMAPPING. Portanto, qualquer dúvida sobre como o provedor de serviços da plataforma de mídia social lida com suas informações pessoais, deve-se entrar
                        em contato diretamente com o gestor da rede social ou site/app vinculado.
                    </p>
                </div>
                <div className={styles.titulo}>
                    <h3>8. DIREITOS DOS USUÁRIOS </h3>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        A LEADSMAPPING assegura a seus Usuários seus direitos de titular previstos no art. 18 da Lei Geral de Proteção de Dados.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Dessa forma, os Usuários podem de maneira gratuita e a qualquer tempo:
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        8.1. Realizar a confirmação de acesso: O Usuário pode solicitar à LEADSMAPPING a confirmação sobre a existência de tratamento dos seus dados pessoais para que, em caso positivo, possa acessá-los,
                        inclusive por meio de solicitação de cópias dos registros.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        8.2. Correção: O Usuário pode solicitar a correção dos seus dados pessoais caso eles estejam incompletos, inexatos ou desatualizados.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        8.3. Anonimização, bloqueio ou eliminação: O Usuário pode solicitar: (i) a anonimização dos seus dados pessoais, de forma que eles não possam mais ser relacionados, portanto,
                        deixem de ser dados pessoais; (ii) o bloqueio dos seus dados pessoais, suspendendo temporariamente a possibilidade de os tratarmos para certas finalidades; e (iii) a eliminação
                        dos seus dados pessoais, caso em que deveremos apagar todos os seus dados pessoais sem possibilidade de reversão.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        8.4. Informação sobre a possibilidade de não consentir: O Usuário tem o direito de receber informações claras e completas sobre a possibilidade e as consequências de não fornecer
                        consentimento, quando ele for solicitado pela LEADSMAPPING. O seu consentimento, quando necessário, deve ser livre e informado. Portanto, sempre que for solicitado o consentimento,
                        o Usuário será livre para negá-lo - nesses casos, é possível que alguns serviços não possam ser prestados. Porém o simples início ou continuidade do uso dos serviços fornecidos configura
                        aceite a esta política de privacidade.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        8.5. Revogação do consentimento: Caso o Usuário tenha consentido com alguma finalidade de tratamento dos seus dados pessoais, pode sempre optar por retirar o seu consentimento.
                        No entanto, isso não afetará a legalidade de qualquer tratamento realizado anteriormente à revogação. Se o Usuário retirar o seu consentimento, é possível que a LEADSMAPPING
                        fique impossibilitada de prestar certos serviços, mas será emitido um aviso quando isso ocorrer. A revogação se dá pelo cancelamento da conta e envio de email e whatsapp
                        solicitando tal revogação bem como a interrupção no uso de quaisquer serviço fornecido pela LEADSMAPPING.
                    </p>
                </div>
                <div className={styles.titulo}>
                    <h3>9. SEGURANÇA</h3>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Todos os dados pessoais serão guardados na base de dados da LEADSMAPPING ou em base de dados mantida “na nuvem”, pelos fornecedores de serviços contratados pela LEADSMAPPING, os
                        quais estão devidamente de acordo com a legislação de dados vigente, ainda que eles operem em países diversos do Brasil.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        A LEADSMAPPING e seus fornecedores usam uma variedade de procedimentos de segurança para proteger a confidencialidade, segurança e integridade dos dados pessoais, evitando assim
                        qualquer dano causado pelo processamento desses dados. Além disso, a LEADSMAPPING garante que só terão acesso aos dados pessoais dos Usuários os colaboradores preparados para
                        este fim, não sendo compartilhados desnecessariamente com outros colaboradores que não lidem com as obrigações contratuais do contratante/profissional.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Ainda que a LEADSMAPPING use medidas de segurança e monitore os sistemas para verificar vulnerabilidades e ataques para proteger os dados pessoais contra divulgação, abuso ou adulteração,
                        os Usuários entendem e concordam não haver garantia de que as informações não poderão ser acessadas, divulgadas, alteradas ou destruídas por violação de qualquer uma das proteções físicas,
                        técnicas ou administrativas.
                    </p>
                </div>
                <div className={styles.titulo}>
                    <h3>10. INTEGRAÇÃO COM SERVIÇOS PARCEIROS</h3>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Para o bom funcionamento dos serviços da LEADSMAPPING, principalmente para a divulgação da localização dos prestadores de serviços, é possível que sejam utilizados serviços de terceiros não
                        controlados pela LEADSMAPPING, como o Google Maps.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        O Usuário fica ciente de que o uso desses serviços está sujeito às políticas de privacidade desses fornecedores e que a LEADSMAPPING não é proprietária e nem controla esses terceiros e, quando
                        o Usuário interage com eles, está fornecendo suas informações a estas empresas, estando a LEADSMAPPING isenta de qualquer responsabilidade.
                    </p>
                </div>
                <div className={styles.titulo}>
                    <h3>11. DIREITO DE ACESSO E CONTROLE DOS DADOS</h3>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Em cumprimento à Lei Geral de Proteção de Dados (Lei nº 13.709/2018), a LEADSMAPPING permite que o Usuário exclua seus dados pessoais da plataforma, hipótese que só será autorizada
                        caso não haja mais o uso dos serviços da empresa
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        A LEADSMAPPING também permite que o Usuário altere ou corrija os dados que sejam mutáveis; que coloque limites ou restrições no uso de dados, dentro do que for possível; e que solicite
                        uma cópia eletrônica dos dados pessoais fornecidos.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Para isso, o Usuário deverá enviar um e-mail e whatsapp através do endereço eletrônico <a href="mailto:atendimento@leadsmapping.com.br">atendimento@leadsmapping.com.br</a> e whatsapp apresentado no site. Não se faz necessário o envio de
                        mensagem via whatsapp caso não haja número de contatos disponível. Os atos só serão tomados após a análise do pedido, que será feita com base nas leis aplicáveis.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Este contato também poderá ser utilizado para esclarecimento de dúvidas acerca do tratamento de dados.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Além disso, em caso de rescisão do contrato com a LEADSMAPPING, os dados do Usuário serão excluídos da plataforma após 5 (cinco) anos, em cumprimento ao Código Civil. A regra é aplicada
                        caso o Usuário não tenha mais nenhuma pendência com a LEADSMAPPING. Enquanto perdurar tais pendências, os dados do Usuário serão mantidos na nossa base de dados.
                    </p>
                </div>
                <div className={styles.titulo}>
                    <h3>12. REVISÕES À POLÍTICA DE PRIVACIDADE</h3>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        A LEADSMAPPING se reserva ao direito de modificar esta Política a qualquer momento, de acordo com sua necessidade. Na hipótese de alterações, será publicada a política de privacidade
                        revisada no site/app da LEADSMAPPING e sinalizada com a identificação de que o documento foi revisado.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        A continuidade no uso dos serviços configura aceite das alterações na política de privacidade.
                    </p>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Na hipótese de o Usuário não cancelar a conta antes da data de vigência da política de privacidade revisada, fica expressa a sua concordância e aceitação aos novos termos.
                    </p>
                </div>
                <div className={styles.titulo}>
                    <h3>13. FALE CONOSCO</h3>
                </div>
                <div className={styles.paragrafos}>
                    <p>
                        Em caso de dúvidas ou reclamações sobre esta política de privacidade ou sobre as práticas de manipulação de informações da LEADSMAPPING, o
                        e-mail <a href="mailto:atendimento@leadsmapping.com.br">atendimento@leadsmapping.com.br</a> está disponível para tanto.
                    </p>
                </div>

            </Box>
        </div>
    )
}

export default PrivacyPolicy