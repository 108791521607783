import React, { useEffect, useState } from 'react';
import axios from "axios";
import dayjs from 'dayjs';

import { DragDropContext } from 'react-beautiful-dnd';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import Coluna from './Coluna';
import './Funil.css'

import HeaderFunil from './HeaderFunil';

const Funil = (props) => {
    //backdrop
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };
    //fim backdrop


    const [itens, setItens] = React.useState([]);

    const getFunil = async () => {
        handleToggle()

        const url = `${process.env.REACT_APP_API_URL}/funil`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: props.id
                }

            });
            return response.data;
        } catch (error) {
            setOpen(false)
            console.error(error);
            return error;
        }
    }


    const updateFunil = async (a) => {
        // verifica se o status é Fechamento e se a data de fechamento está vazia ou nula
        if (a.status === 'Fechamento' && (!a.fechamento_date || a.fechamento_date.trim() === '')) {
            // cria um objeto de data com a data atual em milissegundos
            const dataAtual = new Date(Date.now());

            // formata o objeto de data como uma string no formato ISO para inserção no Postgres
            a.fechamento_date = dataAtual.toISOString();
        }

        const request = {
            anotacoes: a.anotacoes,
            tags: a.tags,
            status: a.status,
            id: a.id,
            valor: a.valor,
            fechamento: a.fechamento_date
        };

        const url = `${process.env.REACT_APP_API_URL}/updateFunil`;

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    };


    useEffect(() => {
        for (let i = 0; i < itens.length; i++) {
            updateFunil(itens[i])
        }
    }, [itens])

    useEffect(() => {
        getFunil().then(function (response) {
            setItens(response.data)
            setOpen(false)
        })
        // eslint-disable-next-line 
    }, [])

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        const sourceColuna = itens.filter((item) => item.status === source.droppableId);
        //const destinationColuna = itens.filter((item) => item.status === destination.droppableId);

        const item = sourceColuna[source.index];

        const newItens = [...itens];
        newItens[newItens.indexOf(item)] = { ...item, status: destination.droppableId };

        setItens(newItens);
    };

    const totalNegociacao = itens.reduce((acc, item) => {
        if (item.status === 'Negociação' && item.valor) {
            return acc + item.valor;
        }
        return acc;
    }, 0);

    function getPrimeiroEUltimoDiaDoMesAtual() {
        const dataAtual = new Date(); // Cria um objeto Date com a data atual
        const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1); // Cria um objeto Date com o primeiro dia do mês atual
        const ultimoDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0); // Cria um objeto Date com o último dia do mês atual
        return {
            primeiroDiaDoMes: primeiroDiaDoMes,
            ultimoDiaDoMes: ultimoDiaDoMes
        };
    }

    const [itensFiltrados, setItensFiltrados] = useState([])
    const [filtroAno, setFiltroAno] = useState([])
    const [filtroData, setFiltroData] = useState()
    const [count, setCount] = useState(0)

    function filtrarItensAno() {
        const anoAtual = dayjs().year();
        const primeiroDiaDoAno = dayjs().year(anoAtual).startOf('year');
        const ultimoDiaDoAno = dayjs().year(anoAtual).endOf('year');

        const itensDoAnoAtual = itens.filter((item) =>
            item.status === 'Fechamento' &&
            dayjs(item.fechamento_date).isBetween(primeiroDiaDoAno, ultimoDiaDoAno, 'day', '[]')
        );
        setFiltroAno(itensDoAnoAtual);
    }

    function filtrarItensAnoManual(data) {
        const anoAtual = dayjs(data).year();
        const primeiroDiaDoAno = dayjs().year(anoAtual).startOf('year');
        const ultimoDiaDoAno = dayjs().year(anoAtual).endOf('year');

        const itensDoAnoAtual = itens.filter((item) =>
            item.status === 'Fechamento' &&
            dayjs(item.fechamento_date).isBetween(primeiroDiaDoAno, ultimoDiaDoAno, 'day', '[]')
        );
        setFiltroAno(itensDoAnoAtual);
    }

    useEffect(() => {
        const mesAno = dayjs(filtroData, 'MM/YYYY');
        const primeiroDiaDoMes = mesAno.startOf('month');
        const ultimoDiaDoMes = mesAno.endOf('month');
        const itensDoMesAtual = itens.filter((item) => item.status === 'Fechamento' && new Date(item.fechamento_date).getTime() >= primeiroDiaDoMes.toDate().getTime() && new Date(item.fechamento_date).getTime() < ultimoDiaDoMes.toDate().getTime());
        filtrarItensAnoManual(filtroData)
        setItensFiltrados(itensDoMesAtual);
        // eslint-disable-next-line 
    }, [count])


    useEffect(() => {
        const datasDoMesAtual = getPrimeiroEUltimoDiaDoMesAtual();
        const itensDoMesAtual = itens.filter((item) => item.status === 'Fechamento' && new Date(item.fechamento_date).getTime() >= datasDoMesAtual.primeiroDiaDoMes.getTime() && new Date(item.fechamento_date).getTime() < datasDoMesAtual.ultimoDiaDoMes.getTime());
        filtrarItensAno()
        setItensFiltrados(itensDoMesAtual)
        // eslint-disable-next-line 
    }, [itens])

    const totalFechamento = itensFiltrados.reduce((acc, item) => {
        if (item.status === 'Fechamento' && item.valor) {
            return acc + item.valor;
        }
        return acc;
    }, 0);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="funil">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <HeaderFunil id={props.id} arrayItens={itens} count={count} setCount={setCount} setFiltroData={setFiltroData} item={itens} setItens={setItens} filtroAno={filtroAno} itensFiltrados={itensFiltrados} />

                <div className='containerColunas'>
                    <Coluna nome="Prospecção" label={`${itens.filter((item) => item.status === 'Prospecção').length} leads`} user={props.user} itens={itens.filter((item) => item.status === 'Prospecção')} setItens={setItens} arrayItens={itens} setNumberCall={props.setNumberCall}/>
                    <Coluna nome="Contato" label={`${itens.filter((item) => item.status === 'Contato').length} leads`} user ={props.user} itens={itens.filter((item) => item.status === 'Contato')} setItens={setItens} arrayItens={itens} setNumberCall={props.setNumberCall}/>
                    <Coluna nome="Negociação" valor={totalNegociacao.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} label={`${itens.filter((item) => item.status === 'Negociação').length} leads`} user={props.user} itens={itens.filter((item) => item.status === 'Negociação')} setItens={setItens} arrayItens={itens} setNumberCall={props.setNumberCall}/>
                    <Coluna nome="Fechamento" valor={totalFechamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} label={`${itensFiltrados.filter((item) => item.status === 'Fechamento').length} vendas`} user={props.user} itens={itensFiltrados.filter((item) => item.status === 'Fechamento')} setItens={setItens} arrayItens={itens} setNumberCall={props.setNumberCall}/>
                </div>
            </div>
        </DragDropContext>
    );
};

export default Funil;
