import React, { useState, useEffect, useRef } from 'react';
import './Timer.css';

import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import StopRoundedIcon from '@mui/icons-material/StopRounded';

import { Box } from '@mui/system';


function Timer() {
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const intervalRef = useRef(null);

    useEffect(() => {
        return () => clearInterval(intervalRef.current);
    }, []);

    const handleStart = () => {
        setIsRunning(true);
        intervalRef.current = setInterval(() => {
            setTimeElapsed(prevTime => prevTime + 1);
        }, 1000);
    };

    const handlePause = () => {
        setIsRunning(false);
        clearInterval(intervalRef.current);
    };

    const handleStop = () => {
        setIsRunning(false);
        clearInterval(intervalRef.current);
        setTimeElapsed(0);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className="timer-container">
            <div className="timer">
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '80px',
                }}>
                    <QueryBuilderRoundedIcon />
                    {isRunning ? (
                        <div className="timer-controls">
                            <PauseRoundedIcon onClick={handlePause} />
                        </div>
                    ) : (
                        <PlayArrowRoundedIcon onClick={handleStart} />
                    )}
                    <StopRoundedIcon onClick={handleStop} />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily: 'Inter',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    width: '80px',
                }}
                >
                    <p className="time">{formatTime(timeElapsed)}</p>
                </Box>
            </div>
        </div>
    );
}

export default Timer;
