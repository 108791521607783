import styles from './Sidebar.module.css'

import home from '../../imagens/interface-home-1.svg'
import search from '../../imagens/target-user.svg'
import leads from '../../imagens/Group 2864.svg'
import filter from '../../imagens/interface-filter.svg'
import robot from '../../imagens/robot.svg'
import email from '../../imagens/mail-inbox.svg'
import signer from '../../imagens/signer.svg'
import phone from '../../imagens/phone-call-ringing.svg'

import { Divider } from '@mui/material'


function Sidebar({ sidebarOpen, setVisibleCardMapping, setVisibleListLeads,
    leadsClick, setLeadsClick, setVisibleFunil, setVisibleHome, setVisibleVoip, setVisibleChatBot, setVisibleEmailMarketing, setVisibleAssinaturaDigital }) {

    function visibleCardMap() {
        setVisibleCardMapping(true)
        setVisibleListLeads(false)
        setVisibleFunil(false)
        setVisibleHome(false)
        setVisibleVoip(false)
        setVisibleChatBot(false)  
        setVisibleEmailMarketing(false)
        setVisibleAssinaturaDigital(false) 
    }

    function visibleList() {
        setVisibleCardMapping(false)
        setVisibleListLeads(true)
        setLeadsClick(!leadsClick);
        setVisibleFunil(false)
        setVisibleHome(false)
        setVisibleVoip(false)
        setVisibleChatBot(false) 
        setVisibleEmailMarketing(false)
        setVisibleAssinaturaDigital(false) 
    }

    function visibleFunil() {
        setVisibleCardMapping(false)
        setVisibleListLeads(false)
        setVisibleFunil(true)
        setVisibleHome(false)
        setVisibleVoip(false)
        setVisibleChatBot(false)
        setVisibleEmailMarketing(false)
        setVisibleAssinaturaDigital(false) 
    }

    function visibleHome() {
        setVisibleCardMapping(false)
        setVisibleListLeads(false)
        setVisibleFunil(false)
        setVisibleHome(true)
        setVisibleVoip(false)
        setVisibleChatBot(false)
        setVisibleEmailMarketing(false)
        setVisibleAssinaturaDigital(false)  
    }

    function visibleVoip() {
        setVisibleCardMapping(false)
        setVisibleListLeads(false)
        setVisibleFunil(false)
        setVisibleHome(false)
        setVisibleVoip(true)
        setVisibleChatBot(false)
        setVisibleEmailMarketing(false)
        setVisibleAssinaturaDigital(false)  
    }

    function visibleChatBot() {
        setVisibleCardMapping(false)
        setVisibleListLeads(false)
        setVisibleFunil(false)
        setVisibleHome(false)
        setVisibleVoip(false)
        setVisibleChatBot(true)
        setVisibleEmailMarketing(false)
        setVisibleAssinaturaDigital(false) 
    }

    function visibleEmailMarketing() {
        setVisibleCardMapping(false)
        setVisibleListLeads(false)
        setVisibleFunil(false)
        setVisibleHome(false)
        setVisibleVoip(false)
        setVisibleChatBot(false)
        setVisibleEmailMarketing(true)
        setVisibleAssinaturaDigital(false) 
    }

    function visibleAssinaturaDigital() {
        setVisibleCardMapping(false)
        setVisibleListLeads(false)
        setVisibleFunil(false)
        setVisibleHome(false)
        setVisibleVoip(false)
        setVisibleChatBot(false)
        setVisibleEmailMarketing(false)
        setVisibleAssinaturaDigital(true) 
    }

    return (
        <div className={styles.sidebar}>
            <div className={`${!sidebarOpen ? styles.tabHomeClosed : styles.tabHome}`} onClick={visibleHome}>
                <img src={home} alt="Home" />
                <h6 className={sidebarOpen ? styles.show : styles.hide}>Home</h6>
            </div>

            <div className={styles.line}></div>

            <div className={`${!sidebarOpen ? styles.tabClosed : styles.tab}`} onClick={visibleCardMap}>
                <img src={search} alt="Mapear Mercado" />
                <h6 className={sidebarOpen ? styles.show : styles.hide}>Mapear mercado</h6>
            </div>

            <div className={`${!sidebarOpen ? styles.tabClosed : styles.tab}`} onClick={visibleList}>
                <img src={leads} alt="Leads" />
                <h6 className={sidebarOpen ? styles.show : styles.hide}>Leads</h6>
            </div>

            <div className={`${!sidebarOpen ? styles.tabClosed : styles.tab}`} onClick={visibleFunil}>
                <img src={filter} alt="Funil de Vendas" />
                <h6 className={sidebarOpen ? styles.show : styles.hide}>Funil de vendas</h6>
            </div>

            <Divider />
            <div className={styles.integracoes}>
                <h6 className={sidebarOpen ? styles.show : styles.hide}>INTEGRAÇÕES</h6>
            </div>
            <div className={`${!sidebarOpen ? styles.tabClosed : styles.tab}`}>
                <img src={phone} alt="Webphone" />
                <h6 className={sidebarOpen ? styles.show : styles.hide} onClick={visibleVoip}>Chamadas VoIp</h6>
            </div>
            <div className={`${!sidebarOpen ? styles.tabClosed : styles.tab}`} onClick={visibleChatBot}>
                <img src={robot} alt="Botconversa" />
                <h6 className={sidebarOpen ? styles.show : styles.hide}>Chatbot</h6>
            </div>
            <div className={`${!sidebarOpen ? styles.tabClosed : styles.tab}`} onClick={visibleEmailMarketing}>
                <img src={email} alt="Email" />
                <h6 className={sidebarOpen ? styles.show : styles.hide}>E-mail marketing</h6>
            </div>
            <div className={`${!sidebarOpen ? styles.tabClosed : styles.tab}`} onClick={visibleAssinaturaDigital}>
                <img src={signer} alt="Assinatura digital" />
                <h6 className={sidebarOpen ? styles.show : styles.hide}>Assinatura digital</h6>
            </div>

        </div>
    )
}

export default Sidebar