import * as React from 'react';

import Snackbar from '@mui/material/Snackbar';


function SnackbarAlert({ handleClose, open, vertical, horizontal, text, color }) {

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                message={text}
                key={vertical + horizontal}
                ContentProps={{
                    style: {
                        backgroundColor: color,
                        color: 'white',
                        fontFamily: 'Inter',
                        fontWeight: 500
                    },
                }}
            />
        </div>
    )
}

export default SnackbarAlert