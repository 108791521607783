import { useState } from 'react';
import axios from "axios";
import styles from '../../inputs/Input.module.css'
import _ from 'lodash';

function Cidades({ textLabel, value, uf, handleFunction }) {

    const [cidade, setCidades] = useState([])


    const checkCidade = (e) => {
        axios
            .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios?orderBy=nome`)
            .then((response) => {
                setCidades(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }

    return (
        <div className={styles.inputs}>

            {uf !== null || uf !== undefined ? (
                checkCidade()
            ) : (
                console.log(".")
            )}
            <label>{textLabel}</label>
            <select className={styles.select} id="cidadesUf" onChange={handleFunction} value={value}>
                <option value=""></option>
                {cidade.map(cidade => (
                    <option key={cidade.id} value={_.deburr(cidade.nome).toUpperCase()}>{_.deburr(cidade.nome).toUpperCase()}</option>
                ))}
            </select>
        </div>
    )
}
export default Cidades
