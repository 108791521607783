import * as React from 'react';
import { Box } from "@mui/material";
import { Container } from "@mui/system";
import { Paper } from '@mui/material';
import SwitchUI from '../../components/switch/Switch';

import { useNavigate } from 'react-router-dom'

import '../../components/drawer/DrawerBottom.css'

import check from '../../components/imagens/check.svg'
import unchecked from '../../components/imagens/unchecked.svg'

function Planos() {

    const stylePaper = {
        color: "rgb(33, 43, 54)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundImage: "none",
        overflow: "hidden",
        position: "relative",
        borderRadius: "16px",
        zIndex: 0,
        padding: "40px",
        backgroundColor: "rgb(255, 255, 255)",
        boxShadow: "rgb(145 158 171 / 16%) 0px 24px 48px 0px",
        maxWidth: '380px'
    }

    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked(!checked)
    }

    const navigate = useNavigate()

    const handleLogin = () => {
        navigate('/login')
    }

    return (
        <Container sx={{ marginTop: "60px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'center' }}>
                <div style={{
                    width: "100px",
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: "center",
                    justifyContent: 'center',
                    margin: "20px",
                    fontSize: "1em",
                    color: "#637381",
                }}>
                    <span>Mensal</span>
                    <SwitchUI checked={checked} handleChange={handleChange} />
                    <span>Anual</span>
                </div>
            </Box>
            {!checked ? (
                <Box sx={{
                    gap: "24px",
                    display: "grid",
                    justifyContent: "center",
                    alignContent: "center",
                    '@media (min-width: 900px)': {
                        gridTemplateColumns: "repeat(2, 1fr)"
                    }
                }}>
                    <Paper sx={stylePaper}>
                        <div className='DrawerBottom'>
                            <span className='titlePlan'>Plano Grátis</span>
                            <div className='css-c7dxy7'>
                                <h5>R$</h5>
                                <h2>0,00</h2>
                                <span>/mês</span>
                            </div>
                        </div>

                        <div className='DrawerBottomBeneficios'>
                            <ul className='css-14i14n2 '>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Mapeamento e segmentação</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={unchecked} alt="check" />
                                    <p style={{ color: "#919eab" }} className='css-1o38g2n'>Sem acesso aos leads</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Funil de vendas</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Gestor de leads (Kanban)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Importação de leads (excel)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={unchecked} alt="check" />
                                    <p style={{ color: "#919eab" }} className='css-1o38g2n'>Visualização detalhada de leads</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={unchecked} alt="check" />
                                    <p style={{ color: "#919eab" }} className='css-1o38g2n'>Exportação CSV: limite de 200 empresas</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={unchecked} alt="check" />
                                    <p style={{ color: "#919eab" }} className='css-1o38g2n'>Histórico da base de pesquisa</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Painel de resultados</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Filtros especiais</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Atendimento via email</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Atendimento via Whatsapp</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Leads avulsos (R$ 0,40/lead)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={unchecked} alt="check" />
                                    <p style={{ color: "#919eab" }} className='css-1o38g2n'>Suporte personalizado</p>
                                </li>
                            </ul>
                            <button onClick={handleLogin}>Criar conta</button>
                        </div>
                    </Paper>

                    <Paper sx={stylePaper}>
                        <div className='DrawerBottom'>
                            <span className='titlePlan'>Plano Mensal</span>
                            <div className='css-c7dxy7'>
                                <h5>R$</h5>
                                <h2>49,90</h2>
                                <span>/mês</span>
                            </div>
                        </div>

                        <div className='DrawerBottomBeneficios'>
                            <ul className='css-14i14n2 '>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Mapeamento e segmentação</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Acesso a até 1000 leads</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Funil de vendas</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Gestor de leads (Kanban)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Importação de leads (excel)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Visualização detalhada de leads</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Exportação CSV: limite de 5k</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Histórico da base de pesquisa</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Painel de resultados</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Filtros especiais</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Atendimento via email</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Atendimento via Whatsapp</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Leads avulsos (R$ 0,40/lead)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={unchecked} alt="check" />
                                    <p style={{ color: "#919eab" }} className='css-1o38g2n'>Suporte personalizado</p>
                                </li>
                            </ul>
                            <button onClick={handleLogin}>Teste grátis</button>
                        </div>
                    </Paper>

                    <Paper sx={stylePaper}>
                        <div className='DrawerBottom'>
                            <span className='titlePlan'>Plano Bimestral</span>
                            <div className='css-c7dxy7'>
                                <h5>R$</h5>
                                <h2>69,90</h2>
                            </div>
                        </div>

                        <div className='DrawerBottomBeneficios'>
                            <ul className='css-14i14n2 '>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Mapeamento e segmentação</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Acesso a até 2000 leads</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Funil de vendas</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Gestor de leads (Kanban)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Importação de leads (excel)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Visualização detalhada de leads</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Exportação CSV: limite de 10k</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Histórico da base de pesquisa</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Painel de resultados</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Filtros especiais</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Atendimento via email</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Atendimento via Whatsapp</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Leads avulsos (R$ 0,40/lead)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={unchecked} alt="check" />
                                    <p style={{ color: "#919eab" }} className='css-1o38g2n'>Suporte personalizado</p>
                                </li>
                            </ul>
                            <button onClick={handleLogin}>Teste grátis</button>
                        </div>
                    </Paper>

                    <Paper sx={stylePaper}>
                        <div className='DrawerBottom'>
                            <div className='tagCustoBeneficio'>
                                <span className='css-10wqog4'>Melhor custo benefício</span>
                            </div>
                            <span className='titlePlan'>Plano Trimestral</span>
                            <div className='css-c7dxy7'>
                                <h5>R$</h5>
                                <h2>399,00</h2>
                            </div>
                        </div>

                        <div className='DrawerBottomBeneficios'>
                            <ul className='css-14i14n2 '>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Mapeamento e segmentação</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Leads ilimitados</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Funil de vendas</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Gestor de leads (Kanban)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Importação de leads (excel)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Visualização detalhada de leads</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Exportação CSV: limite de 80k</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Histórico da base de pesquisa</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Painel de resultados</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Filtros especiais</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Atendimento via email</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Atendimento via Whatsapp</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Leads avulsos (R$ 0,40/lead)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={unchecked} alt="check" />
                                    <p style={{ color: "#919eab" }} className='css-1o38g2n'>Suporte personalizado</p>
                                </li>
                            </ul>
                            <button onClick={handleLogin}>Teste grátis</button>
                        </div>
                    </Paper>
                </Box>
            ) :
                <Box sx={{
                    gap: "24px",
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                    '@media (min-width: 900px)': {
                        gridTemplateColumns: "repeat(1, 1fr)"
                    }
                }}>
                    <Paper sx={stylePaper}>
                        <div className='DrawerBottom'>
                            <span className='titlePlan'>Plano Anual</span>
                            <div className='css-c7dxy7'>
                                <h5>R$</h5>
                                <h2>838,80</h2>
                                <span>/ano</span>
                            </div>
                        </div>

                        <div className='DrawerBottomBeneficios'>
                            <ul className='css-14i14n2 '>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Mapeamento e segmentação</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Leads ilimitados</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Funil de vendas</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Gestor de leads (Kanban)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Importação de leads (excel)</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Visualização detalhada de leads</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Exportação CSV: limite de 100k empresas</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Histórico da base de pesquisa</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Painel de resultados</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Filtros especiais</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Atendimento via email</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Atendimento via Whatsapp</p>
                                </li>
                                <li className=' css-1yo0sni'>
                                    <img className='css-k0e32s' src={check} alt="check" />
                                    <p className='css-1o38g2n'>Suporte personalizado</p>
                                </li>
                            </ul>
                            <button onClick={handleLogin}>Teste grátis</button>
                        </div>
                    </Paper>
                </Box>
            }
        </Container>

    )
}

export default Planos