import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from "../../components/layout/Container"
import Sidebar from "../../components/layout/sidebar/Sidebar"
import Desktop from "../../components/layout/desktop/Desktop"

import styles from './Dashboard.module.css'

import Cookies from 'js-cookie';
import axios from "axios";
import MenuAppBar from '../../components/layout/header/Bar';

import { IconButton } from '@mui/material';

import CallEndRoundedIcon from '@mui/icons-material/CallEndRounded';
import BasicSpeedDial from './SpeedDial';

import { Helmet } from 'react-helmet';


function Dashboard() {

    const navigate = useNavigate();
    const userId = Cookies.get('_ui');
    const expirationDate = Cookies.get('expirationDate');
    const [user, setUser] = useState()
    const [limit, setLimit] = useState(0)

    const getUser = async (id) => {

        const url = `${process.env.REACT_APP_API_URL}/getUserID`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id
                }

            });

            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    const getLimit = async (id) => {
        const url = `${process.env.REACT_APP_API_URL}/credit`;
        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    iduser: id
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    useEffect(() => {
        if (!userId || new Date() > new Date(expirationDate)) {
            navigate('/login');
        }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        getUser(userId).then(response => {
            setUser(response[0])
        })

        getLimit(userId).then(response => {
            console.log(response)
            setLimit(response)
        })
    }, [userId])


    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [visibleCardMapping, setVisibleCardMapping] = useState(false)
    const [visibleListLeads, setVisibleListLeads] = useState(false)
    const [visibleFunil, setVisibleFunil] = useState(false)
    const [visibleHome, setVisibleHome] = useState(true)
    const [visibleVoip, setVisibleVoip] = useState(false)
    const [visibleChatBot, setVisibleChatBot] = useState(false)
    const [visibleEmailMarketing, setVisibleEmailMarketing] = useState(false)
    const [visibleAssinaturaDigital, setVisibleAssinaturaDigital] = useState(false)

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    }

    const [leadsClick, setLeadsClick] = useState(true)


    //voip
    const [registrado, setRegistrado] = useState(false);
    const [calling, setCalling] = useState(false)
    const [stateCall, setStateCall] = useState('Chamando...')
    const [secondsDuration, setSecondsDuration] = useState(0);

    useEffect(() => {

        if (user && user.voip) {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.id = "getSelo";
            script.src = 'https://leadsmapping.com.br/webphone_api.js';
            script.async = true;

            script.onload = () => {
                // aqui você pode acessar a função que está dentro do script
                window.webphone_api.parameters['autostart'] = 0;

                window.webphone_api.onAppStateChange(function (appState) {
                    if (appState === 'loaded') {
                        window.webphone_api.start();
                        setEventText('EVENT, Loaded');
                        setLoaded(true);
                        Start()
                    }
                    else if (appState === 'started') {
                        setEventText('EVENT, Started');
                    }
                    else if (appState === 'stopped') {
                        setEventText('EVENT, Stopped');
                    }
                });

                window.webphone_api.onRegStateChange(function (regState) {
                    if (regState === 'registered') {
                        setRegistrado(true);
                        setEventText('EVENT, Registered');
                    }
                    else if (regState === 'unregistered') {
                        setEventText('EVENT, UnRegistered');
                    }
                    else if (regState === 'failed') {
                        setEventText('EVENT, Register failed.');
                    }
                });

                window.webphone_api.onCallStateChange(function (event, direction, peername, peerdisplayname, line, callid) {

                    setEventText('EVENT, Call ' + event);

                    if (event === 'setup') {
                        setHangupButtonDisabled(false);

                        if (direction == 1) {
                            // means it's outgoing call
                        }
                        else if (direction == 2) {
                            // means it's icoming call
                            setIncomingCallLayoutVisible(true);
                            setStateCall('Em andamento...')
                            setCalling(true);
                            Accept()
                        }

                        setEventText('EVENT, Call setup');
                    }
                    //detecting the end of a call, even if it wasn't successfull
                    else if (event === 'disconnected') {
                        Hangup()
                        setCalling(false);
                        setStateCall('Ligação encerrada...')
                    }
                });
            }

            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }


    }, [user && user.voip]);

    const [serveraddress, setServeraddress] = useState('leadsmapping.api4com.com');
    let username = user && user.ramal ? user.ramal : ''
    let password = user && user.voip_password ? user.voip_password : ''
    const [eventText, setEventText] = useState('EVENT, Inicializando');
    const [incomingCallLayoutVisible, setIncomingCallLayoutVisible] = useState(false);
    const [hangupButtonDisabled, setHangupButtonDisabled] = useState(true);

    const [loaded, setLoaded] = useState(false);


    function Start() {
        window.webphone_api.setparameter('serveraddress', serveraddress);
        window.webphone_api.setparameter('username', username);
        window.webphone_api.setparameter('password', password);

        setEventText('EVENT, Initializing...');

        window.webphone_api.start();
    }

    function Accept() {
        window.webphone_api.accept();
    }

    function Reject() {
        window.webphone_api.reject();
        setCalling(false);
    }

    function Hangup() {
        window.webphone_api.hangup();
        setCalling(false);
    }


    //fim voip
    const [numberCall, setNumberCall] = useState('')
    // const action = (
    //     <div className={styles.call}>
    //         <div>
    //             <p>{numberCall}</p>
    //             <span>{stateCall}</span>
    //         </div>
    //         <IconButton onClick={Reject} size="small" aria-label="fechar" color="inherit"
    //             sx={{
    //                 backgroundColor: '#FF3A32',
    //                 borderRadius: '50%',
    //                 padding: '10px',
    //                 '&:hover': {
    //                     backgroundColor: '#FF3A32',
    //                 }
    //             }}>
    //             <CallEndRoundedIcon sx={{ color: "#FFF" }} />
    //         </IconButton>
    //     </div>
    // );

    return (
        <Container>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            {user && user.voip ? (
                <BasicSpeedDial calling={calling} user={user} hangup={Hangup} numberDial={numberCall} />
            ) : null}

            {/* {calling ? (
                <Snackbar
                    open={true}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    action={action}
                    sx={{ marginTop: "60px", borderRadius: "10px", backgroundColor: "#2B2B36" }}
                    TransitionComponent={(props) => <Slide {...props} direction="right" />}
                />
            ) : null} */}


            <MenuAppBar user={user} limit={limit} sidebarOpen={sidebarOpen} sidebar={toggleSidebar}
                setVisibleHome={setVisibleHome} visibleFunil={visibleFunil} setVisibleFunil={setVisibleFunil} visibleCardMapping={visibleCardMapping}
                setVisibleCardMapping={setVisibleCardMapping} setVisibleListLeads={setVisibleListLeads}
                leadsClick={leadsClick} setLeadsClick={setLeadsClick} registrado={registrado}
                visibleVoip={visibleVoip} setVisibleVoip={setVisibleVoip} setVisibleChatBot={setVisibleChatBot} setVisibleEmailMarketing={setVisibleEmailMarketing}
                setVisibleAssinaturaDigital={setVisibleAssinaturaDigital}
            />
            <div className={styles.dashboard}>
                <div className={`${sidebarOpen ? styles.containerSidebarOpen : styles.containerSidebarClosed}`}>
                    <Sidebar sidebarOpen={sidebarOpen} setVisibleHome={setVisibleHome} visibleFunil={visibleFunil} setVisibleFunil={setVisibleFunil}
                        visibleCardMapping={visibleCardMapping} setVisibleCardMapping={setVisibleCardMapping} setVisibleListLeads={setVisibleListLeads}
                        leadsClick={leadsClick} setLeadsClick={setLeadsClick} setVisibleVoip={setVisibleVoip} setVisibleChatBot={setVisibleChatBot}
                        setVisibleEmailMarketing={setVisibleEmailMarketing} setVisibleAssinaturaDigital={setVisibleAssinaturaDigital} />
                </div>
                <div className={styles.containerDesktop}>
                    <Desktop id={userId} limit={limit} setUser={setUser} setLimit={setLimit} user={user} visibleHome={visibleHome} visibleFunil={visibleFunil} visibleCardMapping={visibleCardMapping} visibleListLeads={visibleListLeads}
                        leadsClick={leadsClick} setLeadsClick={setLeadsClick} calling={calling} setCalling={setCalling} setNumberCall={setNumberCall}
                        visibleVoip={visibleVoip} visibleChatBot={visibleChatBot} visibleEmailMarketing={visibleEmailMarketing} visibleAssinaturaDigital={visibleAssinaturaDigital}
                    />
                </div>
            </div>
        </Container>

    )
}

export default Dashboard
