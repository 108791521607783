import React from 'react';
import { useState, useRef } from "react";

import { Droppable } from 'react-beautiful-dnd';
import Item from './Item';
import './Funil.css'


const Coluna = (props) => {
    const whatsappIconRef = useRef(null);
    const [open, setOpen] = useState(false);

    const atualizarItem = (id, novoValor) => {
        const novoItens = props.itens.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    valor: novoValor,
                };
            }
            return item;
        });
        props.setItens(novoItens);
    };

    return (
        <div className="colunas">
            <div className='header'>
                <h6>{props.nome.toUpperCase()}</h6>
                <div className='divLabel'>
                    {props.valor ? (
                        <React.Fragment>
                            <p>{props.valor}</p>
                            <label>{props.label}</label>
                        </React.Fragment>
                    ) : (
                        <label>{props.label}</label>
                    )}

                </div>
            </div>
            <Droppable droppableId={props.nome}>
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="coluna"
                    >
                        {props.itens.sort((a, b) => a.razao_social > b.razao_social ? 1 : -1).map((item, index) => (
                            <Item setNumberCall={props.setNumberCall} user={props.user} setItens={props.setItens} key={item.id} item={item} index={index} whatsappIconRef={whatsappIconRef} open={open} setOpen={setOpen} arrayItens={props.arrayItens}/>
                        ))}
                        {provided.placeholder}
                        {props.isWhatsappPopupVisible && (<div className='popup'> <p>{props.phoneNumber}</p> </div>)}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default Coluna;
