import styles from './Login.module.css'
import * as React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../components/imagens/logoBlackGreen.svg'

import { useEffect, useState } from 'react'
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import { SHA256 } from 'crypto-js';

import Cookies from 'js-cookie';
import SnackbarAlert from '../../components/layout/snackbar/Snackbar';
import ValidationTextFields from '../../components/textField/InputValidation';
import PasswordTextField from '../../components/textField/InputPassword';
import BasicButtonsGoogle from '../../components/button/buttonLoginGoogle';
import BasicButtonsMicrosoft from '../../components/button/buttonLoginMicrosoft';
import BasicButtons from '../../components/button/button';
import ClipLoader from "react-spinners/ClipLoader";

import Google from '../../components/imagens/GoogleLogin.svg'
import Microsoft from '../../components/imagens/MicrosoftLogin.svg'
import SelectTextFields from '../../components/textField/inputSelect';
import SelectTextFieldsCNPJ from '../../components/textField/inputCNPJ';
import SelectTextFieldsCPF from '../../components/textField/inputCpf';

import { v4 as uuidv4 } from 'uuid';
import ImageAvatars from '../../components/avatar/Avatar';
import BackgroundLetterAvatars from '../../components/avatar/LetterAvatar';
import SimpleBackdrop from '../../components/backdrop/Backdrop';
import { Helmet } from 'react-helmet';

function Login({ autenthicated, setAutenthicated }) {

    //Snippet Google
    const handleClick = () => {
        window.gtag('event', 'conversion', {
            'send_to': 'AW-11085727816/NQIgCKH2n5YYEMiQi6Yp',
            'event_callback': window.location.href
        });
    }


    //SnackAlert
    const [state, setState] = React.useState({
        openAlert: false,
        text: "Credenciais inválidas!"
    });


    const handleCloseAlert = () => {
        setState({ ...state, openAlert: false });
    };
    //fim SnackAlert

    const expirationDate = new Date(Date.now() + 8 * 60 * 60 * 1000);

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [id, setId] = useState("")

    const hash = SHA256(password).toString();

    function handleEmail(e) {
        setEmail(e.target.value)
    }

    function handlePassword(e) {
        setPassword(e.target.value)
    }

    const fetchData = async (page) => {
        const url = `${process.env.REACT_APP_API_URL}/oauth`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    email: email,
                    hash: hash
                }

            })
            return response.data;
        } catch (error) {
            setAutenthicated(false)
            return error;
        }

    };

    const login = () => {
        if (password) {
            fetchData().then(response => {

                if (response.authenticated) {
                    setAutenthicated(response.authenticated)
                    setId(response.id_user)
                    setUserActive(response.ative)

                    // if (!response.authenticated) {
                    //     console.log(response)
                    //     setState({ ...state, openAlert: true });

                    //     setTimeout(() => {
                    //         setState({ ...state, openAlert: false });
                    //     }, 5000);
                    // }
                } else {
                    setState({ ...state, openAlert: true });
                    setTimeout(() => {
                        setState({ ...state, openAlert: false });
                    }, 5000);
                }

            });
        } else {
            setState({ ...state, openAlert: true, text: "Informe uma senha válida!" })
        }
    };

    const navigate = useNavigate();

    const [cadastrese, setCadastrese] = useState("none")
    const [getlogin, setGetLogin] = useState("none")
    const [oauth, setOauth] = useState("")
    const [cadSocial, setCadSocial] = useState("none")
    const [completeDados, setCompleteDados] = useState("none")

    const loginOauth = () => {
        setCadastrese("none")
        setGetLogin("none")
        setCadSocial("none")
        setCompleteDados("none")
        setOauth("")
    };

    const cadastroSocial = () => {
        setCadastrese("none")
        setGetLogin("none")
        setOauth("none")
        setCompleteDados("none")
        setCadSocial("")
    };

    const cadastrar = () => {
        setCadastrese("")
        setGetLogin("none")
        setOauth("none")
        setCadSocial("none")
        setCompleteDados("none")
    };

    const facaLogin = () => {
        setCadastrese("none")
        setGetLogin("")
        setOauth("none")
        setCadSocial("none")
        setCompleteDados("none")
    };

    const completarDados = () => {
        setCadastrese("none")
        setGetLogin("none")
        setOauth("none")
        setCadSocial("none")
        setCompleteDados("")
    };

    const [isLogin, setIsLogin] = useState(false)
    const [isCad, setIsCad] = useState(false)

    const [userGoogle, setUserGoogle] = useState();
    const [userMicrosoft, setUserMicrosoft] = useState();
    const [emailCadastro, setEmailCadastro] = useState("");
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [tipo, setTipo] = useState("")
    const [ufs, setUfs] = useState([])
    const [estado, setEstado] = useState("")
    const [cpfCnpj, setCpfCnpj] = useState("")
    const [profile, setProfile] = useState("")


    const userId = uuidv4();
    const [userActive, setUserActive] = useState(false)


    const getUser = async (email) => {

        const url = `${process.env.REACT_APP_API_URL}/getUser`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    email: email
                }

            });
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }

    const getCpf = async (email) => {

        const url = `${process.env.REACT_APP_API_URL}/getCpf`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    cpf: cpfCnpj
                }

            });
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    }


    const createUser = async () => {
        const currentDate = new Date()
        const lastAccess = new Date()
        const createDate = new Date()
        const lastUpdate = new Date()
        const planDate = new Date()
        const dueDate = new Date(currentDate.setDate(currentDate.getDate() + 15))

        handleClick()

        const request = {
            iduser: userId,
            first_name: firstName,
            last_name: lastName,
            email: emailCadastro.toString(),
            ddd: "",
            phone: "",
            password: hash,
            last_access: lastAccess.toISOString(),
            create_date: createDate.toISOString(),
            last_update: lastUpdate.toISOString(),
            plan_id: 1,
            plan_date: planDate.toISOString(),
            plan_due_date: dueDate.toISOString(),
            ative: true,
            type_user: "Assinante",
            cpf_cnpj: cpfCnpj,
            uf: estado,
            cidade: "",
            picture: profile
        };

        const url = `${process.env.REACT_APP_API_URL}/createUser`;

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    };

    const [visibleClipLoader, setVisibleClipLoader] = useState(false)
    const [visibleText, setVisibleText] = useState("")

    const criarConta = () => {
        if (firstName && lastName && tipo && hash && ufs && estado && cpfCnpj) {
            getUser(emailCadastro).then(response => {
                if (response.length == 0) {
                    getCpf().then(response => {
                        if (response.length == 0) {
                            setVisibleClipLoader(true)
                            setVisibleText("none")

                            createUser().then(response => {
                                if (response.code === "ERR_BAD_RESPONSE") {
                                    setVisibleClipLoader(false)
                                    setVisibleText("")
                                    setState({ ...state, openAlert: true, text: "Ocorreu um erro!" });
                                }

                                if (response.message === "Registro inserido com sucesso") {
                                    getUser(emailCadastro).then(function (response) {
                                        if (response.length === 0) {
                                            setState({ ...state, openAlert: true, text: "Ocorreu um erro!" });
                                            setVisibleClipLoader(false)
                                            setVisibleText("")
                                        } else {
                                            setOpen(true)
                                            setId(response[0].iduser)
                                            setAutenthicated(true)
                                        }
                                    })
                                }
                            });
                        } else {
                            setState({ ...state, openAlert: true, text: "Já existe um usuário com este CPF/CNPJ!" });
                        }
                    })
                } else {
                    setState({ ...state, openAlert: true, text: "Já existe um usuário com este e-mail!" });
                }
            })
        } else {
            setState({ ...state, openAlert: true, text: "Preencha todos os campos!" });
            setVisibleClipLoader(false)
            setVisibleText("")
        }

    };

    useEffect(() => {
        if (isLogin) {
            //se for login segue para dashboard
            if (userGoogle) {
                setEmailCadastro(userGoogle.email)
                setFirstName(userGoogle.given_name)
                setLastName(userGoogle.family_name)
                setProfile(userGoogle.picture)

                getUser(userGoogle.email).then(function (response) {
                    if (response.length === 0) {
                        setState({ ...state, openAlert: true, text: "Usuário não encontrado. Crie uma conta!" });
                        completarDados()
                    } else {
                        setOpen(true)
                        setId(response[0].iduser)
                        setAutenthicated(true) //faz login
                    }
                })
            }
        }

        if (isCad) {  // se for cadastro segue para processo de cadastro
            if (userGoogle) {
                setEmailCadastro(userGoogle.email)
                setFirstName(userGoogle.given_name)
                setLastName(userGoogle.family_name)
                setProfile(userGoogle.picture)

                getUser(userGoogle.email).then(function (response) {
                    if (response.length === 0) {
                        completarDados() //se email não constar cadastra
                    } else {
                        setState({ ...state, openAlert: true, text: "Usuário já possui cadastro. Faça login!" });
                    }
                })
            }
        }
    }, [userGoogle])

    const [nameProfile, setNameProfile] = useState("")


    useEffect(() => {
        if (isLogin) {
            //se for login segue para dashboard
            if (userMicrosoft) {
                setNameProfile(userMicrosoft.name)
                setEmailCadastro(userMicrosoft.username)
                setFirstName("")
                setLastName("")
                setProfile("")

                getUser(userMicrosoft.username).then(function (response) {

                    if (response.length === 0) {
                        setState({ ...state, openAlert: true, text: "Usuário não encontrado. Crie uma conta!" }); //se email não constar cadastra
                        completarDados()
                    } else {
                        setOpen(true)
                        setId(response[0].iduser)
                        setAutenthicated(true) //faz login
                    }
                })
            }
        }

        if (isCad) {  // se for cadastro segue para processo de cadastro
            if (userMicrosoft) {
                setEmailCadastro(userMicrosoft.username)
                setFirstName("")
                setLastName("")
                setProfile("")

                getUser(userMicrosoft.username).then(function (response) {

                    if (response.length === 0) {
                        completarDados() //se email não constar cadastra
                    } else {

                        setState({ ...state, openAlert: true, text: "Usuário já possui cadastro. Faça login!" }); //se email já existir exibe aviso
                    }
                })
            }
        }
    }, [userMicrosoft])

    const tipoSelect = [
        {
            value: 'Pessoa física',
        },
        {
            value: 'Pessoa jurídica',
        }
    ];

    const [cpfVisible, setCpfVisible] = useState("none")
    const [cnpjVisible, setCnpjVisible] = useState("none")

    useEffect(() => {
        if (tipo === "Pessoa física") {
            setCpfVisible("")
            setCnpjVisible("none")
        } else if (tipo === "Pessoa jurídica") {
            setCpfVisible("none")
            setCnpjVisible("")
        }
    }, [tipo])

    useEffect(() => {
        axios
            .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome")
            .then((response) => {
                const ufsObj = response.data.map((estado) => ({ value: estado.sigla }));
                setUfs(ufsObj);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const userAccess = async (id) => {
        const url = `${process.env.REACT_APP_API_URL}/userAccess`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id,
                }
            })
            return response.data;
        } catch (error) {
            return error;
        }

    };

    useEffect(() => {
        if (autenthicated) {
            userAccess(id)
            Cookies.set('_ui', id, { expires: expirationDate });
            setOpen(false)
            navigate('/dashboard');
            setAutenthicated(false)
        }
        // eslint-disable-next-line
    }, [autenthicated]);

    //backdrop
    const [open, setOpen] = useState(false);

    return (
        <div className={styles.loginContainer}>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <SimpleBackdrop open={open} setOpen={setOpen} />
            <SnackbarAlert handleClose={handleCloseAlert} open={state.openAlert} vertical="top" horizontal="right" color="#ec3c62" text={state.text} />
            <img className={styles.imgLogin} src={logo} alt="Logomarca" />

            <div className={styles.loginSection} style={{ display: oauth }}>
                <BasicButtonsGoogle logo={Google} acaoLogin={setIsLogin} valueLogin={true} acaoCad={setIsCad} setUser={setUserGoogle} text={"Login com Google"} />
                <BasicButtonsMicrosoft logo={Microsoft} acaoLogin={setIsLogin} valueLogin={true} acaoCad={setIsCad} setProfile={setProfile} setUser={setUserMicrosoft} text={"Login com Conta Microsoft"} />
                <BasicButtons onclick={facaLogin} text={"Entrar com email e senha"} />

                <div className={styles.txtCadastrese}>
                    <p>Não tem uma conta?</p>
                    <label onClick={cadastroSocial}>Cadastre-se</label>
                </div>
            </div>

            <div className={styles.loginSection} style={{ display: cadSocial }}>
                <BasicButtonsGoogle logo={Google} acaoLogin={setIsLogin} valueLogin={false} acaoCad={setIsCad} setUser={setUserGoogle} text={"Cadastrar com Google"} />
                <BasicButtonsMicrosoft logo={Microsoft} acaoLogin={setIsLogin} valueLogin={false} acaoCad={setIsCad} setProfile={setProfile} setUser={setUserMicrosoft} text={"Cadastrar com Microsoft"} />
                <BasicButtons onclick={cadastrar} text={"Cadastrar com email e senha"} />

                <div className={styles.txtCadastrese}>
                    <p>Já tem uma conta?</p>
                    <label onClick={loginOauth}>Faça login</label>
                </div>
            </div>

            <div className={styles.loginSection} style={{ display: getlogin }}>
                <ValidationTextFields id={"email"} label={"E-mail"} value={email} setValue={setEmail} />
                <PasswordTextField id={"password"} label={"Senha"} value={password} setValue={setPassword} />

                <div className={styles.txtForgot}>
                    <p>Esqueci minha senha</p>
                </div>
                <button className={styles.btnLogin} onClick={login}>Entrar</button>

                <div className={styles.txtCadastrese}>
                    <p>Ainda não tem uma conta?</p>
                    <label onClick={cadastroSocial}>Cadastre-se agora</label>
                </div>
            </div>

            <div className={styles.loginSection} style={{ display: cadastrese }}>
                <h5>Criar uma conta</h5>

                <ValidationTextFields id={"email1"} label={"E-mail"} value={emailCadastro} setValue={setEmailCadastro} />
                <ValidationTextFields id={"firstName"} label={"Nome"} value={firstName} setValue={setFirstName} />
                <ValidationTextFields id={"lastName"} label={"Sobrenome"} value={lastName} setValue={setLastName} />
                <SelectTextFields currencies={ufs} label={"Estado"} value={estado} setValue={setEstado} />
                <SelectTextFields currencies={tipoSelect} label={"Tipo"} value={tipo} setValue={setTipo} />

                <div style={{ display: cnpjVisible, width: "100%" }}>
                    <SelectTextFieldsCNPJ id={"cnpj-1"} setCnpj={setCpfCnpj} />
                </div>

                <div style={{ display: cpfVisible, width: "100%" }}>
                    <SelectTextFieldsCPF id={"cpf-1"} setCpf={setCpfCnpj} />
                </div>

                <PasswordTextField id={"senha"} label={"Crie sua senha"} value={password} setValue={setPassword} />

                <div className={styles.txtPolitica}>
                    <p>Conheça nossa</p>
                    <Link to="https://leadsmapping.com.br/politica-de-privacidade" target="_blank">Política de Privacidade</Link>
                </div>

                <button className={styles.btnLogin} onClick={criarConta}>
                    <p style={{ display: visibleText }}>Cadastrar minha conta</p>
                    {visibleClipLoader ? (
                        <ClipLoader
                            color="white"
                            size={25}
                            speedMultiplier={1}
                        />
                    ) : null}
                </button>

                <div className={styles.txtCadastrese}>
                    <p>Já tem uma conta?</p>
                    <label onClick={facaLogin}>Faça login</label>
                </div>
            </div>


            <div className={styles.loginSection} style={{ display: completeDados }}>
                <h5>Complete seus dados</h5>


                {profile ? (
                    <div className={styles.avatar}>
                        <ImageAvatars url={profile} />
                        <label>{emailCadastro}</label>
                    </div>
                ) : null}

                {!profile && nameProfile ? (
                    <div className={styles.avatar}>
                        <BackgroundLetterAvatars color="#312d45" name={`${nameProfile}`} />
                        <label>{emailCadastro}</label>
                    </div>
                ) : null}


                <ValidationTextFields id={"firstName1"} label={"Nome"} value={firstName} setValue={setFirstName} />
                <ValidationTextFields id={"lastName1"} label={"Sobrenome"} value={lastName} setValue={setLastName} />
                <SelectTextFields currencies={ufs} label={"Estado"} value={estado} setValue={setEstado} />
                <SelectTextFields currencies={tipoSelect} label={"Tipo"} value={tipo} setValue={setTipo} />

                <div style={{ display: cnpjVisible, width: "100%" }}>
                    <SelectTextFieldsCNPJ id={"cnpj-2"} setCnpj={setCpfCnpj} />
                </div>

                <div style={{ display: cpfVisible, width: "100%" }}>
                    <SelectTextFieldsCPF id={"cpf-2"} setCpf={setCpfCnpj} />
                </div>

                <div className={styles.txtPolitica}>
                    <p>Conheça nossa</p>
                    <Link to="https://leadsmapping.com.br/politica-de-privacidade" target="_blank">Política de Privacidade</Link>
                </div>

                <button className={styles.btnLogin} onClick={criarConta}>
                    <p style={{ display: visibleText }}>Cadastrar minha conta</p>
                    {visibleClipLoader ? (
                        <ClipLoader
                            color="white"
                            size={25}
                            speedMultiplier={1}
                        />
                    ) : null}</button>

                <div className={styles.txtCadastrese}>
                    <p>Já tem uma conta?</p>
                    <label onClick={facaLogin}>Faça login</label>
                </div>
            </div>
        </div >

    )
}

export default Login