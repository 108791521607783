import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useRef, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import Divider from '@mui/material/Divider';
import SipRoundedIcon from '@mui/icons-material/SipRounded';
import PhoneDisabledRoundedIcon from '@mui/icons-material/PhoneDisabledRounded';
import PhoneEnabledRoundedIcon from '@mui/icons-material/PhoneEnabledRounded';

import styles from './Header.module.css'


import logo from '../../../logo.svg'
import ImageAvatars from '../../avatar/Avatar';
import DrawerSidebar from '../../drawer/DrawerSidebar';
import { Popover } from '@mui/material';


export default function MenuAppBar({ user, sidebar, sidebarOpen, limit, registrado, setVisibleCardMapping,
    setVisibleListLeads, leadsClick, setLeadsClick, setVisibleFunil, setVisibleHome, setVisibleVoip }) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        console.log(event.currentTarget)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        navigate('/login');
        Cookies.remove('_ui');
    }

    const styled = {
        fontFamily: "Inter",
        fontSize: 14,
        color: "212b36",
        fontWeight: 400,
    }

    const [toggle, setToggle] = React.useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
    }


    return (
        <Box sx={{ flexGrow: 1 }}>
            <DrawerSidebar setToggle={setToggle} toggle={toggle} sidebarOpen={sidebarOpen} setVisibleCardMapping={setVisibleCardMapping}
                setVisibleListLeads={setVisibleListLeads} leadsClick={leadsClick} setLeadsClick={setLeadsClick} setVisibleFunil={setVisibleFunil}
                setVisibleHome={setVisibleHome} setVisibleVoip={setVisibleVoip} />
            <AppBar position="static" sx={{ background: "#005249", boxShadow: "none" }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{
                            mr: 2,
                            '@media (max-width: 900px)': {
                                'display': 'none'
                            }
                        }}
                        onClick={sidebar}
                    >
                        {sidebarOpen ?
                            <MenuIcon />
                            : <MenuOpenRoundedIcon />
                        }
                    </IconButton>

                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{
                            mr: 2,
                            display: 'none',
                            '@media (max-width: 900px)': {
                                'display': 'block'
                            }

                        }}
                        onClick={handleToggle}
                    >
                        {sidebarOpen ?
                            <MenuIcon />
                            : <MenuOpenRoundedIcon />
                        }
                    </IconButton>

                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                        <img className={styles.logoHeader} src={logo} alt="Logo" />
                    </Typography>

                    {registrado ? (
                        <IconButton>
                            <PhoneEnabledRoundedIcon id="iconCall" sx={{
                                color: '#ffff', mr: 1,
                                '@media (max-width: 400px)': {
                                    'display': 'none'
                                }
                            }} />
                        </IconButton>
                    ) : (
                        <IconButton>
                            <PhoneDisabledRoundedIcon sx={{
                                color: '#ffff', mr: 1,
                                '@media (max-width: 400px)': {
                                    'display': 'none'
                                }
                            }} />
                        </IconButton>
                    )}

                    <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                        {limit.saldo ? (
                            <IconButton
                                size="small"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                sx={{
                                    mr: 2,
                                    background: 'rgb(255, 171, 0)',
                                    color: "#7A4100",
                                    borderRadius: '4px',
                                    '&:hover': {
                                        background: 'rgb(255, 171, 0)',
                                        color: "#7A4100",
                                    }
                                }}
                            >
                                {limit.saldo > 1000000 ? (
                                    <p>Ilimitado</p>
                                ) : <p>{limit.saldo}</p>}
                            </IconButton>
                        ) : null}

                        <IconButton
                            size="small"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            {user && user.picture ? <ImageAvatars url={user.picture} /> : <AccountCircle />}

                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose} sx={styled}>Perfil</MenuItem>
                            <MenuItem onClick={handleClose} sx={styled}>Minha conta</MenuItem>
                            <Divider />
                            <MenuItem onClick={handleLogout} sx={styled}>Sair</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
}