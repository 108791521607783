import JsSIP from 'jssip';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';

export default function Sip() {

    const configuration = {
        uri: 'sip:1001@leadsmapping.api4com.com',
        password: 'ejrxgdjj',
        sockets: [new JsSIP.WebSocketInterface('wss://leadsmapping.api4com.com:6443')],
        session_timers: false,
        debug: false,
    };

    var callOptions = {
        mediaConstraints: { audio: true, video: false },
        pcConfig: { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] }
    };


    const audioRef = useRef(null);
    const audioStream = useRef(null);
    const [audioElement, setAudioElement] = useState(null);

    const audio = document.createElement('audio');
    document.body.appendChild(audio);


    const pc = new window.RTCPeerConnection();

    const [phone, setPhone] = useState(null);
    const [session, setSession] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [ringing, setRinging] = useState("")


    useEffect(() => {
        if (configuration.uri && configuration.password) {
            JsSIP.debug.disable('JsSIP:*'); // more detailed debug output
            const newPhone = new JsSIP.UA(configuration);


            const audioElement = document.getElementById('audio-element');

            newPhone.on('registrationFailed', (ev) => {
                setErrorMessage(`Registering on SIP server failed with error: ${ev.cause}`);
                configuration.uri = null;
                configuration.password = null;
            });

            newPhone.on('newRTCSession', (ev) => {
                const newSession = ev.session;
                setSession(newSession);

                if (session) {
                    session.terminate();
                }

                newSession.on('ended', () => {
                    setRinging("Chamada encerrada!")
                    setSession(null);
                });

                newSession.on('failed', () => {
                    setRinging('Chamada falhou!')
                    setSession(null);
                });

                newSession.answer(callOptions);

                let peerConnection = newSession.connection;

                peerConnection.addEventListener("addstream", e => {
                    
                    const stream = e.stream;
                    audio.srcObject = stream[0];

                    // Se você precisar acessar as faixas (tracks) de mídia do "MediaStream", você pode fazer isso usando o método "getTracks()":
                    const audioTracks = stream.getAudioTracks();

                    // Você também pode acessar os eventos "onended" e "onmute" de cada faixa (track) de mídia:
                    audioTracks.forEach((track) => {
                        audio.srcObject = new MediaStream([track]);
                        audio.play();

                        track.onended = () => {
                            console.log('Áudio parou');
                        };
                    });
                });
            });

            newPhone.start();

            setPhone(newPhone);

        } else {
            setErrorMessage('Please fill SIP URI and Password in configuration.');
        }
    }, [configuration.uri, configuration.password]);


    const hangupHandler = () => {
        if (session) {
            session.terminate();
        }
    };


    const aceitarAudio = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        } catch (error) {
            console.error(error);
        }
    }

    const [number, setNumber] = useState('')

    const Call = async () => {
        aceitarAudio()
        setRinging("Chamando...")
        const request = {
            ramal: '1001',
            destination: number,
            iduser: '1542542'
        }

        const url = `${process.env.REACT_APP_API_URL}/calls`;

        try {
            const response = await axios.post(url, request, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response.data.id)
            return response.data;
        } catch (error) {
            return error;
        }
    }


    return (
        <div>
            <audio ref={audioRef} src='./sound/rtc_ringtone.wav'></audio>
            <audio id="audio" autoPlay src=''></audio>
            <h1>Minha aplicação SIP</h1>
            <input value={number} onChange={(event) => setNumber(event.target.value)}></input>
            <button onClick={Call}>Chamar</button>
            <button onClick={hangupHandler}>Desligar</button>
            <p>{ringing}</p>

        </div>
    )

}