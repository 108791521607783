import React from 'react';
import { NumericFormat } from 'react-number-format';

const CurrencyFormat = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2} // Define o número de decimais
            fixedDecimalScale={true} // Define que o número de decimais é fixo
            prefix="R$ "
            format="###.##" // Define a máscara de formatação
        />
    );
});

export default CurrencyFormat;
