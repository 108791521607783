import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';


import styles from '../layout/desktop/funil/HeaderFunil.module.css'

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';


import { BsArchive } from "react-icons/bs";

import empty from '../imagens/empty.svg'

const theme = createTheme({
    typography: {
        fontFamily: 'Inter',
        fontSize: 10
    },
});


export default function TemporaryDrawer(props) {
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    function handleButtonClick(id, anchor) {
        updateStatus(id);
        toggleDrawer(anchor, false);
    }

    const updateStatus = (id) => {
        const novoArray = props.arrayItens.map((objeto) => {
            if (objeto.id === id) {
                return {
                    ...objeto,
                    status: "Prospecção",
                };
            } else {
                return objeto;
            }
        });

        //atualiza o estado com o novo array
        props.setItens(novoArray);
    };

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <div className={styles.archive} onClick={toggleDrawer(anchor, true)}>
                        <BsArchive className={styles.iconArchive} />
                        <p>Arquivo</p>
                    </div>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        <Box
                            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350 }}
                            role="presentation"

                        >
                            <List>
                                {props.item.map((item, index) => (
                                    <ListItem key={index} disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon sx={{ minWidth: "35px" }}>
                                                <BusinessRoundedIcon sx={{ color: "#042940" }} />
                                            </ListItemIcon>
                                            <ThemeProvider theme={theme}>
                                                <ListItemText
                                                    primary={item.razao_social}
                                                    primaryTypographyProps={{
                                                        style: {
                                                            fontSize: 10,
                                                            maxWidth: "180px",
                                                            color: "#042940"
                                                        },
                                                    }}
                                                />
                                            </ThemeProvider>
                                            <button className={styles.btnDesarquivar}
                                                onClick={() => handleButtonClick(item.id, anchor)}
                                                onKeyDown={toggleDrawer(anchor, false)}
                                            >Desarquivar
                                            </button>
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                            {props.item.length === 0 ? (
                                <div style={{ display: "flex", height: "80vh", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <img src={empty} alt="empty" />
                                    <p style={{ fontSize: "14px", color: "#212b36", fontWeight: 500, marginTop: "20px" }}>Arquivo está vazio</p>
                                </div>
                            ) : null}
                        </Box>
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}