import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

export default function BackgroundLetterAvatars(props) {
    function stringAvatar(name) {
        const [firstName, lastName] = name.split(' ');
        const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
        return {
            sx: {
                bgcolor: props.color,
            },
            children: initials.toUpperCase(),
        };
    }
    return (
        <Stack direction="row" spacing={2}>
            <Avatar {...stringAvatar(props.name)} />
        </Stack>
    );
}